import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';




class TutorialAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddTutorialForm           =        {
            section_id          :      '',
            title              :      "",
            description        :      "",
            status             :      1,
            embed_code         :       "",
            ordo              :      ''
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addTutorialForm            :       {...this.initAddTutorialForm},
            form_loading               :        false,
            allSectionsList            :        [],
            allStatusList              :        [],
            editTutorial               :        false,
            editTutorialData            :       null,
        };
    }

    componentDidMount() {
        this.getAddFormData()
    }



    getAddFormData       =   ()        =>      {
        this.setState({form_loading: true});
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/tutorial/form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                allSectionsList             : respData && respData.sections && respData.sections.length > 0 ? respData.sections.map(tp => {return({value:tp.id,label:tp.name})}) : [],
                allStatusList              : respData && respData.status && respData.status.length > 0 ? respData.status.map(tp => {return({value:tp.key,label:tp.status})}) : [],
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.editTutorialData) {
                    let detail = this.props.location.state.editTutorialData;
                    this.setState({
                       addTutorialForm : {
                            ...this.initAddTutorialForm,
                            id                  :           detail.id,
                            description         :           detail.description ? detail.description : "",
                            section_id          :           detail.section ? detail.section.id : "",
                            title              :            detail.title ? detail.title : '',
                            status             :            detail.status ? detail.status.status : 0,
                            embed_code         :            detail.embed_code ? detail.embed_code : "",
                            ordo              :             detail.ordo ? detail.ordo : ""
                       },
                       editTutorial             :   true,
                       editTutorialData        :        detail,
                    })
                }
            })
           
        }).then(() => this.setState({form_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/tutorial_list` })

    }

    submitAddTutorialForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addTutorialForm,
         }
        if (this.state.editTutorial) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/tutorial', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                //this.props.history.push({ pathname: `/tutorial_list` });
                this.props.history.push({ pathname: `/tutorial_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/tutorial', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push({ pathname: `/tutorial_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
        
        
    }


    saveTutorialFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddTutorialForm} id="saveTutorialForm">
            {this.state.form_loading ? <Loader />
                : <Ax>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Section</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTutorialForm', 'section_id')}
                                options={this.state.allSectionsList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allSectionsList.find(s => this.state.addTutorialForm.section_id == s.value)}
                                placeholder={"Please Select Section"}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Title</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="title"
                                type="text"
                                value={this.state.addTutorialForm.title}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addTutorialForm")}
                                placeholder="Please enter Title"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Status</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTutorialForm', 'status')}
                                options={this.state.allStatusList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allStatusList.find(s => this.state.addTutorialForm.status == s.value)}
                                placeholder={"Please Select Status"}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Embeded URL</label>
                        </div>
                        <div className="col-sm-4 ">
                            <textarea
                                name="embed_code"
                                value={this.state.addTutorialForm.embed_code}
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, "addTutorialForm")}
                                placeholder="Please enter Embeded URL"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Order Number</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="ordo"
                                type="number"
                                value={this.state.addTutorialForm.ordo}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addTutorialForm")}
                                placeholder="Please enter Order Number"
                            />
                        </div>
                    </div>
                    <div className="row  my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Description</label>
                        </div>
                        <div className="col-sm-9 ">
                            <CKEditor
                                data={this.state.addTutorialForm.description}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    this.setState({ addTutorialForm: { ...this.state.addTutorialForm, description: editor.getData() } })
                                }}
                               
                        
                            />

                        </div>
                    </div>
                

                </Ax>}


            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
            <Helmet><title>Tutorial Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editTutorial ? "Edit" : "Add"} Tutorial</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.saveTutorialFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
    };
};

export default connect(mapStateToProps)(TutorialAdd);