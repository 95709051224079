import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';




class SectionAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.addUrl                =        process.env.REACT_APP_SERVER_PATH + '/backoffice/section/add';
        this.editUrl                =        process.env.REACT_APP_SERVER_PATH + '/backoffice/section/edit';

        this.initaddSectionForm           =        {
            name              :     "",
            ordo              :      '',
            status            :      '1'
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addSectionForm            :       {...this.initaddSectionForm},
            form_loading               :        false,
            editSection               :        false,
            editSectionData            :       null,
            allStatusList             :       []
        };
    }

    componentDidMount() {
        this.getAddFormData()
    }

    getAddFormData       =   ()        =>      {
        this.setState({form_loading: true});
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/tutorial/form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
               allStatusList              : respData && respData.status && respData.status.length > 0 ? respData.status.map(tp => {return({value:tp.key,label:tp.status})}) : [],
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.editSectionData) {
                    let detail = this.props.location.state.editSectionData;
                    this.setState({
                    addSectionForm : {
                            ...this.initaddSectionForm,
                            id                  :           detail.id,
                            name               :           detail.name,
                            status              :           detail.status ? detail.status.status : "0",
                            ordo                :          detail.ordo
                    },
                    editSection             :   true
                    })
                }
            })
           
        }).then(() => this.setState({form_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/section_list` })

    }

    submitAddSectionForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addSectionForm,
         }
        if (this.state.editSection) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', this.editUrl, this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                //this.props.history.push({ pathname: `/tutorial_list` });
                this.props.history.push({ pathname: `/section_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', this.addUrl, this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push({ pathname: `/section_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
        
        
    }


    saveSectionFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddSectionForm} id="saveSectionForm">
            {this.state.form_loading ? <Loader />
                : <Ax>
                    
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Name</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="name"
                                type="text"
                                value={this.state.addSectionForm.name}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addSectionForm")}
                                placeholder="Please enter Section Name"
                            />
                        </div>
                    </div>
                   
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Order Number</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="ordo"
                                type="number"
                                value={this.state.addSectionForm.ordo}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addSectionForm")}
                                placeholder="Please enter Order Number"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Status</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addSectionForm', 'status')}
                                options={this.state.allStatusList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allStatusList.find(s => this.state.addSectionForm.status == s.value)}
                                placeholder={"Please Select Status"}
                                required={true}
                            />
                        </div>
                    </div>
                 

                </Ax>}


            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
            <Helmet><title>Section Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editSection ? "Edit" : "Add"} Section</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.saveSectionFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
    };
};

export default connect(mapStateToProps)(SectionAdd);