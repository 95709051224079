import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import React from "react";

class FeedbackWidget extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                      =   {
            loading                     :      false,
            data                        :       null,
        }
    }

    componentDidMount() {
        this.getFeedbackListing()
    }

    getFeedbackListing                =   (reload=false)  =>  {
        this.setState({loading: true});
       console.log(this.props.access_token);
        HttpAPICall.withAthorization('GET',process.env.REACT_APP_SERVER_PATH + '/backoffice/dashboard/feedback', this.props.access_token,{}, {} , (response) => {
            this.setState({data : response.data.data})
        }).then(() => this.setState({loading: false}));
    }

    render                      =   ()  =>  {
     
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Feedback</h6>
                    </div>
                    <div className="col-3">

                    </div>
                    <div className="col-3 p0">

                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getFeedbackListing()}>Refresh</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">

                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-end">
                                    <th className="text-start" ></th>
                                    <th style={{ width: "25%" }} className="text-center"></th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr><td colSpan="2"><Loader /></td></tr>)
                                    :
                                    this.state.data ? <Ax>
                                        <tr>
                                            <td>Total feedback in past 90 days</td>
                                            <th className="text-center">{this.state.data && this.state.data.total_feedback ? this.state.data.total_feedback : ""}</th>
                                        </tr>
                                        <tr>
                                            <td>Average Star Rating for past 90 days</td>
                                            <th className="text-center">{this.state.data && this.state.data.feedback_avg ? parseFloat(this.state.data.feedback_avg).toFixed(2) : ""}</th>
                                        </tr>
                                    </Ax>

                                        : <tr><td className="text-center" colSpan={2}>No Record Found</td></tr>
                                }


                            </tbody>
                        </table>
                        <div className="col-sm-12 text-muted fs11">
                        <div className="float-end link-primary">
                            <Link target="_blank" to={{ pathname: "/feedback_list"}}>View Feedback List</Link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
    };
};


export default connect(mapStateToProps)(FeedbackWidget);