import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import renderHTML from 'react-render-html'
import Status from '../../../components/ui/Status';

class ResourceTrackerDetailModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
    
        this.state                  =   {
            access_token                :   '',
            listing_loading            :       false,
            listing_tbl_page           :       1,
            resource_listing           :       [],
            listingMeta                :       null,
            totalListingCount          :       0,
            detail_loading             :       false,
            resourceDetail             :       null,
            addCommentForm             :       {...this.updateCommentFormInit},
            updateVersionFormSubmitting :      false,
            task_listing                :       [],
            task_loading                :       false,
            task_tbl_page               :       1,
            tasklistingMeta             :       null,
            totalTaskListingCount       :       0,
            version_listing             :       [],
            version_loading             :       false,
            version_tbl_page            :       1,
            totalVersionListingCount    :       0,
            versionlistingMeta          :       null
        }
        
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.resourceTrackerModal         =   new Modal(document.getElementById("resourceDetailModal"), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    detailModalInit         =   (id)  =>  {
        this.resourceTrackerModal.show();
        this.getResourceDetail(id);
        this.getLinkedTaskList(1,id)
        this.getVersionHistoryListing(1,id)
    }

    getResourceDetail                   =      (id)    =>       {
        this.setState({detail_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                resourceDetail         :       respData.data,
            })
        }).then(() => this.setState({detail_loading: false}));
    }

    getLinkedTaskList                 =   (page = 1,resource_id='')  =>         {
        this.setState({task_loading: true, task_tbl_page: page});
        let params                      =   {page:page,per_page:50,search_resource_id : resource_id};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                task_listing      :       respData.data,
                tasklistingMeta         :       respData.meta,
                totalTaskListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({task_loading: false}));
    }

    getVersionHistoryListing                 =   (page = 1,id)  =>         {
        this.setState({version_loading: true, version_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource_version/' + id , this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                version_listing             :       respData.data,
                versionlistingMeta         :       {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                    
                },
                totalVersionListingCount   :       respData.total,
        });
        }).then(() => this.setState({version_loading: false}));
    }
   
   
    resourceTrackerModalJsx           =   ()  =>  {
        let detail = this.state.resourceDetail ? this.state.resourceDetail : null
        return <div className="modal fade" id={"resourceDetailModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            Resource Detail
                        </h5>

                    </div>

                    <div className="modal-body">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="resourcedetails-tab" data-bs-toggle="tab" data-bs-target="#resourcedetails"
                                        type="button" role="tab" aria-controls="resourcedetails" aria-selected="true">Details
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">

                                    <button className="nav-link" id="resourceversion-tab" data-bs-toggle="tab" data-bs-target="#resourceversion"
                                        type="button" role="tab" aria-controls="resourceversion" aria-selected="true">Version
                                    </button>

                                </li>
                                <li className="nav-item" role="presentation">

                                    <button className="nav-link" id="resourceimpact-tab" data-bs-toggle="tab" data-bs-target="#resourceimpact"
                                        type="button" role="tab" aria-controls="resourceimpact" aria-selected="true">Impact
                                    </button>

                                </li>

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane  show active" id="resourcedetails" role="tabpanel" aria-labelledby="resourcedetails-tab">
                                    {this.state.detail_loading ? <Loader />
                                        : <Ax>
                                            <div className="my-3">
                                                <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                                    <tbody className='resource_table'>

                                                        <tr>
                                                            <td style={{ width: "25%" }}>Type</td>
                                                            <th>{detail && detail.resource_type ? detail.resource_type.name : "-"}</th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "25%" }}>Name</td>
                                                            <th>{detail && detail.name ? detail.name : "-"}</th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "25%" }}>Attribute</td>
                                                            <th>{detail && detail.path ? detail.path : "-"}</th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "25%" }}>Description</td>
                                                            <th>{detail && detail.description ? renderHTML(detail.description) : "-"}</th>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </Ax>}
                                </div>
                                <div className="tab-pane" id="resourceversion" role="tabpanel" aria-labelledby="resourceversion-tab">
                                    <div className="my-3">
                                        <div className="tab_content_wrapper"> <span className="content_heading"> Version Status</span>
                                            
                                        </div>
                                        <hr />
                                        <div className="my-1">
                                            {this.state.version_loading ? <div><Loader /></div>
                                                : this.state.version_listing && this.state.version_listing.length > 0
                                                    ? this.state.version_listing.map(((vs, key) => {
                                                        return (<Ax>
                                                            <div className="row" key={key}>
                                                                <div className="col-sm-8" >{vs.notes ? vs.notes : "-"} </div>
                                                                <div className="col-sm-4 text-end">
                                                                    {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" /> */}
                                                                    <span>{vs.updated_user} on {vs.created_at_display}</span>
                                                                </div>

                                                            </div><hr />
                                                        </Ax>)
                                                    }))
                                                    : <div class="alert alert-secondary text-center align-middle my-2" role="alert">
                                                        No Version Added
                                                    </div>}
                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane" id="resourceimpact" role="tabpanel" aria-labelledby="resourceimpact-tab">
                                    <div className="my-3">
                                        <div className="tab_content_wrapper"> <span className="content_heading"> Impact</span>

                                        </div>
                                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                            <thead className="table-secondary">
                                                <tr className='text-center'>
                                                    <th style={{ width: "10%" }}>S.No</th>
                                                    <th>Task Name</th>
                                                    <th style={{ width: "15%" }}>Task Status</th>
                                                    <th style={{ width: "15%" }}>Impact Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.task_loading ? <tr className='text-center'>
                                                    <td colSpan={3}><Loader /></td>
                                                </tr> : this.state.task_listing && this.state.task_listing.length > 0
                                                    ? this.state.task_listing.map((ts, key) => {
                                                        return (<tr key={key}>
                                                            <td className='text-center'>{this.state.tasklistingMeta ? this.state.tasklistingMeta.from + key : key}</td>
                                                            <td>{ts.name ? ts.name : "-"}</td>
                                                            <td className='text-center'><Status color={ts.status.status_color_code}>{ts.status.status_text}</Status></td>
                                                            <td className='text-center'><Status color={ts.impact_status.status_color_code}>{ts.impact_status.status_text}</Status></td>
                                                        </tr>)
                                                    })
                                                    : <tr className='text-center'>
                                                        <td colSpan={3}>No Record</td>
                                                    </tr>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    }
    
    render                              =   ()  =>  this.resourceTrackerModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ResourceTrackerDetailModal);