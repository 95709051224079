import * as actionTypes from './actionTypes';
import axios from 'axios';
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';



export const reportListingInitilization   = () => {
    return {
        type                :   actionTypes.REPORT_LISTING_DATA_INIT
    };
};



export const reportListingDataLoadSuccessfully   = (data) => {
   
    return {
        type                     :   actionTypes.REPORT_LISTING_DATA_SUCCESS,
        report_listing           :   data
    };
};




