import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';




class TaskTrackerAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddTaskForm           =        {
            name                :           '',
            key                 :           '',
            type                :           '',
            module              :           '',
            parent              :           '',
            description         :           '',
            pmt_task_id         :           '',
            release_num         :           ''
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addTaskForm                :       {...this.initAddTaskForm},
            form_loading               :        false,
            allTypeList                :        [],
            allTaskList                 :       [],
            allModuleList              :        [],
            additionalInputList        :        [],
            editTask                   :        false,
        };
    }

    componentDidMount() {
        this.getAddFormData()
    }



    getAddFormData       =   ()        =>      {
        this.setState({form_loading: true});
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/add_task_form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                allTypeList             : respData && respData.type && respData.type.length > 0 ? respData.type.map(tp => {return({value:tp.index,label:tp.item})}) : [],
                allTaskList             : respData && respData.task && respData.task.length > 0 ? respData.task.map(tp => {return({value:tp.id,label:`${tp.name} (${tp.key})`})}) : [],
                allModuleList           : respData && respData.modules && respData.modules.length > 0 ? respData.modules.map(tp => {return({value:tp.index,label:tp.item})}) : [],
                additionalInputList     : respData && respData.addtional_inputs && respData.addtional_inputs.length > 0 ? respData.addtional_inputs : [],

            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.editTaskData) {
                    let detail = this.props.location.state.editTaskData;
                    this.setState({
                       addTaskForm : {
                            ...this.initAddTaskForm,
                            id                  :           detail.id,
                            name                :           detail.name,
                            key                 :           detail.key,
                            type                :           detail.type ? detail.type : "",
                            module              :           detail.module ? detail.module : "",
                            parent              :           detail.parent ? detail.parent.id : "",
                            pmt_task_id         :           detail.pmt_task_id ? detail.pmt_task_id : "",
                            release_num         :           detail.release_num ? detail.release_num : "",
                            description         :           detail.description ? detail.description : ""
                       },
                       editTask             :   true
                    })
                }
            })
           
        }).then(() => this.setState({form_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/task_tracker_list` })

    }

    submitAddTaskForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addTaskForm,
         }
        if (this.state.editTask) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                //this.setState({ addTaskForm: { ...this.initAddTaskForm } })
                this.props.history.push({ pathname: `/task_tracker_list`,state:{id:this.state.addTaskForm.id} })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addTaskForm: { ...this.initAddTaskForm } })
                this.props.history.push({ pathname: `/task_tracker_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
        
        
    }


    saveTaskFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddTaskForm} id="saveTaskForm">
            {this.state.form_loading ? <Loader />
                : <Ax>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Type</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskForm', 'type')}
                                options={this.state.allTypeList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allTypeList.find(s => this.state.addTaskForm.type == s.value)}
                                placeholder={"Please Select Type"}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Name</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="name"
                                type="text"
                                value={this.state.addTaskForm.name}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addTaskForm")}
                                placeholder="Please enter Name"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Code</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="key"
                                type="text"
                                value={this.state.addTaskForm.key}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addTaskForm")}
                                placeholder="Please enter Code"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Description</label>
                        </div>
                        <div className="col-sm-4 ">
                            <CKEditor
                            data={this.state.addTaskForm.description}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    // this.state.description = editor.getData();
                                    this.setState({ addTaskForm: { ...this.state.addTaskForm, description: editor.getData() } })
                                }}

                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Parent UC</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskForm', 'parent')}
                                options={this.state.allTaskList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allTaskList.find(s => this.state.addTaskForm.parent == s.value)}
                                placeholder={"Please Select Parent UC"}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Module</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskForm', 'module')}
                                options={this.state.allModuleList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allModuleList.find(s => this.state.addTaskForm.module == s.value)}
                                placeholder={"Please Select Module"}
                            />
                        </div>
                    </div>
                    {
                        this.state.additionalInputList && this.state.additionalInputList.length > 0
                            ? (
                                <Ax>
                                    {
                                        this.state.additionalInputList.map((i, k) => {
                                            let name = i.name;
                                            let input = null;
                                            if (i.type == 'text') {
                                                input = <input
                                                    name={name}
                                                    type="text"
                                                    value={this.state.addTaskForm[name] ? this.state.addTaskForm[name] : ''}
                                                    onChange={(e) => this.formInputHandler(e, 'addTaskForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder={`Please enter ${i.label}`}
                                                />
                                            } else if (i.type == 'numeric') {
                                                input = <input
                                                    name={name}
                                                    type="number"
                                                    value={this.state.addTaskForm[name] ? this.state.addTaskForm[name] : ''}
                                                    onChange={(e) => this.formInputHandler(e, 'addTaskForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder={`Please enter ${i.label}`}
                                                    pattern="[0-9.]+"
                                                />
                                            }

                                            return (<div key={k} className="row align-items-center mb-3 mt-3">
                                                <div className="col-3"><label className="form-label">{i.label}</label></div>
                                                <div className="col-4">{input}</div>
                                                <div className="col-5">
                                                    <span className="form-text"></span>
                                                </div>
                                            </div>);
                                        })
                                    }
                                </Ax>
                            )
                            : null
                    }
                </Ax>}


            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Task Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editTask ? "Edit" : "Add"} Task</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    {this.saveTaskFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
    };
};

export default connect(mapStateToProps)(TaskTrackerAdd);