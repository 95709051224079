import * as actionTypes from './actionTypes';

import { applicationInitLoading } from './app';

import axios from 'axios';
import HttpAPICall from '../../services/HttpAPICall';
import { appInitilizationDataLoad, iamAppDataLoadSuccessfully } from '.';
import TapApiUrls from '../../services/TapApiUrls';

export const iscAppDataInitilization   = () => {
    return {
        type                :   actionTypes.ISC_APP_DATA_INIT
    };
};



export const iscAppDataLoadSuccessfully   = (isc_app_data) => {
    return {
        type                    :   actionTypes.ISC_APP_DATA_SUCCESS,
        isc_data                :   isc_app_data,
    };
};


export const iscAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.ISC_APP_DATA_FAIL
    };
};

export const iscAppDataLoading         =   (user_info, group_info, acl_info)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        if (acl_info.group_modules.includes("isc")) {
            HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/app_data', access_token, {})
                .then((response) => {
                    dispatch(iscAppDataLoadSuccessfully(response.data));
                    dispatch(appInitilizationDataLoad(user_info, group_info, acl_info));
                }).catch(function (err) {
                    dispatch(iscAppDataLoadFail());
                });
        }
    };
};


export const itemSearch = (searchElem,searchParam) => {

    return {
        type            :   actionTypes.ITEM_SEARCH,
        search_elem     :   searchElem,
        search_param    :   searchParam,
    };
};

export const itemSearchClear = () => {
    return {
        type: actionTypes.ITEM_CLEAR
    };
};
