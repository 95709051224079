import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import dashboardStyles from "./Dashboard.module.css"
import FeedbackWidget from './feedback/FeedbackWidget';
import TrainingWidget from './training/TrainingWidget';
import SmsBalanceWidget from './smsBalance/SmsBalanceWidget';

class Dashboard extends React.Component {
 
  render() {
 
    return (<ApplicationLayout>
       <Helmet><title>Dashboard</title></Helmet>

      <div className="row m-0 bg-white">
        <div className="col-sm-12 p-0">
          <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">

            <li className="nav-item" role="presentation">
              <button className={`nav-link active`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content" id="myTabContent">
        <div className="tab-pane  show active" id="home" role="tabpanel" aria-labelledby="dashboard-tab">
          <div className="container-fluid p4" >
            <div className={dashboardStyles.tab_content_header}>

              <div className="row">
                <div className="col-md-6">
                      <FeedbackWidget/>
                      <SmsBalanceWidget/>
                </div>
                <div className="col-md-6">
                    <TrainingWidget/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ApplicationLayout>);
  }
}

export default Dashboard;
