export const APP_BASE_URL           =  process.env.REACT_APP_SERVER_PATH;
export const BACKOFFICE_BASE_URL       =  APP_BASE_URL + '/backoffice';


//url for fund transfer
const TapApiUrls    = ()      =>   {
    return{
        APP_URL                       :          APP_BASE_URL,
        BACKOFFICE_URL                :          BACKOFFICE_BASE_URL,
      }
}

export default TapApiUrls()