import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../components/ui/TapSelect';
import AppBaseComponent from '../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';


class PermissionList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initAddPermissionForm              =       {
            permission_id           :       '',
            permission              :       '',
            permission_label        :       '',
            module_id               :       '',
            sub_module_id           :       '',
            transaction_locking     :       'N'
        }
        
        this.state                           =     {
            
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            permission_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            addPermissionForm                       :       {...this.initAddPermissionForm},
            editModule                          :       false,
            addPermissionFormSubmitting         :       false,
            formDataLoaded                      :          false,
            form_loading                        :          false,
            modules                             :           [],
            sub_modules                         :           [],
            agree                               :           'N',
            permissions                         :           []
        };
       
    }

    componentDidMount() {
        this.initalizeComponent(this.props);
      this.loadListingTblData(1);
      this.addPermissionModal                  =   new Modal(document.getElementById('addPermissionModal'), {keyboard: false, backdrop :false});
      
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/permission', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                permission_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

   
    addPermissionFormInit       =       (data)      =>      {
        this.addPermissionModal.show();
        if(data){
            this.setState({
                addPermissionForm                       :       {
                    module_id           :       data.module && data.module.id ? data.module.id  : '',
                    permission_label    :        data.permission_label ? data.permission_label  : '',
                    permission          :        data.permission ? data.permission  : '',
                    sub_module_id       :        data.sub_module && data.sub_module.id ? data.sub_module.id  : '',
                    transaction_locking :       data.transaction_locking && data.transaction_locking == 'Y' ? 'Y'  : 'N',
                    permission_id       :       data.id
                },
                editModule          :       true,
                agree                   :       'N'
            })
        }else{
            this.setState({
                addPermissionForm      :       {...this.initAddPermissionForm},
                editModule         :       false,
                agree                   :       'N'
            })
        }

        if(!this.state.formDataLoaded){
            this.getAddFormData()
        }
    }

    getAddFormData                 =   ()  =>         {
        this.setState({form_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/permission/formData', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({ 
                modules             :       respData.modules && respData.modules.length > 0 ? respData.modules.map(md => {return({value:md.id,label:md.name})}) :[],
                sub_modules         :       respData.sub_modules && respData.sub_modules.length > 0 ? respData.sub_modules.map(md => {return({value:md.id,label:md.name,module_id : md.module_id})}) :[],
                formDataLoaded      :       true
            });
           
        }).then(() => this.setState({form_loading: false}));
    }

    submitAddPermissionForm     =       (e)     =>      {
        e.preventDefault();
        this.setState({ addPermissionFormSubmitting: true });
       
        if (this.state.editModule) {
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/permission/update', this.props.access_token, {}, { ...this.state.addPermissionForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addPermissionModal.hide();
                this.setState({ addPermissionForm: { ...this.initAddPermissionForm } });
                this.loadListingTblData(this.state.listing_tbl_page);
            }).then(() => this.setState({ addPermissionFormSubmitting: false }));
        } else {
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/permission/add', this.props.access_token, {}, { ...this.state.addPermissionForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addPermissionModal.hide();
                this.setState({ addPermissionForm: { ...this.initAddPermissionForm } });
                this.loadListingTblData(1);
            }).then(() => this.setState({ addPermissionFormSubmitting: false }));
        }
        
    }

    permissionJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Permission List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.state.permissions.includes('permission_add') &&  <button type="button" className="btn btn-primary" onClick={() => this.addPermissionFormInit()}>New</button>}

                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Module :  </h3>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.permissionListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>test</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    permissionListingJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                        <th scope="col" style={{ width: "20%" }}>Module</th>
                        <th scope="col" style={{ width: "20%" }}>Sub Module</th>
                        <th scope="col" style={{ width: "20%" }}>Permission Label</th>
                        <th scope="col" style={{ width: "20%" }}>Keyword</th>
                        <th scope="col" style={{ width: "10%" }}>Tr. Locking</th>
                        <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="4"><Loader /></td></tr>)
                        : (this.state.permission_listing && this.state.permission_listing.length > 0
                            ? (this.state.permission_listing.map((item, index) => {
                                return (<tr key={index} >
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{item.module ? item.module.name : ""}</td>
                                    <td>{item.sub_module ? item.sub_module.name : ""}</td>
                                    <td>{item.permission_label ? item.permission_label : ""}</td>
                                    <td>{item.permission ? item.permission : ""}</td>
                                    <td className='text-center'>{item.transaction_locking && item.transaction_locking == 'Y' ? 'Yes' : 'No'}</td>
                                    <td className='text-center'>
                                        {this.state.permissions.includes('permission_edit') && <Ax>
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" onClick={() => this.addPermissionFormInit(item)} role="button">Edit</a></li>
                                            </ul>
                                        </Ax>}  
                                    </td>
                                  
                                </tr>)
                            }))
                            : (<tr><td colSpan="4" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    addPermissionModalJsx                        =   ()  =>  {
        return (
            <div className="modal fade" id="addPermissionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addPermissionModalLabel">{this.state.editModule ? "Edit" : "Add"} Permission</h5>
                        </div>
                        <form onSubmit={this.submitAddPermissionForm} id="addPermissionForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Module</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addPermissionForm', 'module_id');
                                            }}
                                            options={this.state.modules}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            isDisabled={this.state.editModule}
                                            value={this.state.modules.find(s => s.value == this.state.addPermissionForm.module_id)}
                                            placeholder="Select Module"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Sub Module</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addPermissionForm', 'sub_module_id');
                                            }}
                                            options={this.state.sub_modules && this.state.sub_modules.length > 0 ? this.state.sub_modules.filter(sm => { return (sm.module_id == this.state.addPermissionForm.module_id) }) : []}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            isDisabled={this.state.editModule}
                                            value={this.state.sub_modules.find(s => s.value == this.state.addPermissionForm.sub_module_id)}
                                            placeholder="Select Sub Module"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Permission Label</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="permission_label"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required
                                            placeholder="Enter Permission Label"
                                            onChange={(e) => { this.formInputHandler(e, 'addPermissionForm') }}
                                            // disabled={this.state.editModule ? true : false}
                                            value={this.state.addPermissionForm.permission_label}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Permission Key</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="permission"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required
                                            placeholder="Enter Permission"
                                            onChange={(e) => { this.formInputHandler(e, 'addPermissionForm') }}
                                            disabled={this.state.editModule ? true : false}
                                            value={this.state.addPermissionForm.permission}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Transaction Locking</label>
                                    </div>
                                    <div className="col-sm-9">

                                        <div className="form-check form-check-inline">
                                            <input
                                                name="transaction_locking"
                                                type="radio"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, 'addPermissionForm')}
                                                checked={this.state.addPermissionForm.transaction_locking == "Y"}
                                                className="form-check-input"
                                                required={true}
                                                disabled={this.state.editModule}
                                                id="addPermissionForm_yes"
                                            />
                                            <label className="form-check-label" htmlFor="addPermissionForm_yes">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="transaction_locking"
                                                type="radio"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, 'addPermissionForm')}
                                                checked={this.state.addPermissionForm.transaction_locking == "N"}
                                                className="form-check-input"
                                                required={true}
                                                disabled={this.state.editModule}
                                                id="addPermissionForm_no"
                                            />
                                            <label className="form-check-label" htmlFor="addPermissionForm_no">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between  row">
                                <div className="col text-start">

                                    <input
                                        name="agree"
                                        type="checkbox"
                                        className="form-check-input"
                                        id="agree"
                                        required
                                        value={this.state.agree}
                                        onChange={(e) => {
                                            this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                        }}
                                        checked={this.state.agree == 'Y'}
                                    />
                                    <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                                </div>
                                <div class="col text-end">
                                    <button type="button"
                                        disabled={this.state.addPermissionFormSubmitting}
                                        className="btn btn-secondary mx-2"
                                        data-bs-dismiss="modal"

                                    >Close</button>
                                    <button type="submit" disabled={this.state.addPermissionFormSubmitting} className="btn btn-primary">
                                        Submit {this.state.addPermissionFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>

                            </div>
                          
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Permission Listing</title></Helmet>
            {this.permissionJsx()}
           {this.addPermissionModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(PermissionList);