import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html'
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import InputResourceSearch from '../../includes/ui/InputResourceSearch';
import swal from "sweetalert";
import ResourceTrackerDetailModal from '../resourceTracker/ResourceTrackerDetailModal';


class TaskTrackerList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initTaskSearchForm      =       {
            search_resource_id            :       '',
            search_name                   :       '',
            search_number                 :       '',
        }

        this.taskSearchFormLabel       =       {
            search_resource_id          :       'Resource : ',
            search_name                 :       'Name : ',
            search_number               :       'Number : ',
        }

        this.initLinkResourceForm       =       {
            resource_ids          :       [],
            task_id              :       '',
        }

        this.initChangeTaskStatus       =       {
            status               :      '',
            notes                :      '',
            key                  :      ''
        }

        this.state                           =     {
            
            minimizeTable              :       false,
            listing_loading            :       false,
            listing_tbl_page           :       1,
            task_listing               :       [],
            listingMeta                :       null,
            totalListingCount          :       0,
            taskSearchForm             :       {...this.initTaskSearchForm},
            submittedTaskFilterForm    :       {...this.initTaskSearchForm},
            selectedResource           :        null,
            formSearchedElems          :       [],
            detail_loading             :        false,
            taskDetail                  :       null,
            resource_loading            :       false,
            linkedResourceList          :       [],
            linkResourceForm            :       {...this.initLinkResourceForm},
            selectedLinkedResource      :       [],
            viewLinkedResourceDetail    :       null,
            agree                       :       'N',
            changeTaskStatusForm        :       {...this.initChangeTaskStatus},
            allTaskStatus               :       [],
            taskStatusFormDataLoaded    :       false,
            changeStatusFormSubmitting  :       false,
            statusKey                   :       '',
            allImpactStatus             :       [],
            impactListingMeta           :       null,
            impact_loading              :       false,
            impact_listing              :       [],
            totalImpactListingCount     :       0,
            permissions                 :       [],
            taskPerformed               :       false,
        };
        this.resourceDetailRef          =   React.createRef();
    }

    componentDidMount() {
        this.initalizeComponent(this.props); 
        if(this.props.location && this.props.location.state && this.props.location.state.id){
            this.viewDetailInit(this.props.location.state.id)
        }
      this.loadListingTblData(1);
      this.taskSearchModal                 =        new Modal(document.getElementById('taskSearchModal'), {keyboard: false, backdrop :false});
      this.taskLinkResourceModal           =        new Modal(document.getElementById('taskLinkResourceModal'), {keyboard: false, backdrop :false});
      this.changeTaskStatusModal           =       new Modal(document.getElementById('changeTaskStatusModal'), {keyboard: false, backdrop :false}); 
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50,...this.state.taskSearchForm};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                task_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    submitTaskSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.taskSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.taskSearchFormLabel[key];
            let value                       =   this.state.taskSearchForm[key];
           
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                let show_val            =   value;
                if(key == 'search_name') {
                    show_val            =   this.state.taskSearchForm ? this.state.taskSearchForm.search_name : '';
                }
                if(key == 'search_number') {
                    show_val            =   this.state.taskSearchForm ? this.state.taskSearchForm.search_number : '';
                }
                if(key == 'search_resource_id') {
                    show_val            =   this.state.selectedResource ? this.state.selectedResource.label : '';
                }
                
                searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedTaskFilterForm       :   {...this.state.taskSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.taskSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    taskSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems           :       [],
            taskSearchForm                :       {...this.initTaskSearchForm},
            submittedTaskFilterForm       :       {...this.initTaskSearchForm},
            selectedResource              :       null,
        }, () => {
            this.loadListingTblData(1);
            this.taskSearchModal.hide();
        });
    }

    taskSearchModalInit     =       ()      =>      {
        this.taskSearchModal.show();
    }

    addTaskFormInit     =       ()      =>      {
        this.props.history.push('/add_task')
    }

    editTaskInit        =       ()      =>      {
        this.props.history.push({ pathname: `/add_task`, state: { editTaskData : this.state.taskDetail } });
    }

    viewDetailInit      =       (id)        =>      {
        this.setState({
            minimizeTable : true,
            taskPerformed   :   false
        })
        this.getTaskDetail(id);
        this.getLinkedResourceList(id);
        this.getImpactHistoryListing(id)
    }

    getTaskDetail                   =      (id)    =>       {
        this.setState({detail_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                taskDetail         :       respData.data,
            })
        }).then(() => this.setState({detail_loading: false}));
    }

    getLinkedResourceList                   =      (id)    =>       {
        this.setState({resource_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task/resources/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                linkedResourceList         :       respData,
            })
        }).then(() => this.setState({resource_loading: false}));
    }

    getImpactHistoryListing                   =      (id)    =>       {
        this.setState({impact_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task_status_log/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                impact_listing             :       respData.data,
                impactListingMeta         :       {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                    
                },
                totalImpactListingCount   :       respData.total,
        });
        }).then(() => this.setState({impact_loading: false}));
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
        if(this.state.taskPerformed == true){
            this.loadListingTblData(1)
        }
    }

    linkResourceInit        =   ()      =>      {
        this.taskLinkResourceModal.show();
        this.setState({
            linkResourceForm : {...this.initLinkResourceForm},
            selectedLinkedResource :[],
            agree               :       'N'
        })
    }

    submitTaskLinkResourceForm      =   (e)     =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task_resource/link', this.props.access_token, {}, { ...this.state.linkResourceForm,task_id : this.state?.taskDetail?.id }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.setState({ linkResourceForm: { ...this.initLinkResourceForm },taskPerformed:true })
            this.getLinkedResourceList(this.state?.taskDetail?.id);
            this.getTaskDetail(this.state?.taskDetail?.id);
            //this.loadListingTblData(1)
            this.taskLinkResourceModal.hide();
        }).then(() => this.setState({ saveFormSubmitting: false }));
        
    }

    delinkResourceInit      =       (id)        =>      {
        swal({
            title: "Delink",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                let frmData = {resource_id: id , task_id : this.state?.taskDetail?.id}
                HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task_resource/unlink' , this.props.access_token, {},{...frmData} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.getLinkedResourceList(this.state?.taskDetail?.id);
                }).then(() => { });
            }
        });
    }

    viewLinkedResourceDetailInit        =       (id)      =>      {
        this.resourceDetailRef.current.detailModalInit(id);
    }

    changeTaskStatusInit            =       (key='')      =>      {
        this.changeTaskStatusModal.show();
        this.setState({
            statusKey   :   key
        })
        if(!this.state.taskStatusFormDataLoaded){
            this.getTaskStatusFormData();
        }
    }

    getTaskStatusFormData           =       ()      =>      {
        this.setState({task_form_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task_status_form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;

           this.setState({
                taskStatusFormDataLoaded    :   true,
                allTaskStatus         :       respData.status && respData.status.length > 0 ? respData.status.map(st => {return({value:st.key,label:st.status})}) : [],
                allImpactStatus       :       respData.impact_status && respData.impact_status.length > 0 ? respData.impact_status.map(st => {return({value:st.key,label:st.status})}) : [],
            })
        }).then(() => this.setState({task_form_loading: false}));
    }

    submitChangeStatusForm         =       (e)      =>      {
        e.preventDefault();
        let frmData = {
            ...this.state.changeTaskStatusForm,
            id      :       this.state?.taskDetail?.id,
            key     :       this.state.statusKey
        }
        this.setState({changeStatusFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task_status', this.props.access_token, {}, { ...frmData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ changeTaskStatusForm: { ...this.initChangeTaskStatus },taskPerformed : true })
                this.getTaskDetail(this.state?.taskDetail?.id);
                //this.loadListingTblData(1)
                this.changeTaskStatusModal.hide();
            }).then(() => this.setState({ changeStatusFormSubmitting: false }));
    }
   
    taskTrackerJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Task Tracker List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.state.permissions.includes('task_add') &&  <button type="button" className="btn btn-primary" onClick={() => this.addTaskFormInit()}>New</button>}
                            <button type="button" className="btn btn-secondary" onClick={this.taskSearchModalInit} disabled={this.state.listing_loading} >
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Task : {this.state.taskDetail ? this.state.taskDetail.name : ""} </h3>
                            <div className="text-end mt15">
                            {this.state.permissions.includes('task_edit') && <button type="button" className="btn btn-secondary" onClick={this.editTaskInit}>
                                   Edit
                                </button>}
                                {this.state.permissions.includes('task_resource_link') &&   <button type="button" className="btn btn-secondary" onClick={this.linkResourceInit}>
                                   Link Resource
                                </button>}
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {this.state.permissions.includes('task_status') &&  <li>
                                    <a href='#' className={['dropdown-item'].join(' ')} onClick={() => this.changeTaskStatusInit('status')}>Change Task Status</a>
                                </li>}
                                {this.state.permissions.includes('task_impact_status') &&   <li>
                                    <a href='#' className={['dropdown-item'].join(' ')} onClick={() => this.changeTaskStatusInit('impact_status')}>Change Impact Status </a>
                                </li>}
                                
                           </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.taskListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.taskDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    taskListingJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.taskSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        {this.state.minimizeTable ? <th scope="col" style={{ width: "15%" }}>Name</th>
                            : <Ax>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" style={{ width: "15%" }}>Code</th>
                                <th scope="col">Name</th>
                                <th scope="col" style={{ width: "20%" }}>Module</th>
                                <th scope="col" style={{ width: "10%" }}>Task Status</th>
                                <th scope="col" style={{ width: "15%" }}>Impact Status</th>
                                <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                            </Ax>}

                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.task_listing && this.state.task_listing.length > 0
                            ? (this.state.task_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {this.state.minimizeTable ? <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item.id)}>{item.name ? item.name : ""}</td>
                                        : <Ax>
                                            <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                            <td>{item.key ? item.key : ""}</td>
                                            <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item.id)}>{item.name ? item.name : ""}</td>
                                            <td>{item.module_display ? item.module_display : "-"}</td>
                                            <td className='text-center'><Status color={item.status.status_color_code}>{item.status.status_text}</Status></td>
                                            <td className='text-center'>{item.impact_status ? <Status color={item.impact_status.status_color_code}>{item.impact_status.status_text} </Status> : ""}</td>
                                         
                                            <td className='text-center'>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" onClick={() => this.viewDetailInit(item.id)} role="button">View</a></li>
                                                </ul>
                                            </td>
                                        </Ax>}

                                </tr>)
                            }))
                            : (<tr><td colSpan="7" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    taskDetailJsx                  =       ()      =>      {
        let detail = this.state.taskDetail ? this.state.taskDetail : null
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>

                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="resource-tab" data-bs-toggle="tab" data-bs-target="#resource"
                        type="button" role="tab" aria-controls="resource" aria-selected="true">Resource
                    </button>

                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="impact-tab" data-bs-toggle="tab" data-bs-target="#impact"
                        type="button" role="tab" aria-controls="impact" aria-selected="true">Impact History
                    </button>

                </li>
             
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.detail_loading ? <Loader />
                        : <Ax>
                            <div className="tab_content_header">
                                <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody className='resource_table'>
                                        <tr>
                                            <td style={{ width: "25%" }}>Task Status</td>
                                            <th><Status color={detail.status.status_color_code}>{detail.status.status_text}</Status></th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Impact Status</td>
                                            <th>{detail.impact_status ? <Status color={detail.impact_status.status_color_code}>{detail.impact_status.status_text}</Status> : ""}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Type</td>
                                            <th>{detail.type_display ? detail.type_display : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Name</td>
                                            <th>{detail.name ? detail.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Code</td>
                                            <th>{detail.key ? detail.key : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Description</td>
                                            <th>{detail.description ? renderHTML(detail.description) : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Module</td>
                                            <th>{detail.module_display ? detail.module_display : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Parent UC</td>
                                            <th>{detail.parent ? <span>{detail.parent.name} ({detail.parent.key})</span> : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Project Management Tool</td>
                                            <th>{detail.pmt_task_id ? detail.pmt_task_id : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Release Details</td>
                                            <th>{detail.release_num ? detail.release_num : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Ax>}
                </div>
                <div className="tab-pane" id="resource" role="tabpanel" aria-labelledby="resource-tab">
                    {this.state.resource_loading ? <Loader />
                        : <Ax>

                            {this.state.linkedResourceList && this.state.linkedResourceList.length > 0
                                ? <Ax>
                                    <div className="tab_content_header">
                                        {this.state.linkedResourceList.map((resources, index) => {
                                            return (<Ax>
                                                <div className="tab_content_wrapper"> <span className="content_heading">{resources.type.name}</span></div>
                                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                                    <thead className="table-secondary">
                                                        <tr className='text-center'>
                                                            <th style={{ width: "10%" }}>S.No</th>
                                                            <th>Name</th>
                                                            <th style={{ width: "30%" }}>Attribute</th>
                                                           <th style={{ width: "10%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            resources.rosources.map((list, index) => {
                                                                return <tr>
                                                                    <td scope="row" className='text-center'>{index + 1}</td>
                                                                    <td scope="row" className='link-primary cursor_pointer' onClick={() => this.viewLinkedResourceDetailInit(list.id)}>{list.name}</td>
                                                                    <td scope="row">{list.path === null ? "-" : list.path}</td>
                                                                    <td scope="row" className='text-center'>
                                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        {this.state.permissions.includes('resource_view') && <li><a className="dropdown-item" onClick={() => this.viewLinkedResourceDetailInit(list.id)} role="button">View</a></li>}
                                                                        {this.state.permissions.includes('task_resource_delink') &&    <li><a className="dropdown-item" onClick={() => this.delinkResourceInit(list.id)} role="button">Delink</a></li>}
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                    </tbody>
                                                </table>
                                            </Ax>)
                                        })}
                                    </div>
                                </Ax>
                                : <Ax>
                                    <div class="alert alert-secondary text-center align-middle my-2" role="alert">
                                        No Resource Linked
                                    </div>
                                </Ax>}

                        </Ax>}
                </div>
                <div className="tab-pane" id="impact" role="tabpanel" aria-labelledby="impact-tab">
                <div className="tab_content_header">
                        <div className="tab_content_wrapper"> <span className="content_heading">Impact History</span>
                           
                        </div>
                        <hr />
                            <div className="my-1">
                            {this.state.impact_loading ? <div><Loader/></div>
                                : this.state.impact_listing && this.state.impact_listing.length > 0 
                                ? this.state.impact_listing.map(((vs,key) => {
                                    return(<Ax>
                                        <div className="row" key={key}>
                                    <div className="col-sm-8" >{vs.notes ? vs.notes : "-"} </div>
                                    <div className="col-sm-4 text-end">
                                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" /> */}
                                        <span>{vs.updated_user} on {vs.created_at_display}</span>
                                    </div>
                                
                                </div><hr />
                                    </Ax>)
                                }))
                                : <div class="alert alert-secondary text-center align-middle my-2" role="alert">
                                No Impact Recorded
                            </div>}
                        
                        </div>
                    </div>
                </div>
             
            </div>
        </section>);    
    }

    taskSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="taskSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Task Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="taskSearchForm" onSubmit={this.submitTaskSearchForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Name</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_name"
                                            type="text"
                                            value={this.state.taskSearchForm.search_name}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "taskSearchForm")}
                                            placeholder="Please enter Task Name"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Number</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_number"
                                            type="text"
                                            value={this.state.taskSearchForm.search_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "taskSearchForm")}
                                            placeholder="Please enter Task Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Resource</label>
                                    <div className="col-sm-10">
                                        <InputResourceSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'taskSearchForm', 'search_resource_id');
                                                this.setState({ selectedResource: selectedOption ? selectedOption : null });
                                            }}
                                            placeholder="Search Resource"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.selectedResource ? { ...this.state.selectedResource } : null}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

   taskLinkResourceModalJsx               =        ()             =>        {
       return (
           <div className="modal fade" id="taskLinkResourceModal" tabIndex="-1">
               <div className="modal-dialog modal-lg">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h5 className="modal-title" id="searchAssetModalLabel">Task Link Resource</h5>
                           <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                       </div>
                       <form id="taskLinkResourceForm" onSubmit={this.submitTaskLinkResourceForm}>
                           <div className="modal-body">
                               <div className="row my-3 align-items-center">
                                   <label className="col-sm-2 col-form-label col-form-label-sm  ">Task Name</label>
                                   <div className="col-sm-10">
                                       <input
                                           type="text"
                                           value={this.state?.taskDetail?.name}
                                           className="form-control"
                                           disabled
                                           style={{ height: "30px", fontSize: "93%" }}
                                       />
                                   </div>

                               </div>

                               <div className="row my-3 align-items-center">
                                   <label className="col-sm-2 col-form-label col-form-label-sm  ">Resource</label>
                                   <div className="col-sm-10">
                                       <InputResourceSearch
                                           isMulti={true}
                                           changeEvent={(selectedOption) => {
                                               this.tapSelectChange(selectedOption, 'linkResourceForm', 'resource_ids', () => {
                                                   this.setState({ selectedLinkedResource: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                               });
                                           }}
                                           placeholder="Search Resource"
                                           containerHeight="30px"
                                           fontSize="93%"
                                           value={this.state.selectedLinkedResource && this.state.selectedLinkedResource.length > 0 ? this.state.selectedLinkedResource : []}
                                       />
                                   </div>

                               </div>

                           </div>
                           <div className="modal-footer d-flex justify-content-between  row">
                               <div className="col text-start">

                                   {/* <input
                                       name="agree"
                                       type="checkbox"
                                       className="form-check-input"
                                       id="agree"
                                       required
                                       value={this.state.agree}
                                       onChange={(e) => {
                                           this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                       }}
                                       checked={this.state.agree == 'Y'}
                                   />
                                   <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label> */}
                               </div>
                               <div class="col text-end">
                                   <button type="button"
                                       disabled={this.state.saveFormSubmitting}
                                       className="btn btn-secondary mx-2"
                                       data-bs-dismiss="modal"

                                   >Close</button>
                                   <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">
                                       Submit {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                   </button>
                               </div>

                           </div>

                       </form>
                   </div>
               </div>
           </div>
       );
    }

    viewLinkResourceModalJsx               =        ()             =>        {
        let detail = this.state.viewLinkedResourceDetail ? this.state.viewLinkedResourceDetail : null
        return (
            <div className="modal fade" id="viewLinkResourceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">View Link Resource Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "25%" }}>Task Name</td>
                                            <th>{this.state?.taskDetail?.name}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Resource Name</td>
                                            <th>{detail && detail.name ? detail.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Description</td>
                                            <th>{detail && detail.description ? renderHTML(detail.description) : "-"}</th>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button"  className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
     }

     changeTaskStatusJsx               =        ()             =>        {
        
         return (
             <div className="modal fade" id="changeTaskStatusModal" tabIndex="-1">
                 <div className="modal-dialog modal-lg">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="modal-title" id="searchAssetModalLabel">Change {this.state.statusKey == "status" ? "Task" : "Impact"} Status</h5>
                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                         <form onSubmit={this.submitChangeStatusForm}>
                             <div className="modal-body">
                                 <div className="row my-3 align-items-center">
                                     <label className="col-sm-3 col-form-label col-form-label-sm  ">Task Name</label>
                                     <div className="col-sm-9">
                                         <input
                                             type="text"
                                             value={this.state?.taskDetail?.name}
                                             className="form-control form-control-sm"
                                             disabled
                                         />
                                     </div>

                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3 col-form-label col-form-label-sm require">Change Status</div>
                                     <div className="col-sm-9">
                                         {this.state.statusKey == 'status'
                                             ? <TapSelect
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'changeTaskStatusForm', 'status')

                                                 }}
                                                 options={this.state.allTaskStatus}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 required={true}
                                                 value={this.state.allTaskStatus.find(s => s.value == this.state.changeTaskStatusForm.status)}
                                                 placeholder="Select Status"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                             : <TapSelect
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'changeTaskStatusForm', 'status')

                                                 }}
                                                 options={this.state.allImpactStatus}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 required={true}
                                                 value={this.state.allImpactStatus.find(s => s.value == this.state.changeTaskStatusForm.status)}
                                                 placeholder="Select Impact Status"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />}
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3 col-form-label col-form-label-sm require">Notes</div>
                                     <div className="col-sm-9">
                                         <textarea
                                             name="notes"
                                             value={this.state.changeTaskStatusForm.notes}
                                             onChange={(e) => this.formInputHandler(e, 'changeTaskStatusForm')}
                                             className="form-control"
                                             style={{ height: "100px" }}
                                             required={true}
                                         />
                                     </div>
                                 </div>

                             </div>
                             <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.changeStatusFormSubmitting}
                                 >Close  {this.state.changeStatusFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                 <button type="submit" className="btn btn-primary" disabled={this.state.changeStatusFormSubmitting}>Save  {this.state.changeStatusFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         );
   }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Task Tracker Listing</title></Helmet>
            {this.taskTrackerJsx()}
            {this.taskSearchModalJsx()}
            {this.taskLinkResourceModalJsx()}
           {this.changeTaskStatusJsx()}
           <ResourceTrackerDetailModal ref={this.resourceDetailRef}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :    state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TaskTrackerList);