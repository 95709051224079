import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import ReactPaginate from "react-paginate";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon"
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { DisplayListPagination } from '../../components/TapUi';
import { Link } from 'react-router-dom';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';
import {AllCheckerCheckbox, Checkbox, CheckboxGroup} from "@createnl/grouped-checkboxes";


class RoleListing extends AppBaseComponent {
  
    constructor() {
        super();
     
        this.state = {
            minimizeTable              :         false,
            view_loading               :        false, 
            listing_loading            :        false,
            role_listing               :        [],
            totalListingCount          :        [],
            listing_tbl_page           :        1 ,
            formDataLoaded             :        false,
            form_loading               :        false,
            allRolesList                :       [],
            moduleListChecked           :       [],
            updateRole                  :       {parent_role : '',name : '',id:''},
            saveFormSubmitting          :       false,
            permission_loading          :       false,
            permissions                 :       [],
            appPermissions              :       []
           
        }
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
   }

    componentDidMount() {
        this.initalizeComponent(this.props);
        this.loadListingTblData(1);
        this.updateRoleModal             =        new Modal(document.getElementById('updateRoleModal'), {keyboard: false, backdrop :false});
      
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.appPermissions !== this.props.appPermissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            appPermissions     :       pr.appPermissions && pr.appPermissions.length > 0 ? pr.appPermissions : [],
        })
    }

    loadListingTblData                 =   (page = 1,id=null)  =>         {
       
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role', this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                role_listing            :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
                
        },() => {
            
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    viewRoleDetail      =       (id)   => {
        this.setState({minimizeTable : true})
        if (id && this.state.role_listing && this.state.role_listing.length > 0) {
            this.setState({
                roleData        :       this.state.role_listing.find(rl => rl.id == id)
            })
        }
        if(document.getElementById("permissionUpdateForm")) {
            let form = document.getElementById("permissionUpdateForm")
            form.reset();
        }
        this.getPermissionListing(id)
    }

    getPermissionListing        =       (id)        =>      {
        this.setState({permission_loading: true,});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role_permission/' + id, this.props.access_token,{}, {}, (resp) => {
        
            let respData = resp.data;
           
            this.setState({
                permissions     :   respData
            })
       
        }).then(() => this.setState({permission_loading: false}));
    }

    closeViewDetails        =   ()      =>      {
        this.setState({minimizeTable  : false})
    }

    updateRoleModalInit     =     (data=null)        =>      {
        this.updateRoleModal.show();
        if (!this.state.formDataLoaded) {
            this.getUpdateRoleFormData()
        }
      
        if (data) {
            this.setState({
                editRole        :   true,
                updateRole      :   { 
                    id          :   data.id,
                    parent_role :   data.parent_role_data ? data.parent_role_data.id : '', 
                    name        :   data.name 
                }
            })
        }else{
            this.setState({
                editRole        :   false,
                updateRole      :   {parent_role : '',name : '',id:''}
            })
        }
    }

    getUpdateRoleFormData       =   (e)      =>      {
        this.setState({form_loading : true})
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role_form_data', this.props.access_token, {}, {}, (resp) => {
           let respData = resp.data
           this.setState({
                formDataLoaded : true,
                allRolesList   :  respData && respData.roles && respData.roles.length > 0 ? respData.roles.map(rl => {return({value:rl.id,label:rl.name})}): [] 
            })
          }).then(() => this.setState({form_loading : false}))
    }

    submitUpdateRoleForm        =       (e)      =>      {
        e.preventDefault();
        if (this.state.editRole) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT',process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role', this.props.access_token, {}, { ...this.state.updateRole }, (resp) => {
                toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
                this.updateRoleModal.hide()
                this.loadListingTblData(1)

            }).then(() => this.setState({ saveFormSubmitting: false }))
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role', this.props.access_token, {}, { ...this.state.updateRole }, (resp) => {
                toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
                this.updateRoleModal.hide()
                this.loadListingTblData(1)

            }).then(() => this.setState({ saveFormSubmitting: false }))
        }
    }

   
    deleteRole              =       (id)        =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role/' + id, this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.loadListingTblData(1);
                    this.setState({minimizeTable : false})
                    
                });
            }
        });
    }

    moduleListChecked=[]
    onCheckboxChange = (id, checkboxes) => {
        let str = ''
        let obj = {}
        if (id == id) {
            for (let key in checkboxes) {
                let subset = (({value, checked}) => ({value, checked}))(checkboxes[key]);
                if(subset.checked === true){
                    obj[subset.value] = subset.checked;
                    obj['module_id'] = id
                }
            }
            this.removeByAttr(this.moduleListChecked, 'module_id', id);
            this.moduleListChecked.push(obj)

        }

        return this.moduleListChecked

    }

    removeByAttr = function (arr, attr, value) {
        let i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {
                arr.splice(i, 1);
            }
        }
         return arr;
    }

    getViewValue(permission_key, id) {
        return this.state.permissions.some((permission) => {
            return permission.sub_sections.some((sub_section) => {
                return sub_section.permissions.some((item) => {
                    return item.id === permission_key && item.access === true;
                });
            });
        });
    }
    

    updatePermission(e){
        e.preventDefault();
        let list;
        
        let newArray = this.moduleListChecked.filter(value => Object.keys(value).length !== 0);
        for (let i = 0; i < newArray.length; i++) { // the plainest of array loops
            list =  newArray.map(value => Object.keys(value).filter(i => i !== 'module_id').map((acc, curr)=>{return acc}))
        }

         let  frmData = {
             role_id : this.state.roleData.id,
             permissions : list.flat()
        }; 

        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role_permission', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getPermissionListing(this.state.roleData.id)
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }


    roleListjsx            =       ()      =>    {
      
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Role List</h3>
               {this.state.appPermissions.includes('role_add') &&  <div className="text-end mt15">
                    <button type="button" disabled={this.state.listing_loading} onClick={() => this.updateRoleModalInit()} className="btn btn-primary" >Add </button>
                </div>}
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Role : {this.state.roleData && this.state.roleData.name  ? this.state.roleData.name : "-"} </h3>
                            <div className="text-end mt15">
                               
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.roleListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.roleViewDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    roleListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" style={{ height: "100%" }}>

            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "20%" }}>Role</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center"> S.No</th>
                            <th scope="col" style={{ width: "20%" }}>Role</th>
                            <th scope="col" style={{ width: "20%" }}>Reportee Role</th>
                            <th scope="col" style={{ width: "5%" }} className="text-center">Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="4"><Loader /></td></tr>)
                        : (this.state.role_listing && this.state.role_listing.length > 0
                            ? (this.state.role_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" onClick={() => this.viewRoleDetail(item.id)} className="link-primary">{item.name}</a>
                                                        <br />
                                                        {item.parent_role_data && <small><span>Parent Role : ({item?.parent_role_data?.name ?? "-"})</span></small>}
                                                    </td>
                                                </Ax>
                                            )
                                            : (
                                                <Ax>
                                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                    <td><a role="button" onClick={() => this.viewRoleDetail(item.id)} className="link-primary">{item.name}</a></td>
                                                    <td>{item?.parent_role_data?.name ?? "-"}</td>

                                                    <td className="text-center">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        {this.state.appPermissions.includes('role_permission_view') && <li><a className="dropdown-item" role="button" onClick={() => this.viewRoleDetail(item.id)} >View</a></li>}
                                                            {this.state.appPermissions.includes('role_edit') && <li><a className="dropdown-item" role="button" onClick={() => this.updateRoleModalInit(item)} >Edit</a></li>}
                                                            {this.state.appPermissions.includes('role_delete') && <li><a className="dropdown-item" role="button" onClick={() => this.deleteRole(item.id)} >Delete</a></li>}
                                                        </ul>
                                                    </td>
                                                </Ax>
                                            )
                                    }
                                </tr>)
                            }))
                            : (<tr><td colSpan="4" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </div>)
    }

    roleViewDetailJsx              =   ()  =>  {
        let role                           =   this.state.roleData;
       
        return (<section className=" bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">

                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="permission-tab" data-bs-toggle="tab" data-bs-target="#permission"
                        type="button" role="tab" aria-controls="permission" aria-selected="false">Permission
                    </button>
                </li>


            </ul>
            <div className="tab-content" id="myTabContent">
                {this.state.permission_loading ? <div className='text-center'><Loader/></div>
                : <form className="bg-white px-2" onSubmit={this.updatePermission.bind(this)} id="permissionUpdateForm">
                    <div className="tab-pane show active " id="permission" role="tabpanel" aria-labelledby="permission-tab">
                        <div className="tab_content_header">
                            <div className="tab_content_wrapper my-2"><span className="content_heading">Permission Details</span></div>
                            {this.state.permissions && this.state.permissions.map((ps, key) => {
                                return (<table className="table table-bordered table-hover bg-white mb-3" key="key">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "30%" }}>{ps.name}</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ps.sub_sections && ps.sub_sections.length > 0 && ps.sub_sections.map(module => {
                                            return (<tr className="permission_row">
                                                <td>{module.name}</td>

                                                <CheckboxGroup onChange={this.onCheckboxChange.bind(this, module.id)}>

                                                    <td className="text-center">{module.permissions && module.permissions.length > 0 && <AllCheckerCheckbox />}</td>
                                                    <td className="text-center">{module.permissions.map((permission_key,index) => {return(permission_key.type == "view"  &&  <Checkbox value={permission_key.id} checked={this.getViewValue(permission_key.id, module.id)} /> )})}</td>
                                                    <td className="text-center">{module.permissions.map((permission_key,index) => {return(permission_key.type == "add"   &&  <Checkbox value={permission_key.id} checked={this.getViewValue(permission_key.id, module.id)} /> )})}</td>
                                                    <td className="text-center">{module.permissions.map((permission_key,index) => {return(permission_key.type == "edit"   &&  <Checkbox value={permission_key.id} checked={this.getViewValue(permission_key.id, module.id)} /> )})}</td>
                                                    <td className="text-center">{module.permissions.map((permission_key,index) => {return(permission_key.type == "delete"  &&  <Checkbox value={permission_key.id} checked={this.getViewValue(permission_key.id, module.id)} /> )})}</td>
                                                    <td className="text-center">
                                                        {module.permissions && module.permissions.length > 0 && module.permissions.find(md => md.type == "other") && <Ax>
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                {module.permissions.map((permission_key, index) => {
                                                                    return (permission_key.type == "other" &&
                                                                        <li className="dropdown-item" style={{ display: "flex" }}><Checkbox value={permission_key.id} checked={this.getViewValue(permission_key.id, module.id)} />
                                                                            <span className="mx-2" >{permission_key.name}</span></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Ax>}

                                                    </td>
                                                  
                                                </CheckboxGroup>

                                            </tr>)
                                        })}
                                      
                                    </tbody>
                                </table>)
                            })}

                            <div className="float-end my-2">
                            <button type="submit" disabled={this.state.saveFormSubmitting || !this.state.appPermissions.includes('role_permission_edit')} className="btn btn-primary">
                                        Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                            </div>
                        </div>
                    </div>

                </form>}


            </div>
        </section>);
    }

    updateRoleModalJsx             =   ()  =>  {
        return (
            <div className="modal fade" id="updateRoleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.editRole ? "Edit" : "Add"} Role</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitUpdateRoleForm} id="updateRoleModalForm">
                            <div className="modal-body">
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Role Name</label></div>
                                    <div className="col-sm-8">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            value={this.state.updateRole.name}
                                            placeholder="Please Enter Name"
                                            autoComplete='off'
                                            onChange={(e) => this.formInputHandler(e, "updateRole")}/>
                                    </div>
                                </div>
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-4"><label className="form-label require">Reportee Role</label></div>
                                    <div className="col-sm-8">
                                        <TapSelect
                                            options={this.state.allRolesList.filter(r => r.value != this.state.updateRole.parent_role)}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Reportee Role"
                                            required={true}
                                            value={this.state.allRolesList.find(rl => this.state.updateRole.parent_role == rl.value)}
                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'updateRole', 'parent_role') }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                            <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
       
        return (
            <ApplicationLayout>
                  <Helmet><title>Roles List</title></Helmet>
                  {this.roleListjsx()}
                  {this.updateRoleModalJsx()}
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   [],
        group_permission        :   [],
        appPermissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};


export default connect(mapStateToProps)(RoleListing);