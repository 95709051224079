import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';
import moment from 'moment';
import DatePicker from "react-datepicker";


class MomAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddMeetingForm           =        {
            meeting_at            :           '',
            title               :           '',
            company_name         :           '',
            company_id            :           '',
            present_member       :           [],
            notes                :           '',    
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addMeetingForm                :       {...this.initAddMeetingForm},
            editMeeting                   :        false,
            allCompanyList              :          [],
            addNewCompany               :           'N',
            members                     :           ['']
        };
    }

    componentDidMount() {
       this.getAddFormData();
    }

    getAddFormData       =   ()        =>      {
        this.setState({form_loading: true});
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/mom/form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                allCompanyList             : respData && respData.companies && respData.companies.length > 0 ? respData.companies.map(tp => {return({value:tp.id,label:tp.name})}) : [],
                
             },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.editMeetingData) {
                    let detail = this.props.location.state.editMeetingData;
                    this.setState({
                       addMeetingForm : {
                            ...this.initAddMeetingForm,
                            id                  :           detail.id,
                            meeting_at            :          detail.meeting_at,
                            title               :           detail.title,
                            company_name         :           '',
                            company_id            :          detail.company ? detail.company.id : "",
                            notes                :           detail.notes,
                            
                       },
                       members                  :   detail.present_member,
                       editMeeting             :   true
                    })
                }
             })
           
        }).then(() => this.setState({form_loading: false}));
    }

    handleMemberValue = (e, i) => {
        let members = [...this.state.members]
        members[i] = e.target.value
        this.setState({
            members
        })
    }

    handleDeleteMember = i => e => {
        e.preventDefault()
        if (i >= 0) {
            let members = [
                ...this.state.members.slice(0, i),
                ...this.state.members.slice(i + 1)
            ]
            this.setState({
                members
            })
        }
    }

    addMember = e => {
        e.preventDefault()
        let members = this.state.members.concat([''])
        this.setState({
            members
        })
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/meeting_list` })

    }

    submitAddMeetingForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addMeetingForm,
            present_member : this.state.members,
         }
        if (this.state.editMeeting) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/mom/edit', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                //this.setState({ addMeetingForm: { ...this.initaddMeetingForm } })
                this.props.history.push({ pathname: `/meeting_list`,state:{id:this.state.addMeetingForm.id} })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/mom/add', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addMeetingForm: { ...this.initAddMeetingForm } })
                this.props.history.push({ pathname: `/meeting_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
        
        
    }


    saveMeetingFormJsx         =        ()       =>      {
        
        return (<form className="bg-white p-3" onSubmit={this.submitAddMeetingForm} id="saveMeetingForm">
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">Meeting Date</label>
                </div>
                <div className="col-sm-7 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addMeetingForm.meeting_at
                                ? moment(this.state.addMeetingForm.meeting_at).toDate()
                                : false
                        }
                        name="meeting_at"
                        onChange={(value, event) => { this.formDateHandler('meeting_at', value, 'addMeetingForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        placeholderText={`Please Enter Meeting Date`}
                        required={true}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label  form-label">Company</label>
                <div className="col-sm-7">
                    {this.state.addNewCompany == "Y"
                        ? <input
                            name="company_name"
                            type="text"
                            value={this.state.addMeetingForm.company_name}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addMeetingForm")}
                            placeholder="Please enter Company"

                        />
                        : <TapSelect
                            options={this.state.allCompanyList}
                            changeEvent={(selectedMember) => {
                                this.tapSelectChange(selectedMember, 'addMeetingForm', 'company_id');
                            }}
                            isSearchable={true}
                            value={this.state.allCompanyList.find(s => this.state.addMeetingForm.company_id == s.value)}
                            isClearable={true}
                            placeholder="Select Company"
                            autoCompelete="off"
                        />}
                </div>
                <div className="col-sm-2">
                    <input
                        name="addNewCompany"
                        type="checkbox"
                        value={this.state.addNewCompany}
                        onChange={(e) => {
                            this.setState({
                                addNewCompany: this.state.addNewCompany == 'N' ? 'Y' : 'N',
                                addMeetingForm: this.state.addNewCompany == 'N' ? { ...this.state.addMeetingForm, company_id: '' } : { ...this.state.addMeetingForm }
                            })
                        }}
                        checked={this.state.addNewCompany == 'Y'}
                        className="form-check-input"
                        id="addNewCompany"

                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewCompany">Add New </label>
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label  form-label require">Members Present</label>
                <div className="col-sm-7">

                    {this.state.members && this.state.members.length >= 0 &&
                        this.state.members.map((i, index) => {
                            return (<div className='row align-items-center '>

                                <div className={this.state.members.length > 1 ? "col-sm-10" : "col-sm-12"}>
                                    <input
                                        name={i}
                                        type="text"
                                        className="form-control my-2"
                                        value={i}
                                        // disabled={this.state.edit === true ? true : false}
                                        placeholder="Please Enter Member"
                                        required={true}
                                        autoComplete='off'
                                        onChange={(e) => this.handleMemberValue(e, index)}
                                    />
                                </div>
                                {this.state.members.length > 1 ?
                                    <div className="col-sm-2  text-center"><button className="btn btn-secondary"
                                        onClick={this.handleDeleteMember(index)}>X</button></div> : null}
                            </div>
                            )
                        })}



                </div>
                <div className='col-sm-2'>
                    <div className="link-primary cursor_pointer" onClick={this.addMember}>Add New Member</div>
                </div>

            </div>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">Title</label>
                </div>
                <div className="col-sm-7 ">
                    <input
                        name="title"
                        type="text"
                        value={this.state.addMeetingForm.title}
                        className="form-control"
                        autoComplete="off"
                        required={true}
                        onChange={(e) => this.formInputHandler(e, "addMeetingForm")}
                        placeholder="Please enter Title"
                    />
                </div>
            </div>


            <div className="row  my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">Notes</label>
                </div>
                <div className="col-sm-7 ">
                    <CKEditor
                        data={this.state.addMeetingForm.notes}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {

                            this.setState({ addMeetingForm: { ...this.state.addMeetingForm, notes: editor.getData() } })
                        }}

                    />

                </div>
            </div>


            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
            <Helmet><title>M.O.M Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editRelease ? "Edit" : "Add"} M.O.M with Clients</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.saveMeetingFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
    };
};

export default connect(mapStateToProps)(MomAdd);