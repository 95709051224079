import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html'
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import InputResourceSearch from '../../includes/ui/InputResourceSearch';
import swal from "sweetalert";


class TutorialList extends AppBaseComponent {

    constructor(props) {
        super(props);
        

        this.state                           =     {
            
            minimizeTable              :       false,
            listing_loading            :       false,
            listing_tbl_page           :       1,
            tutorial_listing           :       [],
            listingMeta                :       null,
            totalListingCount          :       0,
            tutorialDetail             :       null
        };
    }

    componentDidMount() {
      this.initalizeComponent(this.props); 
       
      this.loadListingTblData(1);
        // if (this.props.location && this.props.location.state && this.props.location.state.data) {
        //     this.viewDetailInit(this.props.location.state.data)
        // }
     
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
            HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/tutorial', this.props.access_token, params, {}, (resp) => {
                let respData            =       resp.data;
                
                this.setState({
                    tutorial_listing      :       respData.data,
                    listingMeta         :       respData.meta,
                    totalListingCount   :       respData.meta.total,
            });
        }).then(() => this.setState({listing_loading: false}));
    }

    addtutorialFormInit          =       ()      =>      {
        this.props.history.push('/tutorial_add')
    }

    viewDetailInit          =       (data)      =>      {
        if(this.props.permissions.includes('tutorial-view')){
            this.setState({
                minimizeTable       :       true,
                tutorialDetail      :       data,
                detail_loading      :       true
            })
            
            setTimeout(() => {
                this.setState({detail_loading : false})
            },1000)
        }else{
            toast.error('You dont have permission to perform this action')
        }
       
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    editTutorialInit        =       ()      =>      {
        this.props.history.push({ pathname: `/tutorial_add`, state: { editTutorialData : this.state.tutorialDetail } });
    }

    deleteTutorialInit      =       ()        =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
            
                HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_SERVER_PATH + '/backoffice/tutorial/' + this.state?.tutorialDetail?.id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.setState({minimizeTable : false});
                    this.loadListingTblData(1);
                }).then(() => { });
            }
        });
    }

   
    tutorialListJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Tutorials List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.props.permissions.includes('tutorial-add') && <button type="button" className="btn btn-primary" onClick={() => this.addtutorialFormInit()}>New</button>}

                        </Ax> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Tutorial : {this.state?.tutorialDetail?.title} </h3>
                            <div className="text-end mt15">
                            {this.props.permissions.includes('tutorial-edit') && <button type="button" className="btn btn-secondary" onClick={this.editTutorialInit}>
                                    Edit
                                </button>}

                                {this.props.permissions.includes('tutorial-delete') && <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a role="button" onClick={() => this.deleteTutorialInit()}
                                                className={'dropdown-item'}
                                                style={{ "pointerEvents": "all" }}>Delete</a></li>

                                    </ul>

                                </span>}
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.tutorialListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.tutorialDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    tutorialListingJsx            =   ()   =>   {
        return (<Ax>
           
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        {this.state.minimizeTable ? <th scope="col" style={{ width: "15%" }}>Title</th>
                            : <Ax>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" style={{ width: "25%" }}>Title</th>
                                <th scope="col" style={{ width: "10%" }}>Section</th>
                                <th scope="col" style={{ width: "10%" }}>Status</th>
                                <th scope="col" style={{ width: "25%" }}>Embeded URL</th>
                                <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                            </Ax>}

                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.tutorial_listing && this.state.tutorial_listing.length > 0
                            ? (this.state.tutorial_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {this.state.minimizeTable ? <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item)}>{item.title ? item.title : "-"}</td>
                                        : <Ax>
                                            <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                            <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item)}>{item.title ? item.title : "-"}</td>
                                            <td className='text-capitalize text-center'>{item.section ? item.section.name : ""}</td>
                                            
                                            <td className='text-center'><Status color={item.status.status_color_code}>{item.status.status_text}</Status></td>
                                            <td>{item.embed_code ? <span>{item.embed_code.slice(0, 45)}{item.embed_code.length > 45 ? '...' : ''}</span> : ""}</td>
                                            <td className='text-center'>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" onClick={() => this.viewDetailInit(item)} role="button">View</a></li>
                                                </ul>
                                            </td>
                                        </Ax>}

                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    tutorialDetailJsx                  =       ()      =>      {
        let detail = this.state.tutorialDetail ? this.state.tutorialDetail : null
        return (<section className="item_view_container bg-white">
            {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>

                </li>
               
             
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab"> */}
                    {this.state.detail_loading ? <div className='text-center'><Loader/></div>
                    : <Ax>
                        {detail && <div className="tab_content_header">
                                <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                            <tbody className='resource_table'>
                                <tr>
                                    <td style={{ width: "25%" }}>Title</td>
                                    <th>{detail.title ? detail.title : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Section</td>
                                    <td>{detail.section ? detail.section.name : ""}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: "25%" }}>Status</td>
                                    <td><Status color={detail.status.status_color_code}>{detail.status.status_text}</Status></td>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Order No</td>
                                    <td>{detail.ordo ? detail.ordo : ""}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Embeded URL</td>
                                    <td>{detail.embed_code ? detail.embed_code : ""}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: "25%" }}>Description</td>
                                    <td className='text-capitalize'>{detail.description ? renderHTML(detail.description) : "-"}</td>
                                </tr>

                            </tbody>
                        </table>
                            </div>}
                      </Ax>}
                {/* </div>
                
             
            </div> */}
        </section>);    
    }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Tutorial Listing</title></Helmet>
            {this.tutorialListJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :    state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TutorialList);