import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import Ax from '../../components/hoc/Ax';
import TappetLogo from '../../assets/img/svg/logo_new.svg';
import AdminAvatar from '../../assets/img/admin_logo.png';
import {Link} from 'react-router-dom';
import './ApplicationHeader.css';
import { connect } from 'react-redux';

class ApplicationHeader extends React.Component {
    
   
    constructor() {
        super();
        this.state      = {
            userDropdown               :        true,
            showModalNew               :        false,
            openQuickNavigation        :         false,

        };

    }

    
    render() {
        return (
            <Ax>
                <div className="app__header container-fluid bg-white" >
                    <div className="row p-2">
                        <div className="col-sm-3">
                            <Link to="/dashboard" className="app-header_logo">
                                <img src={TappetLogo} width="174" height="54" alt="logo" />
                            </Link>
                        </div>
                        <div className="col-sm-4 pt-1"> {/*
                                <div className="input-group">
                                    <TapSelect options={this.options} width='170px' defaultValue="1" />
                                    <input type="text" className="form-control app-search__input" placeholder="Search by Name, Id, Stock" />
                                    <button type="button" className="app-search__button"><img src={SearchIcon} /></button>
                                </div> */}
                        </div>
                        <div className="col-sm-5 applicationHeader">
                            <ul className="nav justify-content-end">
                             
                                <li className="nav-item ">

                                    <a className="nav-link dropdown-toggle" style={{ 'padding': '10px 15px 0px 15px' }} data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                                    <img src={AdminAvatar} style={{ 'height': '39px' }} className="avatar rounded-circle" alt={this.props.user_name} />
                                    </a>
                                    <ul className="dropdown-menu cursor_pointer" >
                                        {/* <li className="dropdown-item">{this.props.user_name}</li>
                                        <li className="dropdown-item" onClick={() => this.addFeedbackModalInit()}>Feedback</li>
                                        <li><Link title='Clear Cache & Data' className="dropdown-item" onClick={() => this.clearCacheDataHandler()}>Refresh</Link></li> */}
                                        <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        user_name           :   state.app.user_info?.name??'',
        permissions         :   state.app && state.app.acl_info ? state.app.acl_info : null
    };
};

export default connect(mapStateToProps)(ApplicationHeader);