import React from 'react';
import Ax from '../../components/hoc/Ax';
import {connect} from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import tapIcon from "../../services/TapIcon"

class ApplicationSidebar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state      =   {
            isMinimizeSidebar   :   false,
            opensubmenu_id      :   null,
            permissions         :      [],
            sidebarMenu         :      []
        };
        
        // this.sidebarMenu           =   [
        //     { to: '/dashboard', name: 'Dashboard', icon: tapIcon.faHome, activate_links: ['/dashboard'], can_access: false, can_display: true },
        //     {
        //         to: null, name: 'Access Module', icon: tapIcon.faCarAlt,
        //         activate_links: ['/module_list','/sub_module_list','/permission_list'],
        //         can_access: false, 
        //         can_display: true,
        //         sub_menus: [
                    
        //             { to: '/module_list', name: 'Modules', can_access : false,can_display : !this.state.permissions.includes("module_view") ? false : true},
        //             { to: '/sub_module_list', name: 'Sub Modules', can_access: false, can_display:!this.state.permissions.includes("sub_module_view") ? false : true },
        //             { to: '/permission_list', name: 'Permission', can_access: false, can_display: !this.state.permissions.includes("permission_view") ? false : true },
        //         ]
        //     },
        //     {
        //         to: null, name: 'Group', icon: tapIcon.faUsers,
        //         activate_links: ['/group_list',],
        //         can_access: false, can_display: true,
        //         sub_menus: [
                    
        //             { to: '/group_list', name: 'Group', can_access: false, can_display: true },
                   
        //         ]
        //     },
        //     {
        //         to: null, name: 'Development', icon: tapIcon.faUsers,
        //         activate_links: ['/task_tracker_list','/resource_list'],
        //         can_access: false, can_display: true,
        //         sub_menus: [
                    
        //             { to: '/task_tracker_list', name: 'Task Tracker', can_access: false, can_display: true },
        //             { to: '/resource_list', name: 'Resource Tracker', can_access: false, can_display: true }
                   
        //         ]
        //     },
        //     {
        //         to: null, name: 'Setting', icon: tapIcon.faUsers,
        //         activate_links: ['/roles','/user_listing'],
        //         can_access: false, can_display: true,
        //         sub_menus: [
        //             { to: '/roles', name: 'Roles', can_access: false, can_display: true }, 
        //             { to: '/user_listing', name: 'User Listing', can_access: false, can_display: true }, 
        //         ]
        //     },
            
        // ] 
    }

    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        },() => {
            this.setState({
                sidebarMenu         :  [
                    { to: '/dashboard', name: 'Dashboard', icon: tapIcon.faHome, activate_links: ['/dashboard'], can_access: false, can_display: true },
                    {
                        to: null, name: 'Access Module', icon: tapIcon.faCarAlt,
                        activate_links: ['/module_list','/sub_module_list','/permission_list'],
                        can_access: false, 
                        can_display: true,
                        sub_menus: [
                            
                            { to: '/module_list', name: 'Modules', can_access : false,can_display : !this.state.permissions.includes("module_view") ? false : true},
                            { to: '/sub_module_list', name: 'Sub Modules', can_access: false, can_display:!this.state.permissions.includes("sub_module_view") ? false : true },
                            { to: '/permission_list', name: 'Permission', can_access: false, can_display: !this.state.permissions.includes("permission_view") ? false : true },
                        ]
                    },
                    {
                        to: null, name: 'Group', icon: tapIcon.faUsers,
                        activate_links: ['/group_list',],
                        can_access: false, can_display: true,
                        sub_menus: [
                            
                            { to: '/group_list', name: 'Group', can_access: false, can_display: !this.state.permissions.includes("group_listing") ? false : true },
                           
                        ]
                    },
                    {
                        to: null, name: 'Development', icon: tapIcon.faUsers,
                        activate_links: ['/task_tracker_list','/resource_list','/learning_list','/releases_list'],
                        can_access: false, can_display: true,
                        sub_menus: [
                            { to: '/learning_list', name: 'Learning', can_access: false, can_display: !this.state.permissions.includes("learning-view") ? false : true  },
                            { to: '/releases_list', name: 'New Releases', can_access: false, can_display: !this.state.permissions.includes("app-release-view") ? false : true  },
                            { to: '/resource_list', name: 'Resource Tracker', can_access: false, can_display: !this.state.permissions.includes("resource_view") ? false : true },
                            { to: '/task_tracker_list', name: 'Task Tracker', can_access: false, can_display: !this.state.permissions.includes("task_view") ? false : true  },
                           
                        ]
                    },
                    {
                        to: null, name: 'Client Support', icon: tapIcon.faUsers,
                        activate_links: ['/training_list','/topics_list','/tutorial_list','/section_list','/feedback_list','/meeting_list'],
                        can_access: false, can_display: true,
                        sub_menus: [
                            { to: '/feedback_list', name: 'Feedback', can_access: false, can_display: !this.state.permissions.includes("feedback-view") ? false : true },
                            { to: '/meeting_list', name: 'M.O.M with Clients', can_access: false, can_display: !this.state.permissions.includes("mom-view") ? false : true },
                            { to: '/section_list', name: 'Section', can_access: false, can_display: true },
                            { to: '/training_list', name: 'Training Request', can_access: false, can_display: !this.state.permissions.includes("training-view") ? false : true },
                            { to: '/topics_list', name: 'Topics', can_access: false, can_display: true },
                            { to: '/tutorial_list', name: 'Tutorial', can_access: false, can_display: !this.state.permissions.includes("tutorial-view") ? false : true },
                            
                        ]
                    },
                    {
                        to: null, name: 'Integration', icon: tapIcon.faUsers,
                        activate_links: ['/iot_service_provider'],
                        can_access: false, can_display: true,
                        sub_menus: [
                           { to: '/iot_service_provider', name: 'IoT Vendors', can_access: false, can_display: !this.state.permissions.includes("iot-vendor-view") ? false : true }
                        ]
                    },
                    {
                        to: null, name: 'Setting', icon: tapIcon.faUsers,
                        activate_links: ['/roles','/user_listing'],
                        can_access: false, can_display: true,
                        sub_menus: [
                            { to: '/roles', name: 'Roles', can_access: false, can_display: !this.state.permissions.includes("role_view") ? false : true }, 
                           { to: '/user_listing', name: 'Operations Team', can_access: false, can_display: !this.state.permissions.includes("operation-team-view") ? false : true  }, 
                        ]
                    },
                    
                ] 
            })
        })
    }
    
    showHideSubMenu                 =   (submenu_id = null)  =>  {
        
        if(this.state.opensubmenu_id == submenu_id) {
            this.setState({'opensubmenu_id' : null});
        } else {
            this.setState({'opensubmenu_id' : submenu_id});
            if(document.getElementById('wrapper').classList.contains('min_sidebar')) {
                document.getElementById('wrapper').classList.remove('min_sidebar');
            }
        }
    }
    
    showSubMenuLinks            =   (submenu_id = null)  =>  {
        this.setState({'opensubmenu_id' : submenu_id});
    }
    

    sidebarCollapse                 =   ()  =>  {
        let isMinimize              =   document.getElementById('wrapper').classList.contains('min_sidebar');
        if(isMinimize) {
            document.getElementById('wrapper').classList.remove('min_sidebar');
        } else {
            document.getElementById('wrapper').classList.add('min_sidebar');
        }
        this.setState({isMinimizeSidebar : !isMinimize});
    }
    
    render () {
      
        return (
            <div className="sidebar">
                <button className="collapse_sidebar_btn" onClick={this.sidebarCollapse}>
                    {this.state.isMinimizeSidebar
                        ? <tapIcon.FontAwesomeIcon icon={tapIcon.faAngleRight} className="icon" />
                        : <tapIcon.FontAwesomeIcon icon={tapIcon.faAngleLeft} className="icon" />
                    }
                </button>
                <ul className="sidebar-menu">
                    {
                        this.state.sidebarMenu.map((m, k) => {

                            let activate_links = m.activate_links ? m.activate_links : [];
                            if (m.name == 'Access Module' && !this.state.permissions.includes("module_view") && !this.state.permissions.includes("sub_module_view") && !this.state.permissions.includes("permission_view")) {
                                m.can_access = "true"; 

                           }
                           
                            return (<li key={k} className={['item', activate_links.includes(this.props.match.path) ? 'active' : ''].join(' ')}>
                                {m.to 
                                    ? (<Link to={m.to} className="menu-btn" onClick={() => this.showHideSubMenu(k)}>
                                        <tapIcon.FontAwesomeIcon icon={m.icon} className="icon" /> <span>{m.name} {m.can_access == "true" ?
                                            <tapIcon.FontAwesomeIcon className="submenu_icon" style={{ top: "10px" }} icon={tapIcon.faLock} />
                                            : null}</span>
                                    </Link>)
                                    : (<Ax>
                                        <Link to="#" className="menu-btn" onClick={() => this.showHideSubMenu(k)}>
                                            <tapIcon.FontAwesomeIcon icon={m.icon} className="icon" /> <span>{m.name}</span>

                                            <tapIcon.FontAwesomeIcon className="submenu_icon" icon={(this.state.opensubmenu_id == k || activate_links.includes(this.props.match.path)) ? tapIcon.faAngleDown : tapIcon.faAngleLeft} />
                                        </Link>
                                        <div className={['sub_menu', (this.state.opensubmenu_id === k || activate_links.includes(this.props.match.path)) ? 'open' : ''].join(' ')}>
                                            {m.sub_menus.map((sm, sk) => sm.can_display ? <Link key={sk} to={sm.to} onClick={() => this.showSubMenuLinks(k)} className={this.props.match.path == sm.to ? 'active' : ''}> <span>{sm.name}  {sm.can_access == "true" ?
                                            <tapIcon.FontAwesomeIcon style={{
                                                position: "absolute",
                                                right: "20px",
                                                top : "10px",
                                                color: "#fff",
                                            }} icon={tapIcon.faLock} />
                                                : null}</span> </Link> : null)}
                                        </div>
                                    </Ax>)
                                }
                            </li>);
                        })
                    }
                </ul>
            </div>
        );
    }
}


const mapStateToProps = state => {
  
    return {
        loading             :           state.auth.loading,
        error               :           state.auth.error,
        isAuthenticated     :           state.auth.access_token !== null,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    }
}

export default connect(mapStateToProps, null)(withRouter(props => <ApplicationSidebar {...props}/>));
