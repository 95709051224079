import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ax from '../../components/hoc/Ax';
import loginStyle from './Login.module.css';
import TappetLogo from '../../assets/img/tappetbox.png';
import MobileLogo from '../../assets/img/tappet-mobile-logo.jpg';
import whiteLogo from '../../assets/img/logo-white.png';
import AlertErrorMsg from '../../components/ui/alert/AlertErrorMsg';
import * as actions from '../../store/actions/index';
import tapIcon from "../../services/TapIcon"

class Login extends React.Component {
    constructor() {
        super();

        this.groupVerifyFormInitialState         =   {
            'group_id'                      :   '',
            'mobile'                        :   '',
            'otp'                           :   ''
        };

        this.init           =       {
            goto_top_visible                :   false,
            form                            :   'loginProcessWithPasswordForm',
            groupVerifyForm                 :   this.groupVerifyFormInitialState,
            groupVerifyFormSubmitting       :   false,
            loginFormErrorMsg               :   null,
            loginFormSuccessMsg             :   null,
            groupUserCanChange              :   true,
            groupUserLoginFormSubmitting    :   false,
            loginProcessWith                :   null,
            forgotPasswordProcess           :   false,
            forgotPasswordForm              :   {...this.groupVerifyFormInitialState, step : 'user_verify'},
            forgotPasswordFormSubmitting    :   false,
        }

        this.state                      =   {
          ...this.init 
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.toggleVisibility);
    }

    scrollToTop                         =   ()  =>  window.scrollTo({ top: 0, behavior: "smooth" })
    toggleVisibility                    =   ()  =>  this.setState({ goto_top_visible: window.pageYOffset > 200 })

    formInputHandler                    =   (e, key = 'groupVerifyForm')  =>  {
        if(key && e.target && e.target.name) {
            this.setState({ [key]: { ...this.state[key], [e.target.name] : e.target.value } });
        }
        this.setState({loginFormErrorMsg : null});
    }

    groupVerifyFormSubmit               =   (event, action = 'otp')  =>  {
        //errors = CustomValdation(this.state.groupVerifyForm, [{'group_id' : 'required', 'mobile' : 'required|min:10|max:10'}]);
        let errors                  =   [];
        if(this.state.groupVerifyForm.group_id.trim() == '') {
            errors                  =   [...errors, 'Please enter Group Id'];
        }
        if(this.state.groupVerifyForm.mobile.trim() == '') {
            errors                  =   [...errors, 'Please enter Mobile No.'];
        }
        const pattern = /^\d+$/;
        if(!pattern.test(this.state.groupVerifyForm.mobile.trim())) {
            errors                  =   [...errors, 'Please enter valid Mobile No.'];
        }
        if (this.state.groupVerifyForm.mobile.trim().length < 10 ||  this.state.groupVerifyForm.mobile.trim().length > 10) {
            errors                  =   [...errors, 'Please enter valid Mobile No. (Only 10 Digit)'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            this.setState({loginFormErrorMsg : errorMsg});
        } else {
            this.setState({loginFormErrorMsg : null});
            this.setState({groupVerifyFormSubmitting : true});
           
            this.setState({groupUserCanChange : false});
            if(action == 'otp') {
                //Send OTP to Login :-
                axios({
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    params:   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'app'},
                    url: process.env.REACT_APP_SERVER_PATH + '/backoffice/login_otp_send'
                }).then((response) => {
                    this.setState({loginProcessWith : action,groupUserLoginFormSubmitting:false,groupVerifyFormSubmitting:false});
                    toast.success('OTP Send Successfully', {position: toast.TOP_RIGHT});
                    
                })
                .catch((err) => {
                        if(err && err.response && err.response.data && err.response.data.message) {
                            this.setState({loginFormErrorMsg : err.response.data.message,
                                groupVerifyFormSubmitting : false,
                                groupUserLoginFormSubmitting:false});
                        }
                    })
            } else {
                action      =   'otp';
                this.setState({loginProcessWith : action},() => {
                    this.setState({groupVerifyFormSubmitting : false,groupUserLoginFormSubmitting:false});
                });
                
            }
           
        }
    }

    groupUserLoginFormSubmit            =   (event)  =>  {
        let errors                          =   [];
       
        if(this.state.loginProcessWith == 'otp' || this.state.loginProcessWith == 'forgot_password_otp') {
            if (this.state.groupVerifyForm.otp.trim().length < 4 ||  this.state.groupVerifyForm.otp.trim().length > 6) {
                errors                  =   [...errors, 'OTP must be between 4 to 6 characters'];
            }
        }
        
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            this.setState({loginFormErrorMsg : errorMsg});
        } else {
            let loginRequest        =   {
                method  : 'POST',
                headers : { 'Accept': 'application/json', 'Content-Type': 'application/json' }
            };
            loginRequest        =   { ...loginRequest,
                params  :   {...this.state.groupVerifyForm},
                url: process.env.REACT_APP_SERVER_PATH + '/backoffice/login_with_otp'
            };
           
           
            if(loginRequest.url) {
                this.setState({groupUserLoginFormSubmitting : true});
                //Auth Action Start :-
                this.props.onAuthStart();
                axios(loginRequest)
                .then((response) => {
                    //Login SuccessFully Now Put Access Token In Redux Data Action Success :-
                    this.props.onAuthSucess(response.data);
                     this.props.applicationInitLoading(response.data.access_token);
                })
                .catch((err) => {
                    this.props.onAuthFail(err);
                    if(err && err.response && err.response.data && err.response.data.message) {
                        this.setState({loginFormErrorMsg : err.response.data.message});
                    }
                }).then(() => {
                    this.setState({groupUserLoginFormSubmitting : false});
                });
            }
        }
    }

    loginFormSubmitHandler              =   (event, action='otp') =>  {
        event.preventDefault();
        if(this.state.loginProcessWith == 'otp' || this.state.loginProcessWith == 'password') {
            this.groupUserLoginFormSubmit(event);
        } else {
            this.groupVerifyFormSubmit(event, action);
        }
       
    }

    resetLoginForm              =       ()      =>      {
        this.setState({...this.init})
    }

    topHeader                           =   ()  =>  {
        return (<nav className={loginStyle.nav_top}>
                    <div className={['container'].join(' ')} >
                        <div className="row">
                            <div className="col-md-7">
                                <a className={loginStyle.social_icon} target="_blank" href="https://www.facebook.com/tappetbox/?ref=aymt_homepage_panel">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faFacebookF} />
                                </a>
                                <a className={loginStyle.social_icon} target="_blank" href="https://twitter.com/tappetbox">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faTwitter} />
                                </a>
                                <a className={loginStyle.social_icon} target="_blank" href="https://www.linkedin.com/company/tappetbox">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faLinkedinIn} />
                                </a>
                                <a className={loginStyle.social_icon} target="_blank" href="https://www.youtube.com/channel/UCq0x42wA3zUr_CPgHH8TaYg">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faYoutube} />
                                </a>
                            </div>
                            <div className="col-md-5 text-end">Call Us Today! 9650-222-555</div>
                        </div>
                    </div>
                </nav>);
    }
    headerLogo                          =   ()  =>  {
        return (<header className={loginStyle.header_wrapper}>
                    <div className={['container'].join(' ')} >
                        <div className="row">
                            <div className="col-md-12">
                                <img src={TappetLogo} height="60" alt="logo"/>
                            </div>
                        </div>
                    </div>
                </header>);
    }
    footer                              =   ()  =>  {
        return (
            <footer className={['container-fluid', 'mt-3', 'pb-3', 'text-white'].join(' ')}>
                <div className="row">
                    <div className="col-md-4">
                        <img src={whiteLogo} className="mt-4" alt="Tappetbox" />
                        <div className={['mx-2', 'mt-2', 'text-sm'].join(' ')}></div>
                    </div>
                    <div className="col-md-4">
                        <p className={['mt-4' , 'text-xl'].join(' ')}>Contact Us</p>
                        <div className={['mx-2', 'mt-2', 'text-sm', 'text-secondary'].join(' ')}>
                            <p> LGF - 001H, Sushant Arcade,<br/> Sushant Lok-1, Gurugram, <br/> Haryana - 122001.<br /></p>
                            <p className="mt-3 mb-2">Phone: 9650-222-555<br/>Email: contact@tappetbox.com </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <p className={['mt-4' , 'text-xl'].join(' ')}>Get Social</p>
                        <div className={['mx-2', 'mt-2', 'text-xl', 'text-secondary'].join(' ')}>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.facebook.com/tappetbox/?ref=aymt_homepage_panel">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faFacebookF} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://twitter.com/tappetbox">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faTwitter} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.linkedin.com/company/tappetbox">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faLinkedinIn} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.youtube.com/channel/UCq0x42wA3zUr_CPgHH8TaYg">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faYoutube} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
    copywrite                           =   ()  =>  {
        return (
            <Ax>
                <div className="footer_copywrite text-center py-3 text-sm bg-dark text-white">Copyright © {new Date().getFullYear()}, Five Dots Solutions Pvt. Ltd. All Rights Reserved.</div>
                {this.state.goto_top_visible ? <div className="goto_top" onClick={this.scrollToTop}><span><tapIcon.FontAwesomeIcon icon={tapIcon.faAngleUp}/></span></div> : null }
            </Ax>
        );
    }
    loginFormJsx                        =   ()  =>  {
        return (
            <form className="p-3 pt-0 sdsadd" onSubmit={this.loginFormSubmitHandler}>
                <div className="mb-3 mt-3">
                    <label className="form-label">Group ID</label>
                    <div className="input-group">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUsers}/></span>
                        <input
                            name="group_id"
                            type="text"
                            value={this.state.groupVerifyForm.group_id}
                            onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Group/Company ID"
                            readOnly={!this.state.groupUserCanChange}
                            tabIndex={1}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">User ID (Mobile No.)</label>
                    <div className="input-group">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUser}/></span>
                        <input
                            name="mobile"
                            type="text"
                            value={this.state.groupVerifyForm.mobile}
                            onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Mobile Number"
                            maxLength="10"
                            readOnly={!this.state.groupUserCanChange}
                            tabIndex={2}
                        />
                    </div>
                </div>
                { this.state.loginProcessWith == 'otp'
                    ? (
                        <div className="mb-3">
                            <label className="form-label">OTP</label>
                            <div className="input-group">
                                <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUnlock}/></span>
                                <input
                                    name="otp"
                                    type="text"
                                    value={this.state.groupVerifyForm.otp}
                                    onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                                    className="form-control"
                                    required={true}
                                    autoComplete="off"
                                    placeholder="Enter OTP"
                                    minLength="4"
                                    maxLength="6"
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                    )
                    : null
                }
              

            
                <div className="d-grid gap-2 mt-4 mb-2">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        tabIndex={5}
                        disabled={this.state.groupUserLoginFormSubmitting || this.state.groupVerifyFormSubmitting ? true : false}
                        >
                            {this.state.loginProcessWith == 'otp' || this.state.loginProcessWith == 'password' ? 'Login' : 'Submit' }
                            {this.state.groupUserLoginFormSubmitting || this.state.groupVerifyFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ml5"/>) : ''}
                    </button>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <button
                            type="button"
                            disabled={this.state.groupVerifyFormSubmitting || this.state.loginProcessWith == 'otp' || this.state.groupUserLoginFormSubmitting ? true : false}
                            onClick={e => this.resetLoginForm()}
                            tabIndex={6}
                            className={['btn', 'btn-link', loginStyle.login_links].join(' ')}>Reset Form</button>
                    </div>
                   
                </div>
               
            </form>
        );
    }
    
    forgotPasswordFormJsx               =   ()  =>  {
        return (
                <form className="px-3">
                <div className="form-group mb-2">
                    <label htmlFor="group_id" className="login-label">Group ID</label>
                    <div className="input-group mt-1">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUsers}/></span>
                        <input
                            name="group_id"
                            type="text"
                            value={this.state.forgotPasswordForm.group_id}
                            onChange={(e) => this.formInputHandler(e,'forgotPasswordForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Group/Company ID"
                            readOnly={!this.state.groupUserCanChange}
                        />
                    </div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="mobile" className="login-label">User ID (Mobile No.)</label>
                    <div className="input-group mt-1">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUser}/></span>
                        <input
                            name="mobile"
                            type="text"
                            value={this.state.forgotPasswordForm.mobile}
                            onChange={(e) => this.formInputHandler(e,'forgotPasswordForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Mobile Number"
                            maxLength="10"
                            readOnly={!this.state.groupUserCanChange}
                        />
                    </div>
                </div>
                {this.state.forgotPasswordForm.step == 'mobile_verify'
                    ? (
                       <div className="form-group mb-2">
                            <label htmlFor="otp" className="login-label">OTP</label>
                            <div className="input-group mt-1">
                                <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUnlock}/></span>
                                <input
                                    name="otp"
                                    type="text"
                                    value={this.state.forgotPasswordForm.otp}
                                    onChange={(e) => this.formInputHandler(e,'forgotPasswordForm')}
                                    className="form-control"
                                    required={true}
                                    autoComplete="off"
                                    placeholder="Enter OTP"
                                    minLength="4"
                                    maxLength="6"
                                />
                            </div>
                        </div>
                    )
                    : null
                }

                <div className="mt-4">
                    <button
                        type="button"
                        onClick={e => this.forgotPasswordFormSubmit(e)}
                        className="btn btn-primary btn-block w-100"
                        disabled={this.state.forgotPasswordFormSubmitting ? true : false}
                        > Submit {this.state.forgotPasswordFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ml5"/>) : ''}
                    </button>
                </div>
                <div className="login-links mt-3 mb-3">
                    <div className="col-6">

                    </div>
                    <div className="col-6 text-end">
                        <button type="button"
                            disabled={this.state.forgotPasswordFormSubmitting ? true : false}
                            onClick={this.loginFormInit}
                            className="login-otp-link btn btn-link">Back To Login</button>
                    </div>
                </div>
            </form>
        );
    }

    render () {
       
        return (
            <div className="pageTbl" style={{height:"100vh"}}>
                {this.props.isAuthenticated ? <Redirect to="/dashboard" /> :  null}
                <ToastContainer />
                {this.topHeader()}
                {this.headerLogo()}
                <div className="container-fluid bg-white">
                    <div className="row">
                        <div className={['col-md-8', 'px-1'].join(' ')}><img src={MobileLogo} className={loginStyle.page_image} alt="Tappetbox"/></div>
                        <div className={['col-md-4', 'p0' ,loginStyle.login_box].join(' ')}>
                            <h3 className="form-header mb-2">{this.state.forgotPasswordProcess ? 'Reset Password' : 'Login'}</h3>
                            { this.state.loginFormErrorMsg ? (<AlertErrorMsg>{this.state.loginFormErrorMsg}</AlertErrorMsg>) : null }
                            { this.state.loginFormSuccessMsg ? (<AlertErrorMsg>{this.state.loginFormSuccessMsg}</AlertErrorMsg>) : null }
                            { this.state.forgotPasswordProcess ? this.forgotPasswordFormJsx() : this.loginFormJsx() }
                            <div className={[loginStyle.tnc, 'text-xs', 'lh-base', 'p-3'].join(' ')}>By Logging, you agree to our <a target="_blank" href="https://tappetbox.in/software-license">Terms & Conditions </a> and that you have read our <a href="https://tappetbox.in/privacy-policy" target="_blank">Privacy Policy</a></div>
                        </div>
                    </div>
                </div>
                {this.footer()}
                {this.copywrite()}
            </div>
        )
    }

}

const mapStateToProps = state => {
 
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token_type: state.auth.token_type,
        isAuthenticated: state.auth.access_token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuthStart             :   () => dispatch(actions.authStart()),
        onAuthSucess            :   (responseData) => dispatch(actions.authSuccess(responseData)),
         applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token)),
        onAuthFail              :   (err)   =>  dispatch(actions.authFail(err)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Login );