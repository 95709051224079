import React from 'react';
import style from './Index.module.css';
import ReactPaginate from "react-paginate";

class DisplayListPagination extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            meta                        :   props && props.meta ? props.meta : null
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.meta !== this.props.meta) {
            this.initilaizeState(nextProps);
        }
    }
    
    initilaizeState                     =   (props)  =>  {
        this.setState({
            meta                            :   props && props.meta ? props.meta : null,
        });
    }

    render                  =   ()  =>  {
        return (
            <div className="row" style={{zIndex : 999}}>
                <div className="col">
                    {this.state.meta ? `Showing ${this.state.meta.from||0} to ${this.state.meta.to||0} of ${this.state.meta.total} entries` : ``}
                </div>
                <div className="col">
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        pageCount={this.state.meta ? this.state.meta.last_page : 0}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={0}
                        forcePage={this.state.meta ? (parseInt(this.state.meta.current_page)-1) : 1}
                        onPageChange={this.props.onPageChange}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination justify-content-end"
                        pageClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        pageLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        activeClassName="active" 
                    />
                </div>
            </div>
        );
    };
}

export default DisplayListPagination;