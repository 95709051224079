import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html'
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import swal from "sweetalert";
import moment from 'moment';
import DatePicker from "react-datepicker";
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';


class ResourceTrackerList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initSearchForm      =       {
            search_resource_type_ids      :       [],
            search_name                   :       '',
            search_attribute              :       '',
        }

        this.searchFormLabel       =       {
            search_resource_type_ids        :       'Resource Type : ',
            search_name                     :       'Name : ',
            search_attribute                :       'Attribute : ',
        }

        this.updateCommentFormInit            =        {
            updated_on                        :         new Date(),
            notes                             :         '',
        }

        this.state                           =     {
            
            minimizeTable              :       false,
            listing_loading            :       false,
            listing_tbl_page           :       1,
            resource_listing               :       [],
            listingMeta                :       null,
            totalListingCount          :       0,
            detail_loading             :       false,
            resourceDetail             :       null,
            addCommentForm             :       {...this.updateCommentFormInit},
            updateVersionFormSubmitting :      false,
            task_listing                :       [],
            task_loading                :       false,
            task_tbl_page               :       1,
            tasklistingMeta             :       null,
            totalTaskListingCount       :       0,
            version_listing             :       [],
            version_loading             :       false,
            version_tbl_page            :       1,
            totalVersionListingCount    :       0,
            versionlistingMeta          :       null,
            permissions                 :       [],
            resourceSearchForm             :       {...this.initSearchForm},
            submittedResourceFilterForm    :       {...this.initSearchForm},
            formSearchedElems          :       [],
            allTypeList                :        []
        };
       
    }

    componentDidMount() {
        this.initalizeComponent(this.props);
        this.getSearchFormData()
      this.loadListingTblData(1);
      this.updateVersionModal           =   new Modal(document.getElementById('updateVersionModal'), {keyboard: false, backdrop :false});
      this.searchModal                 =        new Modal(document.getElementById('searchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }

    getSearchFormData       =   ()        =>      {
        this.setState({form_loading: true});
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/add_resource_form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                allTypeList             : respData && respData.types && respData.types.length > 0 ? respData.types.map(tp => {return({value:tp.id,label:tp.name})}) : [],
                
            })
           
        }).then(() => this.setState({form_loading: false}));
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50,...this.state.submittedResourceFilterForm};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                resource_listing    :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    addResourceFormInit         =       ()      =>      {
        this.props.history.push('/add_resource')
    }

    viewDetailInit      =       (id)        =>      {
        this.setState({
            minimizeTable : true
        })
       this.getResourceDetail(id);
       this.getLinkedTaskList(1,id)
       this.getVersionHistoryListing(1,id)
    }

    getResourceDetail                   =      (id)    =>       {
        this.setState({detail_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                resourceDetail         :       respData.data,
            })
        }).then(() => this.setState({detail_loading: false}));
    }

    getLinkedTaskList                 =   (page = 1,resource_id='')  =>         {
        this.setState({task_loading: true, task_tbl_page: page});
        let params                      =   {page:page,per_page:50,search_resource_id : resource_id};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/task', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                task_listing      :       respData.data,
                tasklistingMeta         :       respData.meta,
                totalTaskListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({task_loading: false}));
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    editResourceInit        =       ()      =>      {
        this.props.history.push({ pathname: `/add_resource`, state: { editResourceData : this.state.resourceDetail } });
    }

    deleteResourceInit      =       ()        =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
            
                HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource/' + this.state?.resourceDetail?.id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.setState({minimizeTable : false});
                    this.loadListingTblData(1);
                }).then(() => { });
            }
        });
    }

    updateVersionInit       =       ()      =>      {
        this.updateVersionModal.show();
        this.setState({ addCommentForm: { ...this.updateCommentFormInit } });
    }

    submitUpdateVersionForm         =       (e)      =>      {
        e.preventDefault();
        this.setState({
            updateVersionFormSubmitting : true
        })
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource_version_change', this.props.access_token, {}, { ...this.state.addCommentForm,resource_id:this.state?.resourceDetail?.id }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateVersionModal.hide();
            this.setState({ addCommentForm: { ...this.updateCommentFormInit } });
            this.getVersionHistoryListing(1,this.state?.resourceDetail?.id);
            this.getLinkedTaskList(1,this.state?.resourceDetail?.id)
            // this.loadListingTblData(1);
        }).then(() => this.setState({ updateVersionFormSubmitting: false }));
    }

    getVersionHistoryListing                 =   (page = 1,id)  =>         {
        this.setState({version_loading: true, version_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource_version/' + id , this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                version_listing             :       respData.data,
                versionlistingMeta         :       {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                    
                },
                totalVersionListingCount   :       respData.total,
        });
        }).then(() => this.setState({version_loading: false}));
    }

    submitSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.resourceSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.searchFormLabel[key];
            let value                       =   this.state.resourceSearchForm[key];
           
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                let show_val            =   value;
                if(key == 'search_name') {
                    show_val            =   this.state.resourceSearchForm ? this.state.resourceSearchForm.search_name : '';
                }
                if(key == 'search_attribute') {
                    show_val            =   this.state.resourceSearchForm ? this.state.resourceSearchForm.search_attribute : '';
                }
                if(key == 'search_resource_type_ids') {
                    show_val            =  this.state.allTypeList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                
                searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedResourceFilterForm       :   {...this.state.resourceSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.searchModal.hide()
            this.loadListingTblData(1);
        });
    }

    resourceSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems           :       [],
            resourceSearchForm                :       {...this.initSearchForm},
            submittedResourceFilterForm       :       {...this.initSearchForm},
        }, () => {
            this.loadListingTblData(1);
            this.searchModal.hide();
        });
    }

    searchModalInit     =       ()      =>      {
        this.searchModal.show();
    }
   
    resourceTrackerJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Resource Tracker List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.state.permissions.includes('resource_add') && <button type="button" className="btn btn-primary" onClick={() => this.addResourceFormInit()}>New</button>}
                           <button type="button" className="btn btn-secondary" onClick={this.searchModalInit} disabled={this.state.listing_loading} >
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Resource : {this.state?.resourceDetail?.name} </h3>
                            <div className="text-end mt15">
                            {this.state.permissions.includes('resource_edit') && <button type="button" className="btn btn-secondary" onClick={this.editResourceInit}>
                                   Edit
                                </button>}
                                {this.state.permissions.includes('resource_delete') &&  <button type="button" className="btn btn-secondary" onClick={() => this.deleteResourceInit()}>
                                   Delete
                                </button>}
                               
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.resourceListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.resourceDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    resourceListingJsx            =   ()   =>   {
        return (<Ax>
              <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.resourceSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        {this.state.minimizeTable ? <th scope="col" style={{ width: "15%" }}>Name</th>
                            : <Ax>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" style={{ width: "15%" }}>Type</th>
                                <th scope="col">Name</th>
                                <th scope="col" style={{ width: "40%" }}>Attribute</th>
                                <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                            </Ax>}

                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.resource_listing && this.state.resource_listing.length > 0
                            ? (this.state.resource_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {this.state.minimizeTable ? <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item.id)}>{item.name ? item.name : ""}</td>
                                        : <Ax>
                                            <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                            <td>{item.resource_type ? item.resource_type.name : "-"}</td>
                                            <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item.id)}>{item.name ? item.name : "-"}</td>
                                            <td>{item.path ? item.path : "-"}</td>
                                            <td className='text-center'>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" onClick={() => this.viewDetailInit(item.id)} role="button">View</a></li>
                                                </ul>
                                            </td>
                                        </Ax>}

                                </tr>)
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    resourceDetailJsx                  =       ()      =>      {
        let detail = this.state.resourceDetail ? this.state.resourceDetail : null
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                 
                </li>
                <li className="nav-item" role="presentation">
                  
                    <button className="nav-link" id="version-tab" data-bs-toggle="tab" data-bs-target="#version"
                        type="button" role="tab" aria-controls="version" aria-selected="true">Version
                    </button>

                </li>
                <li className="nav-item" role="presentation">
                  
                    <button className="nav-link" id="impact-tab" data-bs-toggle="tab" data-bs-target="#impact"
                        type="button" role="tab" aria-controls="impact" aria-selected="true">Impact
                    </button>

                </li>
               
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.detail_loading ? <Loader />
                        : <Ax>
                            <div className="tab_content_header">
                                <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody className='resource_table'>

                                        <tr>
                                            <td style={{ width: "25%" }}>Type</td>
                                            <th>{detail.resource_type ? detail.resource_type.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Name</td>
                                            <th>{detail.name ? detail.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Attribute</td>
                                            <th>{detail.path ? detail.path : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Description</td>
                                            <th>{detail.description ? renderHTML(detail.description) : "-"}</th>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </Ax>}
                </div>
                <div className="tab-pane" id="version" role="tabpanel" aria-labelledby="version-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper"> <span className="content_heading"> Version Status</span>
                            <div className='text-end'>
                                {this.state.permissions.includes('resource_version_update') && <button type="button" className="btn btn-secondary" onClick={() => this.updateVersionInit()}>
                                    Add
                                </button>}
                            </div>
                        </div>
                        <hr />
                            <div className="my-1">
                            {this.state.version_loading ? <div><Loader/></div>
                                : this.state.version_listing && this.state.version_listing.length > 0 
                                ? this.state.version_listing.map(((vs,key) => {
                                    return(<Ax>
                                        <div className="row" key={key}>
                                    <div className="col-sm-8" >{vs.notes ? vs.notes : "-"} </div>
                                    <div className="col-sm-4 text-end">
                                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" /> */}
                                        <span>{vs.updated_user} on {vs.created_at_display}</span>
                                    </div>
                                
                                </div><hr />
                                    </Ax>)
                                }))
                                : <div class="alert alert-secondary text-center align-middle my-2" role="alert">
                                No Version Added
                            </div>}
                            </div>
                        
                    </div>
                </div>
                <div className="tab-pane" id="impact" role="tabpanel" aria-labelledby="impact-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper"> <span className="content_heading"> Impact</span>
                            
                        </div>
                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                            <thead className="table-secondary">
                                <tr className='text-center'>
                                    <th style={{ width: "10%" }}>S.No</th>
                                    <th>Task Name</th>
                                    <th style={{ width: "15%" }}>Task Status</th>
                                    <th style={{ width: "15%" }}>Impact Status</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.task_loading ? <tr className='text-center'>
                                    <td colSpan={3}><Loader /></td>
                                </tr> : this.state.task_listing && this.state.task_listing.length > 0
                                    ? this.state.task_listing.map((ts, key) => {
                                        return (<tr key={key}>
                                            <td className='text-center'>{this.state.tasklistingMeta ? this.state.tasklistingMeta.from + key : key}</td>
                                            <td>{ts.name ? ts.name : "-"}</td>
                                            <td className='text-center'>{ts && ts.status ? <Status color={ts.status.status_color_code}>{ts.status.status_text}</Status> : ""}</td>
                                            <td className='text-center'>{ts && ts.impact_status ? <Status color={ts.impact_status.status_color_code}>{ts.impact_status.status_text}</Status> : ""}</td>
                                        </tr>)
                                    })
                                    : <tr className='text-center'>
                                        <td colSpan={3}>No Record</td>
                                    </tr>}

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </section>);    
    }

    updateVersionStatusJsx               =        ()             =>        {
        
        return (
            <div className="modal fade" id="updateVersionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Add Version </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitUpdateVersionForm}>
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Date</label>
                                    </div>
                                    <div className="col-sm-9 add_calender_section">
                                        <DatePicker
                                            selected={
                                                this.state.addCommentForm.updated_on
                                                    ? moment(this.state.addCommentForm.updated_on).toDate()
                                                    : false
                                            }
                                            name="updated_on"
                                            onChange={(value, event) => { this.formDateHandler('updated_on', value, 'addCommentForm', 'YYYY-MM-DD hh:mm:ss') }}
                                            dateFormat="dd-MMM-yyyy hh:mm a"
                                            className={"form-control"}
                                            showMonthDropdown
                                            showYearDropdown
                                            showTimeSelect
                                            timeIntervals={5}
                                            timeCaption="Time"
                                            autoComplete="off"
                                            scrollMonthYearDropdown
                                            required={true}
                                            placeholderText={`Please Enter Date of Version`}
                                            disabled={true}
                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Description</label>
                                    </div>
                                    <div className="col-sm-9 add_calender_section">
                                    <textarea
                                                name="notes"
                                                value={this.state.addCommentForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                className="form-control"
                                                style={{ height: "100px" }}
                                                required={true}
                                            />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.updateVersionFormSubmitting}
                                >Close  {this.state.updateVersionFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.updateVersionFormSubmitting}>Save  {this.state.updateVersionFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

   searchModalJsx               =        ()             =>        {
    return (
        <div className="modal fade" id="searchModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Resource Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form id="resourceSearchForm" onSubmit={this.submitSearchForm}>
                        <div className="modal-body">
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm  ">Resource Type</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.allTypeList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'resourceSearchForm', 'search_resource_type_ids')}
                                        value={this.state.allTypeList.filter(u => this.state.resourceSearchForm?.search_resource_type_ids?.includes(u.value))}
                                        isSearchable={true}
                                        isMulti={true}
                                        isClearable={true}
                                        placeholder="Select Resource Type"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>

                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm  ">Name</label>
                                <div className="col-sm-10">
                                    <input
                                        name="search_name"
                                        type="text"
                                        value={this.state.resourceSearchForm.search_name}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "resourceSearchForm")}
                                        placeholder="Please enter Resource Name"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>

                            </div>
                            <div className="row my-3 align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm  ">Attribute</label>
                                <div className="col-sm-10">
                                    <input
                                        name="search_attribute"
                                        type="text"
                                        value={this.state.resourceSearchForm.search_attribute}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "resourceSearchForm")}
                                        placeholder="Please enter Attribute"
                                        style={{ height: "30px", fontSize: "93%" }}
                                    />
                                </div>

                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}


    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Resource Tracker Listing</title></Helmet>
            {this.resourceTrackerJsx()}
            {this.updateVersionStatusJsx()}
            {this.searchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ResourceTrackerList);