import login from '../pages/auth/Login';
import logOut from '../pages/auth/LogOut';
import dashboard from '../pages/dashboard/Dashboard';
import ResourceTrackerAdd from '../pages/development/resourceTracker/ResourceTrackerAdd';
import ResourceTrackerList from '../pages/development/resourceTracker/ResourceTrackerList';
import TaskTrackerAdd from '../pages/development/taskTracker/TaskTrackerAdd';
import TaskTrackerList from '../pages/development/taskTracker/TaskTrackerList';
import GroupList from '../pages/group/GroupList';
import ModuleList from '../pages/modules/ModuleList';
import PermissionList from '../pages/permission/PermissionList';
import subModulesList from '../pages/subModules/subModulesList';
import RoleListing from '../pages/roles/RoleListing';
import AddRole from '../pages/roles/addRole/AddRole'
import UserListing from '../pages/users/UserListing';
import LearningList from '../pages/development/learnings/LearningList';
import TrainingList from '../pages/development/trainings/TrainingList';
import TopicsList from '../pages/development/topics/TopicsList';
import ReleasesList from '../pages/development/releases/ReleasesList';
import ReleaseAdd from '../pages/development/releases/ReleaseAdd';
import TutorialList from '../pages/development/tutorial/TutorialList';
import TutorialAdd from '../pages/development/tutorial/TutorialAdd';
import SectionsList from '../pages/development/sections/SectionsList';
import SectionAdd from '../pages/development/sections/SectionAdd';
import FeedbackListing from '../pages/support/feedback/FeedbackListing';
import MomListing from '../pages/support/mom/MomListing';
import MomAdd from '../pages/support/mom/MomAdd';
import IOTServiceProvider from '../pages/iotServiceProvider/IOTServiceProvider';


export default [
    { path: '/dashboard', exact: true, name: "Dashboard", component: dashboard },
    { path: '/module_list', exact: true, name: "Module List", component: ModuleList },
    {path : '/sub_module_list', exact: true, name: "Sub Module", component: subModulesList},
    {path : '/permission_list', exact: true, name: "Permission", component: PermissionList},
    {path : '/group_list', exact: true, name: "Group List", component: GroupList},
    {path : '/task_tracker_list', exact: true, name: "Task Tracker", component: TaskTrackerList},
    {path : '/add_task', exact: true, name: "Task Tracker Add", component: TaskTrackerAdd},
    {path : '/resource_list', exact: true, name: "Resource List", component: ResourceTrackerList},
    {path : '/add_resource', exact: true, name: "Resource Add", component: ResourceTrackerAdd},
    {path : '/roles', exact: true, name: "Role List", component: RoleListing},
    {path : '/add_role', exact: true, name: "Role Add", component: AddRole},
    {path : '/user_listing', exact: true, name: "User Listing", component: UserListing},
    {path : '/learning_list', exact: true, name: "Learning Listing", component: LearningList},
    {path : '/training_list', exact: true, name: "Training Listing", component: TrainingList},
    {path : '/topics_list', exact: true, name: "Topics", component: TopicsList},
    {path : '/releases_list', exact: true, name: "Topics", component: ReleasesList},
    {path : '/release_add', exact: true, name: "Release Add", component: ReleaseAdd},
    {path : '/tutorial_list', exact: true, name: "Tutorial List", component: TutorialList},
    {path : '/tutorial_add', exact: true, name: "Tutorial Add", component: TutorialAdd},
    {path : '/section_list', exact: true, name: "Section List", component: SectionsList},
    {path : '/section_add', exact: true, name: "Section List", component: SectionAdd},
    {path : '/feedback_list', exact: true, name: "Feedback List", component: FeedbackListing},
    {path : '/meeting_list', exact: true, name: "M.O.M List", component: MomListing},
    {path : '/meeting_add', exact: true, name: "M.O.M Add", component: MomAdd},
    {path : '/iot_service_provider', exact: true, name: "Header", component: IOTServiceProvider},
    // {path : '/header', exact: true, name: "Header", component: HeaderList},
    {path : '/', exact: true, name: "Login", component: login},
    {path : '/logout', exact: true, name: "Logout", component: logOut},
]