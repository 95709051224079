import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import renderHTML from 'react-render-html'


class LearningList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initAddLearningForm              =       {
            name                :       '',
            topic              :       '',
            notes         :       ''
        }
        
        this.state                           =     {
            
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            learning_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            addLearningForm                       :       {...this.initAddLearningForm},
            editLearning                          :       false,
            addLearningFormSubmitting             :       false,
            agree                               :      'N',
            permissions                         :       [],
            form_loading                        :       false,
            allTopicsList                       :       [],
            minimizeTable                       :       false,
            learningDetail                      :       null,
            detail_loading                      :       false,
        };
       
    }

    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
        this.loadListingTblData(1);
        this.addLearningModal                  =   new Modal(document.getElementById('addLearningModal'), {keyboard: false, backdrop :false});
      
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/learnings', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                learning_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

   
    addLearningFormInit       =       (data)      =>      {
        if(this.props.permissions.includes('learning-add') || this.props.permissions.includes('learning-edit')){
            this.addLearningModal.show();
            if(!this.state.formLoaded){
                this.getAddFormData();
            }
            if(data){
                this.setState({
                    addLearningForm                       :       {
                        title            :       data.title,
                        id              :       data.id,
                        topic           :       data.topic,
                        notes           :       data.notes,
                    },
                    editLearning          :       true,
                    agree               :       'N',
                })
            }else{
                this.setState({
                    addLearningForm      :       {...this.initAddLearningForm},
                    editLearning         :       false,
                    agree              :       'N',
                })
            }
        }else{
            toast.error('You dont have permission to perform this action')
        }
    }

    getAddFormData          =       ()      =>      {
        this.setState({form_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/learning/form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({ 
                allTopicsList             :       respData.topics && respData.topics.length > 0 ? respData.topics.map(md => {return({value:md.key,label:md.name})}) :[],
                formDataLoaded      :       true
            });
           
        }).then(() => this.setState({form_loading: false}));
    }

    submitAddLearningForm     =       (e)     =>      {
        e.preventDefault();
        this.setState({ addLearningFormSubmitting: true });
       
        if (this.state.editLearning) {
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/learning', this.props.access_token, {}, { ...this.state.addLearningForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addLearningModal.hide();
                this.setState({ addLearningForm: { ...this.initAddLearningForm } });
                this.loadListingTblData(this.state.listing_tbl_page);
            }).then(() => this.setState({ addLearningFormSubmitting: false }));
        } else {
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/learning', this.props.access_token, {}, { ...this.state.addLearningForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addLearningModal.hide();
                this.setState({ addLearningForm: { ...this.initAddLearningForm } });
                this.loadListingTblData(1);
            }).then(() => this.setState({ addLearningFormSubmitting: false }));
        }
        
    }

    viewDetailInit          =       (data)      =>  {
        if(this.props.permissions.includes('learning-view')){
            this.setState({detail_loading:true,learningDetail : data,minimizeTable:true})
            setTimeout(() => {
                this.setState({detail_loading:false})
            }, 2000);
        }else{
            toast.error('You dont have permission to perform this action')
        }
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    learningJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Learning List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.props.permissions.includes('learning-add') && <button type="button" className="btn btn-primary" onClick={() => this.addLearningFormInit()}>New</button>}

                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Learning : {this.state.learningDetail ? this.state.learningDetail.title : "-"} </h3>
                            <div className="text-end mt15">
                            <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                            </button>
                            </div>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.learningLisitngJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.learningDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    learningLisitngJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                    {this.state.minimizeTable ? <th scope="col" style={{ width: "15%" }}>Title</th>
                            : <Ax>
                                 <th scope="col" style={{ width: "10%" }}>S.No</th>
                        <th scope="col" style={{ width: "20%" }}>Title</th>
                        <th scope="col">Topic</th>
                        <th scope="col" style={{ width: "20%" }}>Added By</th>
                        <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                            </Ax>}
                       
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.learning_listing && this.state.learning_listing.length > 0
                            ? (this.state.learning_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {this.state.minimizeTable ? <td className='link-primary cursor_pointer' onClick={() => this.viewDetailInit(item)}>{item.title ? item.title : ""}</td>
                                        : <Ax>
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewDetailInit(item)}>{item.title ? item.title : ""}</td>
                                    <td>{item.topic_name ? item.topic_name : ""}</td>
                                    <td>{item.user_detail ? item.user_detail.name : ""}</td>
                                    <td className='text-center'>
                                      <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" onClick={() => this.viewDetailInit(item)} role="button">View Detail</a></li>
                                            <li><a className="dropdown-item" onClick={() => this.addLearningFormInit(item)} role="button">Edit</a></li>
                                        </ul>
                                    </td>
                                    </Ax>}
                                </tr>)
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    learningDetailJsx                  =       ()      =>      {
        let detail = this.state.learningDetail ? this.state.learningDetail : null
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                 
                </li>
               
               
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.detail_loading ? <Loader />
                        : <Ax>
                            <div className="tab_content_header">
                                <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody className='resource_table'>

                                        <tr>
                                            <td style={{ width: "25%" }}>Title</td>
                                            <th>{detail.title ? detail.title : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Topic</td>
                                            <th>{detail.topic_name ? detail.topic_name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Added By</td>
                                            <th>{detail.user_detail ? detail.user_detail.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Description</td>
                                            <th>{detail.notes ? renderHTML(detail.notes) : "-"}</th>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </Ax>}
                </div>
               
            </div>
        </section>);    
    }

    addLearningModalJsx                        =   ()  =>  {
        return (
            <div className="modal fade" id="addLearningModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addLearningModalLabel">{this.state.editLearning ? "Edit" : "Add"} Learning</h5>
                        </div>
                        <form onSubmit={this.submitAddLearningForm} id="addTermsForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Title</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="title"
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => { this.formInputHandler(e, 'addLearningForm') }}
                                            value={this.state.addLearningForm.title}
                                            required
                                            placeholder="Enter Learning Title"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Topic</label>
                                    </div>
                                    <div className="col-sm-9">
                                    <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addLearningForm', 'topic');
                                            }}
                                            options={this.state.allTopicsList}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            value={this.state.allTopicsList.find(s => s.value == this.state.addLearningForm.topic)}
                                            placeholder="Select Topics"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Notes</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <CKEditor
                                            data={this.state.addLearningForm.notes}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                // this.state.notes = editor.getData();
                                                this.setState({ addLearningForm: { ...this.state.addLearningForm, notes: editor.getData() } })
                                            }}

                                        />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="modal-footer d-flex justify-content-between  row">
                                <div className="col text-start">

                                    <input
                                        name="agree"
                                        type="checkbox"
                                        className="form-check-input"
                                        id="agree"
                                        required
                                        value={this.state.agree}
                                        onChange={(e) => {
                                            this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                        }}
                                        checked={this.state.agree == 'Y'}
                                    />
                                    <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                                </div>
                                <div class="col text-end">
                                    <button type="button"
                                        disabled={this.state.addLearningFormSubmitting}
                                        className="btn btn-secondary mx-2"
                                        data-bs-dismiss="modal"

                                    >Close</button>
                                    <button type="submit" disabled={this.state.addLearningFormSubmitting} className="btn btn-primary">
                                        Submit {this.state.addLearningFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Learning Listing</title></Helmet>
            {this.learningJsx()}
           {this.addLearningModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(LearningList);