import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import boRoutes from '../routes/boRoutes';
import publicRoutes from '../routes/publicRoutes';
import PageNotFound from '../pages/PageNotFound';
import AppInitializeScreen from '../pages/AppInitializeScreen/AppInitializeScreen';
import Ax from '../components/hoc/Ax';
import Login from '../pages/auth/Login';
import LogOut from '../pages/auth/LogOut';
import MaintenanceMode from './MaintenanceMode';

class App extends React.Component {
    
 
    
    constructor(props) {
        super(props);
    }
    
    componentDidMount               =   ()  =>  {
        if(process.env.REACT_APP_ENV == 'production') {
          
        }
       
        this.props.onTryAutoSignIn();
    }
    
    render                          =   ()  =>  {
        let tbRoutes                    =   publicRoutes;
        if(this.props.isAppInitialized && this.props.isAuthenticated) {
            tbRoutes                    =   tbRoutes.concat(boRoutes);
        }
        if(process.env.REACT_APP_ENV == 'production' && this.props.addData.firebase_maintenance_mode === 'Y') {
            return <MaintenanceMode />;
        } else if (this.props.isAuthenticated && !this.props.isAppInitialized) {
            return <AppInitializeScreen />;
        } else {
            return <Router>
                <Switch>
                    <Route path={'/logout'} exact={true} component={LogOut} />
                    { tbRoutes.map((prop, key) => <Route path={prop.path} exact={prop.exact} key={key} component={prop.component} />) }
                    <Route path='*' component={this.props.isAuthenticated ? PageNotFound : Login} />
                </Switch>
            </Router>
        }
    }
}

const mapStateToProps = state => {
    return {
        loading             :   state.auth.loading,
        error               :   state.auth.error,
        isAuthenticated     :   state.auth.access_token !== null,
        isAppInitialized    :   state.auth.access_token !== null ? true : false,
        addData             :   state.app ? state.app : null,
        permissions         :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    }
}

const mapDispatchToProps        =   dispatch    =>  {
    return {
        onTryAutoSignIn                 : () => dispatch(actions.authCheckState()),
  
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);