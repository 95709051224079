import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';




class ResourceTrackerAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddResourceForm           =        {
            name                :           '',
            path                :           '',
            type                :           '',
            description         :           '',
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addResourceForm            :       {...this.initAddResourceForm},
            form_loading               :        false,
            editResource               :        false,
            allTypeList                :        []
        };
    }

    componentDidMount() {
        this.getAddFormData()
    }



    getAddFormData       =   ()        =>      {
        this.setState({form_loading: true});
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/add_resource_form_data', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                allTypeList             : respData && respData.types && respData.types.length > 0 ? respData.types.map(tp => {return({value:tp.id,label:tp.name})}) : [],
                
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.editResourceData) {
                    let detail = this.props.location.state.editResourceData;
                    this.setState({
                       addResourceForm : {
                            ...this.initAddResourceForm,
                            id                  :           detail.id,
                            name                :           detail.name,
                            path                :           detail.path,
                            description         :           detail.description ? detail.description : "",
                            type                :           detail.resource_type ? detail.resource_type.id : ""
                       },
                       editResource             :   true
                    })
                }
            })
           
        }).then(() => this.setState({form_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/resource_list` })

    }

    submitAddResourceForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addResourceForm,
         }
        if (this.state.editResource) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addResourceForm: { ...this.initAddResourceForm } })
                this.props.history.push({ pathname: `/resource_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/resource', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addResourceForm: { ...this.initAddResourceForm } })
                this.props.history.push({ pathname: `/resource_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
        
    }


    saveResourceFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddResourceForm} id="saveResourceForm">
            {this.state.form_loading ? <Loader />
                : <Ax>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Type</label>
                        </div>
                        <div className="col-sm-4 ">
                            <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addResourceForm', 'type')}
                                options={this.state.allTypeList}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.allTypeList.find(s => this.state.addResourceForm.type == s.value)}
                                placeholder={"Please Select Type"}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label require">Name</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="name"
                                type="text"
                                value={this.state.addResourceForm.name}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addResourceForm")}
                                placeholder="Please enter Name"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Attribute</label>
                        </div>
                        <div className="col-sm-4 ">
                            <input
                                name="path"
                                type="text"
                                value={this.state.addResourceForm.path}
                                className="form-control"
                                autoComplete="off"
                                required={true}
                                onChange={(e) => this.formInputHandler(e, "addResourceForm")}
                                placeholder="Please enter Attribute"
                            />
                        </div>
                    </div>
                   <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="first_name" className="form-label">Description</label>
                        </div>
                        <div className="col-sm-4 ">
                            <CKEditor
                             data ={this.state.addResourceForm.description}
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                    // this.state.description = editor.getData();
                                    this.setState({ addResourceForm: { ...this.state.addResourceForm, description: editor.getData() } })
                                }}

                            />
                        </div>
                    </div>
                 
                </Ax>}


            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Resource Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editResource ? "Edit" : "Add"} Resource</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    {this.saveResourceFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ResourceTrackerAdd);