import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';


class TopicsList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initAddTopicsForm              =       {
            name                :       '',
            detail              :       ''
        }
        
        this.state                           =     {
            
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            topics_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            form_loading                        :       false,
            allRolesList                        :       [],
            addTopicForm                        :       {...this.initAddTopicsForm}
        };
       
    }

    componentDidMount() {
      this.loadListingTblData(1);
      this.addTopicModal             =        new Modal(document.getElementById('addTopicModal'), {keyboard: false, backdrop :false});  
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/training/topic', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                topics_listing      :       respData.data,
                listingMeta         :       {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                    
                },
                totalListingCount   :       respData.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    addTopicsFormInit     =     (data=null)        =>      {
        if(this.props.permissions.includes('topic-manage')){
            this.addTopicModal.show();
            if(data){
                this.setState({
                    addTopicForm  :  {
                        ...this.initAddTopicsForm,
                        id      :       data.id,
                        name    :       data.name,
                        detail  :       data.detail
                    }
                })
            }else{
                this.setState({
                    addTopicForm        :       {...this.initAddTopicsForm}
                })
            }
        }else{
            toast.error('You dont have permission to perform this action')
        }
    }

    submitAddTopicForm        =       (e)      =>      {
        e.preventDefault();
        if (this.state.addTopicForm && this.state.addTopicForm.id) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/training/topic', this.props.access_token, {}, { ...this.state.addTopicForm }, (resp) => {
                toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
                this.addTopicModal.hide();
                this.loadListingTblData(1);

            }).then(() => this.setState({ saveFormSubmitting: false }))
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/training/topic', this.props.access_token, {}, { ...this.state.addTopicForm}, (resp) => {
                toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
                this.addTopicModal.hide();
                this.loadListingTblData(1);

            }).then(() => this.setState({ saveFormSubmitting: false }))
        }
        
    }
   
   
    topicsJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Topics List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.props.permissions.includes('topic-manage') && <button type="button" className="btn btn-primary" onClick={() => this.addTopicsFormInit()}>New</button>}
                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Topics :  </h3>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.topicsLisitngJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>test</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    topicsLisitngJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                        <th scope="col" style={{ width: "30%" }}>Topics</th>
                        <th scope="col">Detail</th>
                        <th scope="col" style={{ width: "12%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                   
                    {this.state.listing_loading
                        ? (<tr><td colSpan="3"><Loader /></td></tr>)
                        : (this.state.topics_listing && this.state.topics_listing.length > 0
                            ? (this.state.topics_listing.map((item, index) => {
                                return (<tr key={index} >
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{item.name ? item.name : "-"}</td>
                                    <td>{item.detail ? item.detail : "-"}</td>
                                    <td className="text-center">
                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.addTopicsFormInit(item)}>Edit</a></li>
                                        </ul>
                                    </td>
                                </tr>)
                            }))
                            : (<tr><td colSpan="3" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    addTopicModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addTopicModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.addTopicForm && this.state.addTopicForm.id ? "Edit" : "Add"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <form onSubmit={this.submitAddTopicForm} id="addTopicModalForm">
                            <div className="modal-body">

                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-3"><label className="form-label require">Name</label></div>
                                    <div className="col-sm-8">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addTopicForm.name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            required={true}
                                            autoFocus={true}
                                            onChange={(e) => this.formInputHandler(e, "addTopicForm")}
                                            placeholder="Please enter Name "
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-3"><label className="form-label">Detail</label></div>
                                    <div className="col-sm-8">
                                        <textarea
                                            name="detail"
                                            value={this.state.addTopicForm.detail}
                                            onChange={(e) => this.formInputHandler(e, 'addTopicForm')}
                                            className="form-control"
                                            style={{ height: "100px" }}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Topics Listing</title></Helmet>
            {this.topicsJsx()}
            {this.addTopicModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(TopicsList);