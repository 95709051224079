import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ax from '../../components/hoc/Ax';
import loginStyle from './Login.module.css';
import TappetLogo from '../../assets/img/tappetbox.png';
import MobileLogo from '../../assets/img/tappet-mobile-logo.jpg';
import whiteLogo from '../../assets/img/logo-white.png';
import AlertErrorMsg from '../../components/ui/alert/AlertErrorMsg';
import * as actions from '../../store/actions/index';

class LogOut extends React.Component {

    constructor() {
        super();
    }

    componentDidMount() {
        this.props.clearAppData();
        this.props.onLogout();
    }

    render() {
        return <Redirect to="/" />
    }

}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token_type: state.auth.token_type,
        isAuthenticated: state.auth.access_token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        clearAppData : () => dispatch(actions.clearAppData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogOut);