import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../components/ui/TapSelect';
import AppBaseComponent from '../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';


class UserList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initAdduserForm              =       {
            name                :       '',
            user              :       ''
        }
        
        this.state                           =     {
            
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            user_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            form_loading                        :       false,
            allRolesList                        :       [],
            
            // adduserForm                       :       {...this.initAdduserForm},
            // edituser                          :       false,
            // adduserFormSubmitting             :       false,
            // agree                               :      'N'
        };
       
    }

    componentDidMount() {
      this.loadListingTblData(1);
      this.updateRoleModal             =        new Modal(document.getElementById('updateRoleModal'), {keyboard: false, backdrop :false});
      
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/users', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                user_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    updateRoleModalInit     =     (data=null)        =>      {
        this.updateRoleModal.show();
        if (!this.state.formDataLoaded) {
            this.getUpdateRoleFormData()
        }
      

    }

    getUpdateRoleFormData       =   (e)      =>      {
        this.setState({form_loading : true})
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/role_form_data', this.props.access_token, {}, {}, (resp) => {
           let respData = resp.data
           this.setState({
                formDataLoaded : true,
                allRolesList   :  respData && respData.roles && respData.roles.length > 0 ? respData.roles.map(rl => {return({value:rl.id,label:rl.name})}): [] 
            })
          }).then(() => this.setState({form_loading : false}))
    }

    submitUpdateRoleForm        =       (e)      =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/bo_acl/user', this.props.access_token, {}, { ...this.state.updateRole }, (resp) => {
            toast.success(resp.data?.msg, { position: toast.POSITION.TOP_CENTER })
            this.updateRoleModal.hide()
            this.loadListingTblData(1)

        }).then(() => this.setState({ saveFormSubmitting: false }))
        
    }
   
   
    userJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Operations Team List</h3>
                {/* <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                            <button type="button" className="btn btn-primary" onClick={() => this.adduserFormInit()}>New</button>

                        </Ax>
                        : null}


                </div> */}
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>user :  </h3>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.userLisitngJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>test</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    userLisitngJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                        <th scope="col">Name</th>
                        <th scope="col" style={{ width: "35%" }}>Role</th>
                        <th scope="col" style={{ width: "15%" }}>Mobile</th>
                    </tr>
                </thead>
                <tbody>
                   
                    {this.state.listing_loading
                        ? (<tr><td colSpan="4"><Loader /></td></tr>)
                        : (this.state.user_listing && this.state.user_listing.length > 0
                            ? (this.state.user_listing.map((item, index) => {
                                return (<tr key={index} >
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td className='text-capitalize'>{item.name ? item.name : ""}</td>
                                    <td className='text-capitalize'>{item.role_data ? item.role_data.name : ""}</td>
                                    <td className='text-capitalize text-center'>{item.mobile ? item.mobile : ""}</td>
                                </tr>)
                            }))
                            : (<tr><td colSpan="4" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    updateRoleModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateRoleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Change Role</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Name   </td>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>Current Role </td><th></th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Status  </td>
                                        <th></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.submitUpdateRoleForm} id="updateRoleModalForm">
                            <div className="modal-body">
                                
                                <div  className="row align-items-center mb-3 mt-3">
                                    <div className="col-sm-3"><label className="form-label require">Change Role</label></div>
                                    <div className="col-sm-8">
                                        {/* <TapSelect
                                            options={this.state.allRolesList.filter(r => r.value != this.state.updateRole.parent_role)}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Reportee Role"
                                            required={true}
                                            value={this.state.allRolesList.find(rl => this.state.updateRole.parent_role == rl.value)}
                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'updateRole', 'parent_role') }}
                                        /> */}
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                            <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Operations Team Listing</title></Helmet>
            {this.userJsx()}
            {this.updateRoleModalJsx()}
          
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(UserList);