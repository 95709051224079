import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';
import moment from 'moment';
import DatePicker from "react-datepicker";


class ReleaseAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddReleaseForm           =        {
            platform            :           '',
            title               :           '',
            description         :           '',
            can_published       :           'Y',
            is_supported        :           'Y',
            app_version         :           '',
            upgrade_time        :           '',
            status              :           '1'
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addReleaseForm                :       {...this.initAddReleaseForm},
            editRelease                   :        false,
            allPlatformList             :       [{value:"web",label:"Web"},{value:"ios",label:"IOS"},{value:"android",label:"Android"}],
            allStatusList             :       [{value:"1",label:"Active"},{value:"0",label:"InActive"}]
        };
    }

    componentDidMount() {
       setTimeout(() => {
            if(this.props.location && this.props.location.state && this.props.location.state.editReleaseData) {
                let detail = this.props.location.state.editReleaseData;
                this.setState({
                addReleaseForm : {
                        ...this.initAddReleaseForm,
                        id                  :           detail.id,
                        title               :           detail.title,
                        can_published       :           detail.is_supported == 'Y' ? 'Y' : 'N',
                        app_version         :           detail.app_version ? detail.app_version :'',
                        build_version       :           detail.build_version ? detail.build_version :'',
                        description         :           detail.description ? detail.description : "1234",
                        platform            :           detail.platform,
                        upgrade_time        :           detail.upgrade_time ? detail.upgrade_time : "",
                        status              :           detail.status
                },
                editRelease             :   true
                })
            }
       },1000)
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/releases_list` })

    }

    submitAddReleaseForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addReleaseForm,
         }
        if (this.state.editRelease) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/release', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                //this.setState({ addReleaseForm: { ...this.initAddReleaseForm } })
                this.props.history.push({ pathname: `/releases_list`,state:{id:this.state.addReleaseForm.id} })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/release', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addReleaseForm: { ...this.initAddReleaseForm } })
                this.props.history.push({ pathname: `/releases_list` })
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
        
        
    }


    saveReleaseFormJsx         =        ()       =>      {
        console.log(this.state.addReleaseForm.description,'ttt')
        return (<form className="bg-white p-3" onSubmit={this.submitAddReleaseForm} id="saveReleaseForm">

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">Platform</label>
                </div>
                <div className="col-sm-7 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addReleaseForm', 'platform')}
                        options={this.state.allPlatformList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allPlatformList.find(s => this.state.addReleaseForm.platform == s.value)}
                        placeholder={"Please Select Platform"}
                        required={true}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">Title</label>
                </div>
                <div className="col-sm-7 ">
                    <input
                        name="title"
                        type="text"
                        value={this.state.addReleaseForm.title}
                        className="form-control"
                        autoComplete="off"
                        required={true}
                        onChange={(e) => this.formInputHandler(e, "addReleaseForm")}
                        placeholder="Please enter Title"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">Status</label>
                </div>
                <div className="col-sm-7 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addReleaseForm', 'status')}
                        options={this.state.allStatusList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allStatusList.find(s => this.state.addReleaseForm.status == s.value)}
                        placeholder={"Please Select Status"}
                        required={true}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">Can be Published</label>
                </div>
                <div className="col-sm-7">
                    <div className="form-check form-check-inline">
                        <input
                            name="can_published"
                            type="radio"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, 'addReleaseForm')}
                            checked={this.state.addReleaseForm.can_published === "N"}
                            className="form-check-input"
                            id="addReleaseFormN"
                        />
                        <label className="form-check-label" htmlFor="addReleaseFormN">No</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            name="can_published"
                            type="radio"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, 'addReleaseForm')}
                            checked={this.state.addReleaseForm.can_published === "Y"}
                            className="form-check-input"
                            id="addReleaseForm_Y"
                        />
                        <label className="form-check-label" htmlFor="addReleaseForm_Y">Yes</label>
                    </div>

                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">Is Supported</label>
                </div>
                <div className="col-sm-7">
                    <div className="form-check form-check-inline">
                        <input
                            name="is_supported"
                            type="radio"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, 'addReleaseForm')}
                            checked={this.state.addReleaseForm.is_supported === "N"}
                            className="form-check-input"
                            id="addReleaseFormNSupport"
                        />
                        <label className="form-check-label" htmlFor="addReleaseFormNSupport">No</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            name="is_supported"
                            type="radio"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, 'addReleaseForm')}
                            checked={this.state.addReleaseForm.is_supported === "Y"}
                            className="form-check-input"
                            id="addReleaseForm_YSupport"
                        />
                        <label className="form-check-label" htmlFor="addReleaseForm_YSupport">Yes</label>
                    </div>

                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">App Version</label>
                </div>
                <div className="col-sm-7 ">
                    <input
                        name="app_version"
                        type="text"
                        value={this.state.addReleaseForm.app_version}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addReleaseForm")}
                        placeholder="Please enter App Version"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">Build Version</label>
                </div>
                <div className="col-sm-7 ">
                    <input
                        name="build_version"
                        type="text"
                        value={this.state.addReleaseForm.build_version}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addReleaseForm")}
                        placeholder="Please enter Build Version"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">Upgrade Time</label>
                </div>
                <div className="col-sm-7 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addReleaseForm.upgrade_time
                                ? moment(this.state.addReleaseForm.upgrade_time).toDate()
                                : false
                        }
                        name="upgrade_time"
                        onChange={(value, event) => { this.formDateHandler('upgrade_time', value, 'addReleaseForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                        placeholderText={`Please Enter Upgrade Time`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>

            
        
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label">Description</label>
                </div>
                <div className="col-sm-7 ">
                    <CKEditor
                        data={this.state.addReleaseForm.description}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                           
                            this.setState({ addReleaseForm: { ...this.state.addReleaseForm, description: editor.getData() } })
                        }}

                    />
                   
                </div>
            </div>


            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
            <Helmet><title>Release Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editRelease ? "Edit" : "Add"} Release</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>

                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.saveReleaseFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ReleaseAdd);