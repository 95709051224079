import React from 'react';
import TapIcon from '../../services/TapIcon';
import whiteLogo from '../../assets/img/logo-white.png';
import Ax from '../../components/hoc/Ax';
import TappetLogo from '../../assets/img/tappetbox.png';
import { Helmet } from 'react-helmet';

class TermsAndConditions extends React.Component {

    constructor() {
        super();

        this.state                      =   {
            goto_top_visible                :   false,
          
        };
    }
  
    componentDidMount() {
        window.addEventListener('scroll', this.toggleVisibility);
    }

    scrollToTop                         =   ()  =>  window.scrollTo({ top: 0, behavior: "smooth" })
    toggleVisibility                    =   ()  =>  this.setState({ goto_top_visible: window.pageYOffset > 200 })
    
    topHeader                           =   ()  =>  {
        return (<nav className='nav_top'>
                    <div className={['container'].join(' ')} >
                        <div className="row">
                            <div className="col-md-7">
                                <a className={"social_icon"}  target="_blank" href="https://www.facebook.com/tappetbox/?ref=aymt_homepage_panel">
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faFacebookF} />
                                </a>
                                <a className={"social_icon"} target="_blank" href="https://twitter.com/tappetbox">
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faTwitter} />
                                </a>
                                <a className={"social_icon"} target="_blank" href="https://www.linkedin.com/company/tappetbox">
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faLinkedinIn} />
                                </a>
                                <a className={"social_icon"} target="_blank" href="https://www.youtube.com/channel/UCq0x42wA3zUr_CPgHH8TaYg">
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faYoutube} />
                                </a>
                            </div>
                            <div className="col-md-5 text-end">Call Us Today! 9650-222-555</div>
                        </div>
                    </div>
                </nav>);
    }

    headerLogo                          =   ()  =>  {
        return (<header className='header_wrapper'>
                    <div className={['container'].join(' ')} >
                        <div className="row">
                            <div className="col-md-12">
                                <img src={TappetLogo} height="60" alt="logo"/>
                            </div>
                        </div>
                    </div>
                </header>);
    }
    footer                              =   ()  =>  {
        return (
            <footer className={['container-fluid', 'mt-3', 'pb-3', 'text-white'].join(' ')}>
                <div className="row">
                    <div className="col-md-4">
                        <img src={whiteLogo} className="mt-4" alt="Tappetbox" />
                        <div className={['mx-2', 'mt-2', 'text-sm'].join(' ')}></div>
                    </div>
                    <div className="col-md-4">
                        <p className={['mt-4' , 'text-xl'].join(' ')}>Contact Us</p>
                        <div className={['mx-2', 'mt-2', 'text-sm', 'text-secondary'].join(' ')}>
                            <p> Plot No-4, Second Floor,<br/> Sheetla Complex, Prem Nagar - 2, Gurugram, <br/> Haryana - 122001.<br /></p>
                            <p className="mt-3 mb-2">Phone: 9650-222-555<br/>Email: contact@tappetbox.com </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <p className={['mt-4' , 'text-xl'].join(' ')}>Get Social</p>
                        <div className={['mx-2', 'mt-2', 'text-xl', 'text-secondary'].join(' ')}>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.facebook.com/tappetbox/?ref=aymt_homepage_panel">
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faFacebookF} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://twitter.com/tappetbox">
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faTwitter} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.linkedin.com/company/tappetbox">
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faLinkedinIn} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.youtube.com/channel/UCq0x42wA3zUr_CPgHH8TaYg">
                                <TapIcon.FontAwesomeIcon icon={TapIcon.faYoutube} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
    copywrite                           =   ()  =>  {
        return (
            <Ax>
                <div className="footer_copywrite text-center py-3 text-sm bg-dark text-white">Copyright © {new Date().getFullYear()}, Five Dots Solutions Pvt. Ltd. All Rights Reserved.</div>
                {this.state.goto_top_visible ? <div className="goto_top" onClick={this.scrollToTop}><span><TapIcon.FontAwesomeIcon icon={TapIcon.faAngleUp}/></span></div> : null }
            </Ax>
        );
    }

    softwarePolicyJsx       =       ()      =>      {
        return(<div className='bg_white'>
            <div >
                <div className='text-center p-2'>
                    <h2 class="text-center fw-normal">Software License Agreement &amp; Terms Of Use</h2>

                </div>
                <section className='container' style={{width:"60%"}}>
                    <div className='text-justify' style={{ fontSize: "13px", fontWeight: 350 }}>
                            <p>  The domain name www.tappetbox.in (hereinafter referred to as "Software") is owned by Five Dots Solutions Private Limited, a company incorporated under the provisions of the Companies Act, 2013, having its registered office at B-91, Second Floor, Sharda Puri, New Delhi - 110015, India  (hereinafter referred to as "Licensor").
                            </p>
                            <p><b>WHEREAS,</b> the Licensor is engaged in the business of designing and developing business-related software and related products and has created and developed a software package called <b>TappetBox®</b> which is a cloud based infrastructure asset management solution software to manage and control the infrastructure assets in an effective and efficient manner (hereinafter referred to as <b>“Software”</b>).</p>
                            <p>Your use of the Software and services and tools are governed by the following terms and conditions (hereinafter as <b>"Terms of Use"</b>) as applicable to the Website including the applicable policies which are incorporated herein by way of reference. If You use the software, You shall be subject to the policies that are applicable to the Software for such action. By mere use of the Software (Website), You shall be contracting with Five Dots Solutions Private Limited and these terms and conditions including the policies constitute your binding obligations, with Licensor.</p>
                            <p>For the purpose of these Terms of Use, wherever the context so requires <b>“Licensee”</b> or <b>"You"</b> or <b>"User"</b> shall mean any natural or legal person who has agreed to become a Licensee on the TappetBox Software by providing Registration Data while registering on the Software as Registered User using the computer systems. </p>
                            <p>When You use the “Software”, You will be subject to the rules, guidelines, policies, terms, and conditions applicable, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the Software following the posting of changes/updates shall mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Software</p>
                            <p>AND WHEREAS, the Licensee desires to utilise such Software in its business operations and intends to obtain license from the Licensor;</p>
                            <p>AND WHEREAS, both Licensor and Licensee believe it is in their mutual interest and desire to enter into an agreement whereby Licensee would use Licensor's Software pursuant to the terms and conditions hereinafter provided.</p>
                            <p><b> NOW, THEREFORE, in consideration of the premises and the mutual covenants of this Agreement, the parties hereto agree as follows:</b></p>
                            <ol>
                                <li>
                                    <h6>DEFINITIONS</h6>
                                    <p><b>“Documentation”</b> means the user guide to help information and/or other documentation generally provided by Licensor to licensees of the Software.</p>
                                    <p><b>“Sales Order”</b> means the Order made, agreed or executed amongst Licensor and Licensee towards use and access of the Software developed by the Licensor herein.</p>
                                    <p><b>“Software”</b> means <b>TappetBox®</b>, a cloud based infrastructure asset management solution software to manage and control the infrastructure assets in an effective and efficient manner and includes its separate modules, as modified and updated from time to time. (e.g., updates delivered pursuant to the support and maintenance services).</p>
                                    <p><b>“Subscription Fee”</b> means the annual fee set out in the Sales Order payable for the license to use the Software, which includes standard support and maintenance services in accordance with Licensor’s standard policy in effect at the commencement date of the Subscription Term (as such term is defined in the Sales Order).</p>
                                </li>
                                <li>
                                    <h6>LICENSE</h6>
                                    <p><b>2.1 Software.</b> Subject to all terms and conditions in this Agreement, the Licensor grants to Licensee a non-exclusive and non-transferable right and license to use the Software and the Documentation for its own internal business purposes for the Subscription Term specified in the Sales Order.</p>
                                    <p><b>2.2 Limitations.</b> The Software is licensed as a single product and Licensee shall not separate or use its component parts (including customised modules) beyond that which is expressly authorised in the Sales Order. In addition, the Licensee undertakes and agrees that its use of the Software shall comply with all other limitations, prohibitions and conditions set forth in the Sales Order (including without limitation, any restriction regarding: Software usage, such as the number of installed production servers or number of users; the time period during which use of the Software is permitted).</p>
                                    <p><b>2.3 License Control.</b> The Licensee acknowledges that the Software may require password or license keys to detect or prevent unauthorised use of, or disable, the Software. The Licensee undertakes to indemnify the Licensor against any unauthorised use or access by any third person without obtaining any Subscription Fee</p>
                                    <p><b>2.4 Support and Maintenance.</b> Subject to timely payment of all Subscription Fees and subject to the terms and conditions of this Agreement, the Licensee shall receive support and maintenance services in accordance with Licensor’s standard policy in effect at the commencement date of the Subscription Term.</p>
                                    <p><b>2.5 Additional  Services.</b>  Besides the implementation, support and maintenance, the Licensor will provide consulting services to the Licensee.  Any additional services shall be beyond the normal scope of Support and maintenance. The Licensor may charge the Licensee for any such additional services at Licensor’s then-current rates, or as otherwise negotiated between Licensee and Licensor. During the existence of this Agreement, if the Licensee requests for such additional services, the Licensor shall inform the Licensee that the services requested constitute additional services and provide a quote for such services to the Licensee.  If the Licensee desires to obtain those services from Licensor according to the prices identified in the quotation, the Licensee shall forward a Purchase Order to Licensor requesting such services. Upon its receipt of the Purchase Order, Licensor may or may not agree to provide the requested services. If Licensee desires additional services from Licensor then a Work Order shall be created describing services between Licensee and Licensor.</p>
                                </li>
                                <li>
                                    <h6> IMPLEMENTATION AND SERVICES</h6>
                                    <p><b>3.1 Licensor.</b> To the extent expressly specified in the Sales Order, Licensor shall (a) implement the Software (“Implementation Services”) and (b) perform the professional services (the “Professional Services”). Licensor may use third party subcontractors in providing the Implementation Services and Professional Services. Licensor shall be responsible for the performance of such subcontractors</p>
                                </li>
                                <li>
                                    <h6>PAYMENTS</h6>
                                    <p><b>4.1 Fees.</b> Licensee agrees to pay Licensor the Subscription Fees, any applicable Implementation Services Fees and Professional Services Fees (together, “Fees”) within the time prescribed as specified in the Sales Order.</p>
                                    <p><b>4.2 Payment Terms.</b> Unless specified otherwise, all Fees due hereunder shall be paid quarterly / half yearly / annually within a period of fifteen (15) days from the date of invoice raised. The first annual Subscription Fees shall be invoiced promptly after the Effective Date. Subsequent annual Subscription Fees will be invoiced at least 30 days prior to the anniversary of the Subscription Term, and are due in full no later than the anniversary of the Subscription Term. All payments shall be made to a Bank account specified by Licensor. Any amount not paid when due shall bear a late payment interest, until paid, at the rate of 18% per annum. Licensee shall also be responsible to reimburse Licensor for all costs (including attorneys’ fees) incurred in collecting late payments. Licensee undertakes and agree to reimburse the Licensor for all the costs and expenses payable incurred by Licensor towards collection of dues and outstanding payment in terms of this Agreement.</p>
                                    <p><b>4.3 Taxes.</b> All payments required by this Agreement are exclusive of any good and service tax, central, state, local taxes, duties, cess, levies etc. and Licensee agrees to bear and shall be responsible for the payment of all such charges at applicable rates from time to time</p>
                                    <p><b>4.4 Inspection Audit.</b> Subject to written notice of seven (7) days in advance to the Licensee, the Licensor shall have the right to conduct an inspection audit, at such frequent intervals, as it deem fit, of Licensee’s use of the Software to verify Licensee’s compliance with this Agreement. Licensee shall be bound to make its systems and all applicable books, records and transaction logs available for such inspection during normal business hours at Licensee’s principal place of business or at the any other place of accessibility of the Software. Any such audit shall be at sole expense and costs of Licensor only.</p>
                                </li>
                                <li>
                                    <h6>CONFIDENTIALITY</h6>
                                    <p><b>5.1 Scope.</b> The term Confidential Information means all trade secrets, know-how, inventions, developments, software and other financial, business or technical information of Licensor (or any of its licensors or customers) that is disclosed by or for Licensor or that is otherwise learned or accessed by Licensee, but not including any information that Licensee can demonstrate is (a) rightfully furnished to it without restriction by a third party without breach of any separate obligation to Licensor, (b) generally available to the public without breach of this Agreement or (c) independently developed by it without reliance on such information</p>
                                    <p><b>5.2 Confidentiality.</b> Except for the specific rights granted by this Agreement, Licensee shall not possess, use or disclose any Confidential Information of the Licensor without Licensor’s prior written consent, and shall use and apply reasonable care to protect such Confidential Information. Licensee may disclose Confidential Information of the Licensor to its employees and contractors ONLY ON NEED TO KNOW BASIS for the purposes of this Agreement and are  completely bound by confidentiality obligations that are at least as protective as the provisions herein. Licensee shall be responsible for any breach of confidentiality by its employees and contractors. The Licensee shall return all tangible Confidential Information, permanently erase all Confidential Information from any storage media and destroy all information, records and materials developed therefrom promptly after termination of this Agreement (or at Licensor’s request at any other time).</p>
                                    <p>The Licensee further acknowledges that disclosure of Confidential Information by any means would cause substantial harm for which damages alone would not be a sufficient remedy, and therefore that upon any such disclosure by the Licensee the Licensor will be entitled to appropriate equitable relief in addition to any other remedy in accordance with law</p>
                                </li>
                                <li>
                                    <h6>PROPRIETARY RIGHTS</h6>
                                    <p><b>6.1 Restrictions.</b> Except as specifically permitted in this Agreement, Licensee shall not directly or indirectly: (a) use any Confidential Information to create any software or documentation that is similar to any Software or Documentation; (b) disassemble, decompile, reverse engineer or use any other means to attempt to discover any source code or underlying ideas, algorithms or organization of the Software; (c) encumber, loan, lease, rent, sublicense, transfer or distribute any Software, or use the Software for the benefit of any third party; (d) copy, create derivative works of or otherwise modify any Software or Documentation; (e) use or allow the transfer, transmission, export or re-export of all or any part of the Software (or any product thereof) in violation of any export control laws or regulations of India; or (f) permit any third party to do any of the foregoing. Licensee will promptly notify Licensor in writing with regard to any unauthorised use, reproduction or distribution of any Software by their employees or any other person having the accessibility of the Software provided by them.</p>
                                    <p><b>6.2 No Implied Licenses</b>. Except for the limited rights and licenses expressly granted hereunder, no other license is granted (by implication, estoppel or otherwise), no other use is permitted and Licensor shall retain all rights, title and interests (including all patent rights, copyright rights, trade secret rights and all other intellectual property and proprietary rights) in and to the Software and Documentation. Licensee agrees not to take any action inconsistent with such ownership.</p>
                                </li>

                                <li>
                                    <h6>WARRANTY AND DISCLAIMERS</h6>
                                    <p><b>7.1 Software.</b> Licensor warrants to Licensee that the Software will operate substantially in conformance with the Documentation during the Subscription Term after the Effective Date. Any warranty claim under this Section 7 shall be applicable for a period of three months from the date of the Sales order. The warranty claim by the Licensee shall be made in writing to the Licensor. Licensor’s sole obligation and Licensee’s exclusive remedy in respect thereof is to use reasonable efforts to repair or replace the Software that Licensor determines, in its reasonable judgment, is non-conforming or, at Licensor’s sole discretion, to accept return of the non-conforming Software and upon receipt thereof, Licensor shall refund to Licensee the amount that Licensee paid thereof</p>
                                    <p><b>7.2 Exclusions.</b> The foregoing warranty shall apply only to the licensed Software and shall not apply to any non-conformity resulting from any hardware, operating system or other software to the Software that was licensed under this Agreement or that was (a) used in violation of this Agreement, (b) used, handled, operated, maintained or stored improperly, or in any manner not in accord with the Documentation, industry standard practice or Licensor’s instructions or recommendations, or (c) combined, altered, modified or repaired other than by or for Licensor.</p>
                                    <p><b>7.3 Disclaimers.</b> Except as expressly specified herein, the software, documentation and support services are provided “AS IS” without warranty of any kind. Licensor does not warrant that the software will be compatible with any licensee application or environment or otherwise meet licensee’s requirements, or that operation will be uninterrupted or error-free.</p>
                                    <p>To the maximum extent permitted by applicable law, the Licensor hereby disclaims all other warranties, express or implied, oral or written, including without limitation, all implied warranties of title, non-infringement, integration, accuracy, merchantability or fitness for any particular purpose and all warranties arising from any course of dealing or performance or usage of trade.</p>
                                </li>
                                <li>
                                    <h6>LIMITATION OF LIABILITY</h6>
                                    <p><b>8.1</b> Licensor shall have no liability or obligation to Licensee hereunder with respect to any licensed Software with regard to any claim based upon (a) any use of the Software not strictly in accordance with this Agreement, (b) any use of Software in an application or environment or on a platform or with devices for which it was not designed or contemplated, (c) alterations, combinations or enhancements of the Software not created by Licensor, (d) Licensee’s continuing allegedly infringing activity after being notified thereof.</p>
                                    <p><b>8.2</b> The Licensor shall not be liable concerning the subject matter of this agreement (including the indemnification), regardless of the form of any claim or action (whether in contract, negligence, strict liability or otherwise), for any (a) matter beyond its reasonable control, (b) loss or inaccuracy of data, loss or interruption of use, or cost of procuring substitute technology, goods or services, (c) indirect, punitive, incidental, reliance, special, exemplary or consequential damages including, but not limited to, loss of business, revenues, profits or goodwill, or (d) damages, in the aggregate, in excess of the subscription fees paid by licensee to licensor for the software giving rise to such damages during the 12-month period prior to the date the cause of action arose, even if it has been advised of the possibility of such damages. These limitations are independent from all other provisions of this agreement and shall apply notwithstanding the failure of any remedy provided herein.</p>
                                </li>
                                <li>
                                    <h6>TERM AND TERMINATION</h6>
                                    <p><b>9.1 Term.</b> This Agreement shall commence on the Effective Date and continue in effect for the Subscription Term unless specified otherwise in the Sales Order. Thereafter, and unless otherwise specified in the Order Form, this Agreement shall automatically renew for the same Subscription Term duration specified in the initial Sales Order unless either party notifies the other in writing of its intention not to renew by no later than sixty (60) days before the expiration of the then-current term</p>
                                    <p><b>9.2 Termination.</b> This Agreement may be terminated (a) by either party if the other party materially breaches a provision of this Agreement and fails to cure such breach within 30 days (10 days in the case of any non-payment) after receiving written notice of such breach from the non-breaching party; or (b) by either party immediately upon written notice, if the other party makes any assignment for the benefit of creditors, or a receiver, trustee in bankruptcy or similar officer is appointed to take charge of any or all of the other party’s property, or the other party seeks protection under any bankruptcy, creditors arrangement, composition or comparable proceeding or such a proceeding is instituted against the other party, or the other party becomes insolvent or, without a successor, dissolves, liquidates or otherwise fails to operate in the ordinary course.</p>
                                    <p><b>9.3 Effects of Termination.</b> Upon termination of this Agreement for any reason, all rights, obligations and licenses of the parties hereunder shall cease, except that (a) all obligations that accrued prior to the effective date of termination and any remedies for breach of this Agreement shall survive any termination, (b) Licensee shall promptly return or destroy all Software and other tangible Confidential Information, and permanently erase all Confidential Information from any computer and storage media, and (c) the provisions of Sections 4 (Payments), 5 (Confidentiality), 6 (Proprietary Rights), 7 (Warranty and Disclaimers), 8 (Limitation of Liability), 10 (General Provisions) and this Section 9.3 shall also survive.</p>
                                </li>
                                <li>
                                    <h6>GENERAL PROVISIONS</h6>
                                    <p><b>10.1 Entire Agreement.</b> This Agreement including the Sales Order, constitutes the entire agreement, and supersedes all prior negotiations, understandings or agreements (oral or written), between the parties about the subject matter of this Agreement. No waiver, consent or modification of this Agreement shall bind either party unless in writing and signed by the party against which enforcement is sought, except the terms of this Agreement that may be modified by Licensor from time to time so long as they do not result in a diminution of the rights granted hereunder. By downloading updates delivered pursuant to the support and maintenance services, Licensee agrees to be bound by the terms of the Agreement then in effect. The failure of either party to enforce its rights under this Agreement at any time for any period will not be construed as a waiver of such rights. If any provision of this Agreement is determined to be illegal or unenforceable, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.</p>
                                    <p><b>10.2 Governing Law & Jurisdiction.</b> This Agreement shall be governed by and construed in accordance with the laws of India and the parties agree to submit any dispute in respect of this Agreement to the exclusive jurisdiction of the courts at New Delhi, India only</p>
                                    <p><b>10.3 Remedies.</b> Unless specifically provided otherwise, each right and remedy in this Agreement is in addition to any other right or remedy, at law or in equity. Licensee agrees that, in the event of any breach or threatened breach of Section 5 or 6, Licensor will suffer irreparable damage for which there is no adequate remedy at law. Accordingly, Licensor shall be entitled to injunctive and other equitable remedies to prevent or restrain such breach or threatened breach.</p>
                                    <p><b>10.4 Notices.</b> Any notice or communication hereunder shall be in writing and either personally delivered, or sent via confirmed electronic mail, or sent via recognized delivery courier or certified or registered mail A.D., addressed to the other party at its address specified above or in the Sales Order, or at such other address designated in a subsequent notice. </p>
                                    <p><b>10.5 Assignment.</b> This Agreement and the rights and obligations hereunder are personal to Licensee, and may not be assigned or otherwise transferred, in whole or in part, without Licensor’s prior written consent. Any attempt to do otherwise shall be void and of no effect. This Agreement shall be binding upon, and inure to the benefit of, the successors, representatives and permitted assigns of the parties</p>
                                    <p><b>10.6 Non-Solicitation.</b> The Licensor and Licensee agree that during the Term of the Agreement and for a period of one (1) year following its expiration or termination, the Licensee shall not entice, solicit or encourage any employee of the Licensor to leave their office for any reason whatsoever. In case the Licensee entice, solicit or encourage any of the Licensor’s employee at any time during the existence of this Agreement, the Licensor, notwithstanding any thing herewith, shall be entitled to immediately terminate this Agreement and shall cease its obligations in terms of this Agreement</p>
                                    <p><b>10.7 Acknowledgment.</b> Licensee acknowledges that (a) it has read and understood this Agreement, (b) it has had an opportunity to have its legal counsel review this Agreement and (c) Licensor requires identification of the Licensee before issuing this license.</p>
                                </li>
                                <li>
                                    <h6>COUNTERPARTS</h6>
                                    <p><b>11.1</b> This Agreement may be executed by the Parties in separate counterparts each of which when so executed and delivered shall be an original, but all such counterparts shall together constitute one and the same instrument.</p>
                                </li>
                                <li>
                                    <h6>FORCE MAJEURE</h6>
                                    <p><b>12.1</b> Neither Party shall be responsible for any failure to comply with its respective obligations under this Agreement, where such failure or delay is due to events of Force Majeure (as defined below) provided that the affected Party notifies the non-affected Party within forty eight (48) hours of the commencement of the event of Force Majeure.</p>
                                    <p><b>12.2</b> Force Majeure events shall mean and shall include: acts of God such as fire, storm, flood, earthquake, explosion or accident; acts of the public enemy; acts of war or terrorism; sabotage; epidemic; quarantine restrictions; transportation embargoes or failures or delays in transportation; strikes; acts of any government, whether national, municipal or otherwise, or any agency thereof; and acts of third parties which are beyond the reasonable control of such Party.</p>
                                </li>
                                <li>
                                    <h6>CONTACT US</h6>
                                    <p>Please contact us for any questions or comments (including all inquiries unrelated to copyright infringement) regarding this Website.</p>
                                    <p><b>Grievance officer</b></p>
                                    <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>
                                    <p>Five Dots Solutions Pvt Ltd. <br />
                                        Plot No-4, Second Floor,<br />
                                        Sheetla Complex, Prem Nagar - 2, Gurugram, <br />
                                        Haryana-122001
                                    </p>
                                    <p><b>Contact us</b></p>
                                    <p>
                                        Email: contact@tappetbox.com <br />
                                        Phone: 9650222555 <br />
                                        Time: Mon - Fri (10:00 - 18:00)  <br />

                                    </p>
                                </li>
                            </ol>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                </section>
            </div>
        </div>)
    }

    render() {
        return (
          <Ax>
              <Helmet><title>TappetBox - Terms & Conditions</title></Helmet>
            <div className="pageTbl" style={{height:"100vh"}}>
            {this.topHeader()}
            {this.headerLogo()}
            {this.softwarePolicyJsx()}
            {this.footer()}
            {this.copywrite()}
            </div>
          </Ax>
        )
    }
}

export default TermsAndConditions;
