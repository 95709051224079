import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import swal from "sweetalert";
import Status from '../../../components/ui/Status';
import InputGroupSearch from '../../includes/ui/InputGroupSearch';
import InputUserSearch from '../../includes/ui/InputUserSearch';
import TapSelect from '../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import InputGroupUserSearch from '../../includes/ui/InputGroupUserSearch';



class FeedbackListing extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.feedbackSearchInitState         =   {
            'search_ratings'                 :   [],
            'search_status_ids'                 :   [],
            'search_source'                    :   [],
            'search_user_ids'                :       [],
            'search_group_ids'               :       [],
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
        };

        this.feedbackSearchFormLabel         =   {
            'search_ratings'                 :   'Ratings : ',
            'search_status_ids'             :    'Status : ',
            'search_source'                 :    'Source : ',
            'search_user_ids'               :    'User : ',
            'search_group_ids'              :    'Group : ',
            'date_range'                    :   'Feedback Period : ',
            
        };
       
        this.state                           =     {
            minimizeTable                :       false,
            listing_loading              :       false,
            listing_tbl_page             :       1,
            feedback_listing             :       [],
            listingMeta                  :       null,
            totalListingCount            :       0,
            feedbackData                 :       null,
            feedbackSearchForm              :       {...this.feedbackSearchInitState},
            submittedFeedbackSearchForm      :       {...this.feedbackSearchInitState},
            formSearchedElems               :       [],
            allRatings                      :       [{value:1,label:1},{value:2,label:2},{value:3,label:3},{value:4,label:4},{value:5,label:5}],
            allStatus                       :       [],
            allSources                      :       [],
            all_periods                      :       [],
            searchedUsers                    :   [],
            searchedGroups                    :   [],
        };
       
    }

    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
        this.loadListingTblData(1);
        this.getFormData();
        this.feedbackSearchModal     =    new Modal(document.getElementById('feedbackSearchModal'), {keyboard: false, backdrop :false}); 
    }


    initalizeComponent      =       (pr)            =>      {
        
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
            //all_periods     :        all_periods ,
        })
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50,...this.state.submittedFeedbackSearchForm};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/feedback/list', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                feedback_listing    :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
            });
        }).then(() => this.setState({listing_loading: false}));
    } 
    
    getFormData        =       ()      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH  + '/backoffice/feedback/form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
            let all_periods             =     respData && respData.periods && respData.periods.length > 0 ?  [...respData.periods , {key : 'custom' , display : 'Custom Date Range'}] : [] ;
            this.setState({
                formDataLoaded              :   true,
                allStatus                   :   respData && respData.status &&  respData.status.length > 0  ? respData.status.map((s) => { return {value: s.key, label: `${s.status}`}}) : [],
                all_periods                 :   all_periods,
                allSources                  :   respData && respData.source && respData.source.length > 0 ? respData.source.map(so => {return({value:so,label:so})}) : [],
            },() => {
                
            })
        }).then(() => this.setState({form_data_loading: false}));
    }
    
    viewFeedbackData        =       (id)        =>      {
        if (this.props.permissions.includes('app-release-view')) {
            this.setState({
                minimizeTable : true
            })
    
            this.getFeedbackDetail(id)
        }else{
            toast.error('You dont have permission for this action')
        }
        
    }

    getFeedbackDetail           =       (id)      =>      {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_SERVER_PATH + '/backoffice/feedback/detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            
            this.setState({
                feedbackData        :   respData.data
            });

        }).then(() => this.setState({ view_loading: false }));
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    changeStatusInit        =       (id ='',status='')      =>      {
        let frmData = {
            id      :   this.state.feedbackData ? this?.state?.feedbackData?.id : id,
            status  :   this.state.feedbackData && this.state.feedbackData.status == 26 ? 23  : status  && status == 26 ? 23  : 26
        }

        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/feedback/update', this.props.access_token, {} ,{...frmData},(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData(1);
                        if(this.state.feedbackData){
                            this.getFeedbackDetail(this.state?.feedbackData?.id)
                        }
                    }).then(() => {

                    });
                }
            });
    }

    feedbackSearchModalInit         =       ()      =>      {
        this.feedbackSearchModal.show(); 
    }

    feedbackSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.feedbackSearchForm);
        let searchedElems               =   [];
        let feedbackSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.feedbackSearchFormLabel[key];
            let value                       =   this.state.feedbackSearchForm[key];
            if(value && value.toString().length > 0) {
                feedbackSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                   
                     if(key == 'search_source') {
                        show_val            =   this.state.allSources.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ratings') {
                        show_val            =   this.state.allRatings.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_user_ids') {
                        show_val            =   this.state.searchedUsers ? this.state.searchedUsers.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'search_group_ids') {
                        show_val            =   this.state.searchedGroups ? this.state.searchedGroups.map(s => s.label).join(', ') : '';
                    }
                   
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.feedbackSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.feedbackSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedFeedbackSearchForm         :   {...this.state.feedbackSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.feedbackSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    feedbackSearchClear         =       ()     =>      {
        this.setState({
            feedbackSearchForm                :   {...this.feedbackSearchInitState},
            submittedFeedbackSearchForm       :   {...this.feedbackSearchInitState},
            formSearchedElems                 :   [],
        searchedGroups                      :       [],
        searchedUsers                   :       []
        }, () => {
            this.feedbackSearchModal.hide();
            this.loadListingTblData(1);
        });
    }


   
    feedbackJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Feedback List</h3>
                <div className="text-end mt15">
                {(!this.state.minimizeTable) ?
                        <Ax>  <button type="button" disabled={this.state.listing_loading} onClick={this.feedbackSearchModalInit} className="btn btn-secondary" >
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        </Ax>
                        : null
                    }

                </div>

            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Feedback Detail </h3>
                            <div className="text-end mt15">
                                {this.props.permissions.includes('feedback-update') && <button type="button" className="btn btn-secondary" onClick={this.changeStatusInit}>
                                    Change Status
                                </button>}
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.feedbackListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <div className='text-center'><Loader /></div> : this.feedbackViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    feedbackListingJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.feedbackSearchClear} />
           <table className="table table-bordered table-fixed  bg-white table-sm">

                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Feedback</th>
                        </tr>)
                        : (
                            <tr className='text-center'>
                                <th scope="col" style={{ width: "5%" }} >S.No</th>
                                <th scope="col" style={{ width: "13%" }}>Date of Feedback</th>
                                <th scope="col" style={{ width: "25%" }}>Group Name</th>
                                <th scope="col" style={{ width: "21%" }}>User Name</th>
                                <th scope="col" style={{ width: "10%" }}>Rating</th>
                                <th scope="col" style={{ width: "12%" }}>Source</th>
                                <th scope="col" style={{ width: "10%" }}>Status</th>
                                <th scope="col" style={{ width: "8%" }}>Action</th>

                            </tr>)}
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.feedback_listing.length > 0
                            ? (this.state.feedback_listing.map((feedback, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewFeedbackData(feedback.id)}>{feedback.groupData ? feedback.groupData.group_name : "-"} </a>
                                                        <div className="text-end">
                                                            <small><Status color={feedback.status_color}>{feedback.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td>{feedback.created_at_display ? feedback.created_at_display : ""}</td>
                                                <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewFeedbackData(feedback.id)}>{feedback.groupData ? feedback.groupData.group_name : "-"}</td>
                                                <td>{feedback.user_detail ? feedback.user_detail.full_name : ""}</td>
                                                <td  className="text-center">{feedback.rating ? feedback.rating : "0"}</td>
                                                <td className="text-center">{feedback.source ? feedback.source : "web"}</td>
                                                <td  className="text-center"><Status color={feedback.status_color}>{feedback.status_text}</Status></td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewFeedbackData(feedback.id)}>View</a></li>
                                                       {this.props.permissions.includes('feedback-update') && <li><a className="dropdown-item" role="button" onClick={() => this.changeStatusInit(feedback.id,feedback.status)}>Change Status</a></li>}
                                                    </ul>
                                                </td>

                                            </Ax>)}

                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

            </table>
           
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    feedbackViewDetails       =       ()      =>      {
        let detail = this.state.feedbackData;
        return (<div className="tab_content_header">
            {detail
                ? <Ax>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                        <tbody>
                        <tr>
                                <td style={{ width: "26%" }} >Date of Feedback</td>
                                <th>{detail.created_at_display ? detail.created_at_display : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Group Name</td>
                                <th>{detail.groupData ? detail.groupData.group_name : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >User Name</td>
                                <th className='text-capitalize'>{detail.user_detail ? detail.user_detail.full_name : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Rating</td>
                                <th>{detail.rating ? detail.rating : "0"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Source</td>
                                <th>{detail.source ? detail.source : "web"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Suggestion</td>
                                <th>{detail.suggestion ? detail.suggestion : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Comment</td>
                                <th>{detail.comment ? detail.comment : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Status</td>
                                <th><Status color={detail.status_color}>{detail.status_text}</Status></th>

                            </tr>
                        
                           
                        </tbody>
                    </table>
                </Ax>
                : null}
        </div>)
    }

    feedbackSearchModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"feedbackSearchModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Search</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.feedbackSearchFormSubmit} id={"feedbackSearchForm"}>

                            <div className="row align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Ratings</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allRatings}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'feedbackSearchForm', 'search_ratings')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allRatings.filter(s => this.state.feedbackSearchForm.search_ratings.includes(s.value))}
                                        placeholder="Select Topics"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Status</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allStatus}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'feedbackSearchForm', 'search_status_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allStatus.filter(s => this.state.feedbackSearchForm.search_status_ids.includes(s.value))}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Source</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allSources}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'feedbackSearchForm', 'search_source')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allSources.filter(s => this.state.feedbackSearchForm.search_source.includes(s.value))}
                                        placeholder="Source"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            
                            
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Group</label>
                                <div className="col-sm-9">
                                    <InputGroupSearch
                                        changeEvent={(selectedOption) => {

                                            this.tapSelectChange(selectedOption, 'feedbackSearchForm', 'search_group_ids');
                                            this.setState({
                                                feedbackSearchForm: { ...this.state.feedbackSearchForm, search_group_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                searchedGroups: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        placeholder="Select Groups"
                                        value={this.state.searchedGroups.length > 0 ? this.state.searchedGroups.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Group Users</label>
                                <div className="col-sm-9">
                                    <InputGroupUserSearch
                                        changeEvent={(selectedOption) => {

                                            this.tapSelectChange(selectedOption, 'feedbackSearchForm', 'search_user_ids');
                                            this.setState({
                                                feedbackSearchForm: { ...this.state.feedbackSearchForm, search_user_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                searchedUsers: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        placeholder="Select Users"
                                        value={this.state.searchedUsers.length > 0 ? this.state.searchedUsers.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row py-2 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Feedback Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={this.state.all_periods ? this.state.all_periods : []}
                                        value={this.state.feedbackSearchForm.date_range}
                                        startDate={this.state.feedbackSearchForm.date_range_start}
                                        endDate={this.state.feedbackSearchForm.date_range_end}
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            this.setState({
                                                feedbackSearchForm
                                                    : {
                                                    ...this.state.feedbackSearchForm,
                                                    date_range: period,
                                                    date_range_start: startDate ? startDate : null,
                                                    date_range_end: endDate ? endDate : null
                                                }
                                            });
                                        }}
                                        className="form-control-sm"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"feedbackSearchForm"}>Search{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Feedback Listing</title></Helmet>
            {this.feedbackJsx()}
            {this.feedbackSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(FeedbackListing);