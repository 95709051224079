import * as actionTypes from './actionTypes';

import { appInitilizationStarting, applicationInitLoading } from './app';
import {assetWidgetClear, complianceWidgetClear, counterlogAgeingWidgetClear, fuelSummaryWidgetClear, PACWidgetClear, purchaseOrderWidgetClear, rentalDueDatesWidgetClear, rentalStatusWidgetClear, reportListingInitilization, requisitonWidgetClear, scsWidgetClear, ticketWidgetClear, transactionDelayWidgetClear, walletSummaryWidgetClear}  from './dashboard'

import axios from 'axios';
import { iscAppDataInitilization } from './isc';
import { iamAppDataInitilization } from './asset';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (authData) => {
    localStorage.setItem('access_token', authData.access_token);
    localStorage.setItem('token_type', authData.token_type);
    localStorage.setItem('expires_in', authData.expires_in);
    
    //Call Application Initialize Function :-
//    applicationInit(authData.access_token);
  
    return {
        type            :   actionTypes.AUTH_SUCCESS,
        access_token    :   authData.access_token,
        token_type      :   authData.token_type,
        expires_in      :   authData.expires_in
    };
};

export const authFail = (err) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: err,
        response: err.response,
    };
};

export const logout = () => { 
    if(localStorage.getItem('access_token')) {
        axios({
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + localStorage.getItem('access_token')},
            url: process.env.REACT_APP_API_SERVER + '/logout'
        })
        .then(function (response) {})
        .catch(function (err) {});
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_type');
    localStorage.removeItem('expires_in');
   
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const authCheckState = () => {
    return dispatch => {
        const access_token = localStorage.getItem('access_token');
        if (!access_token) {
            dispatch(logout());
        } else {
            const accessData = {
                access_token: localStorage.getItem('access_token'),
                token_type: localStorage.getItem('token_type'),
                expires_in: localStorage.getItem('expires_in'),
            };
            dispatch(authSuccess(accessData));
            dispatch(applicationInitLoading(accessData.access_token));
        }
    };
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const clearAppData = () => {
    return dispatch => {
        dispatch(iscAppDataInitilization());
        dispatch(iamAppDataInitilization());
        dispatch(reportListingInitilization());
        dispatch(appInitilizationStarting())

    };
}