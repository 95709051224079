import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import InputUserSearch from '../../includes/ui/InputUserSearch';
import InputGroupSearch from '../../includes/ui/InputGroupSearch';


class TrainingList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initCommentForm            =       {
            training_id             :            '',
            comment                 :           ''
        }

        this.initAddTraingForm    =   {
            id                  :      '',
            trainer_ids         :      [],
            training_mode       :   "online",
            training_date       :       ''
        }

        this.trainingSearchInitState         =   {
            'search_topic_ids'                 :   [],
            'search_status'                 :   [],
            'search_request_mode'           :   '',
            'search_training_mode'           :   '',
            'search_trainer_ids'             :   [],
            'search_master_ids'             :   [],
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
        };

        this.trainingSearchFormLabel         =   {
            'search_topic_ids'                 :   'Topics : ',
            'search_status'                 :   'Status : ',
            'search_request_mode'           :   'Requested Mode of Training : ',
            'search_training_mode'           :   'Mode of Training : ',
            'search_trainer_ids'            :   'Trainers : ',
            'search_master_ids'            :   'Group : ',
            'date_range'                    :   'Training Period : ',
            
        };
       
        this.state                           =     {
            minimizeTable                :       false,
            listing_loading              :       false,
            listing_tbl_page             :       1,
            training_listing             :       [],
            listingMeta                  :       null,
            totalListingCount            :       0,
            trainingData                 :       null,
            addCommentForm               :       {...this.initCommentForm},
            formDataLoaded               :       false,
            allStatus                    :       [],
            addTrainingForm              :       {...this.initAddTraingForm},
            saveFormSubmitting           :       false,
            trainingSearchForm              :       {...this.trainingSearchInitState},
           submittedTrainingSearchForm      :       {...this.trainingSearchInitState},
           formSearchedElems               :       [],
           allTopicsList                    :       [],
           allModes                         :       [{value:"online",label:"Online"},{value:"offline",label:"Offline"}],
           all_periods                      :       [],
           searchedUsers                    :   [],
           searchedGroups                    :   [],
                 
        };
       
    }

    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
        // this.loadListingTblData(1);
        // if(!this.state.formDataLoaded){
            this.getaddCommentFormData()
        //}
       
                 
        this.addCommentModal         =   new Modal(document.getElementById('addCommentModal'), {keyboard: false, backdrop :false});  
        this.uploadTrainingModal     =   new Modal(document.getElementById('updateTrainingModal'), {keyboard: false, backdrop :false}); 
        this.trainingSearchModal     =    new Modal(document.getElementById('trainingSearchModal'), {keyboard: false, backdrop :false});  
    }
    
    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.all_periods !== this.props.all_periods){
    //         this.initalizeComponent(nextProps);
    //     }
    // }

    initalizeComponent      =       (pr)            =>      {
        
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
            //all_periods     :        all_periods ,
        })
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50,...this.state.submittedTrainingSearchForm};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/project/training', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                training_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }   
    
    viewTrainingData        =       (id)        =>      {
        if(this.props.permissions.includes('training-view')){
            this.setState({
                minimizeTable : true
            })
    
            this.getTrainingDetail(id)
        }else{
            toast.error('You dont have permission to perform this action')
        }
        
    }

    getTrainingDetail           =       (id)      =>      {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_SERVER_PATH + '/backoffice/training/detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            
            this.setState({
                trainingData        :   respData.data
            });

        }).then(() => this.setState({ view_loading: false }));
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    getaddCommentFormData        =       ()      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH  + '/backoffice/training/form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
            let all_periods             =     respData && respData.periods && respData.periods.length > 0 ?  [...respData.periods , {key : 'custom' , display : 'Custom Date Range'}] : [] ;
            this.setState({
                formDataLoaded              :   true,
                allStatus                   :   respData && respData.status &&  respData.status.length > 0  ? respData.status.map((s) => { return {value: s.key, label: `${s.status}`}}) : [],
                allTopicsList               :   respData && respData.topics && respData.topics.length > 0 ? respData.topics.map(tp => {return({value:tp.id,label:tp.name})}) : [],
                all_periods                 :   all_periods
            },() => {
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                if(ParamObject.get('search_status')){
                    let updateFilterForm = {search_status : [Number(ParamObject.get('search_status'))]};
                    
                    setTimeout(() => {
                        this.setState({trainingSearchForm    : 
                                    {   ...this.state.trainingSearchForm,...updateFilterForm } 
                        }, () => {
                                this.trainingSearchFormSubmit()
                        });
                    }, 1000)
                    
                 }else{
                    this.loadListingTblData(1);
                }
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    addCommentModalInit     =       ()      =>      {
        this.addCommentModal.show();
        this.setState({
            addCommentForm : {
                ...this.initCommentForm,
                training_id     :       this.state?.trainingData?.id,
            }
        })
    }

    submitCommentForm      =       (e)         =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH  + '/backoffice/training/comment', this.props.access_token, {}, { ...this.state.addCommentForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.setState({addCommentForm:{...this.initCommentForm}})
           this.addCommentModal.hide();
           this.getTrainingDetail(this.state?.trainingData?.id);
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }

    editTrainingModal       =       ()      =>      {
        if(this.state.trainingData){
            let data = this.state.trainingData;
            this.uploadTrainingModal.show();
            this.setState({
                addTrainingForm : {
                    ...this.initAddTraingForm,
                    id      :     data.id ? data.id : "",
                    training_date      :   data.training_date ? data.training_date : "",
                    preferred_date      :   data.preferred_date ? data.preferred_date : "",
                    training_mode        :   data.training_mode ? data.training_mode : "online",
                    status          :       data.status ? data.status : "",
                    trainer_ids         :      data.trainersData && data.trainersData.length > 0 ? data.trainersData.map(td => {return({...td,value:td.id,label:td.name})}) : []
                }})
        }
    }

    submitScheduleTrainingForm      =       (e)         =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/project/training', this.props.access_token, {}, { ...this.state.addTrainingForm,trainer_ids : this.state.addTrainingForm.trainer_ids.map(td => td.value) }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.setState({addTrainingForm:{...this.initAddTraingForm}})
           this.uploadTrainingModal.hide();
           this.loadListingTblData(1);
           this.getTrainingDetail(this.state.trainingData.id)
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }

    trainingSearchModalInit         =       ()      =>      {
        this.trainingSearchModal.show(); 
    }

    trainingSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.trainingSearchForm);
        let searchedElems               =   [];
        let trainingSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.trainingSearchFormLabel[key];
            let value                       =   this.state.trainingSearchForm[key];
            if(value && value.toString().length > 0) {
                trainingSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                   
                     if(key == 'search_topic_ids') {
                        show_val            =   this.state.allTopicsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_request_mode') {
                        show_val            =   this.state.allModes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_training_mode') {
                        show_val            =   this.state.allModes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.allStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_trainer_ids') {
                        show_val            =   this.state.searchedUsers ? this.state.searchedUsers.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'search_master_ids') {
                        show_val            =   this.state.searchedGroups ? this.state.searchedGroups.map(s => s.label).join(', ') : '';
                    }
                   
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.trainingSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.trainingSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedTrainingSearchForm         :   {...this.state.trainingSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.trainingSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    trainingSearchClear         =       ()     =>      {
        this.setState({
            trainingSearchForm                :   {...this.trainingSearchInitState},
            submittedTrainingSearchForm       :   {...this.trainingSearchInitState},
            formSearchedElems                 :   [],
        searchedGroups                      :       [],
        searchedUsers                   :       []
        }, () => {
            this.trainingSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    trainingJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Training List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>  <button type="button" disabled={this.state.listing_loading} onClick={this.trainingSearchModalInit} className="btn btn-secondary" >
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        </Ax>
                        : null
                    }

                </div>

            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Training Detail </h3>
                            <div className="text-end mt15">
                                {this.props.permissions.includes('training-schedule-manage') && <button type="button" className="btn btn-secondary" onClick={() => this.editTrainingModal()}>Update</button>}
                                {this.props.permissions.includes('training-add-comment') && <button type="button" className="btn btn-secondary" onClick={() => this.addCommentModalInit()}>Add Comment</button>}
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.trainingListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <div className='text-center'><Loader /></div> : this.trainingViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    trainingListingJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.trainingSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">

                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Training</th>
                        </tr>)
                        : (
                            <tr>
                                <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                                <th scope="col" style={{ width: "18%" }}>Topics</th>
                                <th scope="col" style={{ width: "25%" }}>Group Name</th>
                                <th scope="col" style={{ width: "9%" }}>Mode of Training</th>
                                <th scope="col" style={{ width: "9%" }}>Mobile</th>
                                <th scope="col" style={{ width: "9%" }}>Requested Date</th>
                                <th scope="col" style={{ width: "9%" }}>Training Date</th>
                                {/* <th scope="col" style={{ width: "25%" }}>Trainers</th> */}
                                <th scope="col" style={{ width: "9%" }} className='text-center'>Status</th>
                                <th scope="col" style={{ width: "8%" }} className='text-center'>Action</th>

                            </tr>)}
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.training_listing.length > 0
                            ? (this.state.training_listing.map((training, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewTrainingData(training.id)}>{training.topicsData && training.topicsData.length > 0 ? training.topicsData.map((td, k) => { return (td.name) }).join(', ') : "-"} </a>
                                                        <div className="mt-1">
                                                            <small> <span>{training.groupData ? training.groupData.group_name : "-"}</span> </small>
                                                            <small className="float-end"><Status color={training.status_color}>{training.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewTrainingData(training.id)}>{training.topicsData && training.topicsData.length > 0 ? training.topicsData.map((td, k) => { return (td.name) }).join(', ') : "-"}</td>
                                                <td>{training.groupData ? training.groupData.group_name : "-"}</td>
                                                <td className='text-capitalize'>{training.training_mode ? training.training_mode : ""}</td>
                                                <td>{training.mobile ? training.mobile : "-"}</td>
                                                <td>{training.preferred_date_display ? training.preferred_date_display : "-"}</td>
                                                <td>{training.training_date_display ? training.training_date_display : "-"}</td>
                                                {/* <td>{training.trainersData && training.trainersData.length > 0 ? training.trainersData.map(st => {return(st.name)}).join(', ') : "-"}</td> */}
                                                <td><Status color={training.status_color}>{training.status_text}</Status></td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewTrainingData(training.id)}>View</a></li>
                                                    </ul>
                                                </td>

                                            </Ax>)}

                                </tr>)
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

            </table>
            {/* <div className='text-sm p-2'>*Preferred Training Date</div> */}
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    trainingViewDetails       =       ()      =>      {
        let detail = this.state.trainingData;
        return (<div className="tab_content_header">
            {detail
                ? <Ax>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Requested Details</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                        <tbody>
                            <tr>
                                <td style={{ width: "26%" }} >Topics</td>
                                <th>{detail.topicsData && detail.topicsData.length > 0 ? detail.topicsData.map((td, k) => { return (td.name) }).join(', ') : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Group Name</td>
                                <th>{detail.groupData ? detail.groupData.group_name : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Requested Mode of Training</td>
                                <th className='text-capitalize'>{detail.request_mode ? detail.request_mode : "-"}</th>

                            </tr>
                        
                            <tr>
                                <td style={{ width: "26%" }} >Mobile</td>
                                <th>{detail.mobile ? detail.mobile : "-"}</th>

                            </tr>
                           
                            <tr>
                                <td style={{ width: "26%" }}>Requested Date of Training</td>
                                <th>{detail.preferred_date_display ? detail.preferred_date_display : "-"}</th>

                            </tr>
                            
                            <tr>
                                <td style={{ width: "26%" }} >Notes</td>
                                <th>{detail.notes ? detail.notes : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Status</td>
                                <th>{detail.status ? <Status color={detail.status_color}>{detail.status_text}</Status> : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >User Name</td>
                                <th>{detail.created_by ? <span>{detail?.created_by?.full_name}</span> : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >User Number</td>
                                <th>{detail.created_by ? <span>{detail?.created_by?.mobile}</span> : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Requested On</td>
                                <th>{detail.created_at_display ? <span>{detail.created_at_display}</span> : "-"}</th>

                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Training Details</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                        <tbody>
                           
                            <tr>
                                <td style={{ width: "26%" }} >Mode of Training</td>
                                <th className='text-capitalize'>{detail.training_mode ? detail.training_mode : "-"}</th>

                            </tr>

                            <tr>
                                <td style={{ width: "26%" }} >Trainers</td>
                                <th>{detail.trainersData && detail.trainersData.length > 0 ? detail.trainersData.map(st => {return(st.name)}).join(', ') : "-"}</th>

                            </tr>
                           
                            <tr>
                                <td style={{ width: "26%" }}>Training Date</td>
                                <th>{detail.training_date_display ? detail.training_date_display : "-"}</th>

                            </tr>

                        </tbody>
                    </table>
                    { detail.comments && detail.comments.length > 0 && <Ax>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Comments</span></div>
                        
                        </Ax>}
                   
                        <div className="my-2">
                            {
                                detail.comments && detail.comments.length > 0 ? detail.comments.map((c, k) => {
                                    return (<Ax key={k}><div className="row">
                                        {c.comment ? <div className='col-sm-8'>{c.userData && c.userData.full_name} added Comment <br/> {c.comment ? c.comment : ""} </div> : <div className='col-sm-8'>-</div>}
                                        <div className="col-sm-4 text-end">
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                            <span> {c.created_at_display}</span>
                                        </div>
                                        <div className="col-sm-8 my-1">{c.notes}</div>
                                    </div><hr /></Ax>);
                                })
                                    : null
                            }
                        </div>
                </Ax>
                : null}
        </div>)
    }

    addCommentModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"addCommentModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Add Comment</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitCommentForm} id={"addCommentForm"}>
                            
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Comment </label>
                                <div className="col-sm-9">
                                    <textarea
                                        name="comment"
                                        value={this.state.addCommentForm.comment}
                                        onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                        className="form-control"
                                        style={{ height: "100px" }}
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"addCommentForm"}>Submit{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }
    
    updateTrainingModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"updateTrainingModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Schedule a Training</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitScheduleTrainingForm} id={"updateTrainingForm"}>
                        <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Status</label>
                                <div className="col-sm-9">
                                <TapSelect
                                        options={this.state.allStatus}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTrainingForm', 'status')}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={this.state.allStatus.find(s => this.state.addTrainingForm.status == s.value)}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Mode of Training</label>
                                <div className="col-sm-9">
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="training_mode"
                                            type="radio"
                                            value="online"
                                            onChange={(e) => {
                                                this.formInputHandler(e, 'addTrainingForm');
                                            }}
                                            checked={this.state.addTrainingForm.training_mode === "online"}
                                            className="form-check-input"
                                            required={true}
                                            id="addTrainingFormonline"
                                        />
                                        <label className="form-check-label text-sm" htmlFor="addTrainingFormonline">Online  (Through Google Meet, Zoom Call, etc)</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="training_mode"
                                            type="radio"
                                            value="offline"
                                            onChange={(e) => {
                                                this.formInputHandler(e, 'addTrainingForm');
                                            }}
                                            checked={this.state.addTrainingForm.training_mode === "offline"}
                                            className="form-check-input"
                                            required={true}
                                            id="addTrainingForm_offline"
                                        />
                                        <label className="form-check-label text-sm" htmlFor="addTrainingForm_offline">Offline  (By visiting your office)</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Trainers </label>
                                <div className="col-sm-9">
                                    <InputUserSearch
                                        changeEvent={(selectedOption) => {
                                            this.setState({
                                                addTrainingForm: { ...this.state.addTrainingForm, trainer_ids: selectedOption.length > 0 ? selectedOption : [] },
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        value={this.state.addTrainingForm && this.state.addTrainingForm.trainer_ids && this.state.addTrainingForm.trainer_ids.length > 0 ? this.state.addTrainingForm.trainer_ids.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Tentative Date of Training </label>
                                <div className="col-sm-9 add_calender_section">
                                    <DatePicker
                                        selected={
                                            this.state.addTrainingForm.preferred_date
                                                ? moment(this.state.addTrainingForm.preferred_date, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="preferred_date"
                                        onChange={(value, event) => this.formDateHandler('preferred_date', value, 'addTrainingForm')}
                                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        disabled
                                        placeholderText={`Tentative Date of Training`}

                                    />

                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Training Date </label>
                                <div className="col-sm-9 add_calender_section">
                                    <DatePicker
                                        selected={
                                            this.state.addTrainingForm.training_date
                                                ? moment(this.state.addTrainingForm.training_date, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="training_date"
                                        onChange={(value, event) => this.formDateHandler('training_date', value, 'addTrainingForm')}
                                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        placeholderText={`Date of Training`}

                                    />

                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                                </div>
                            </div>
                           
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"updateTrainingForm"}>Submit{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    trainingSearchModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"trainingSearchModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Search</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.trainingSearchFormSubmit} id={"trainingSearchForm"}>

                            <div className="row align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Topics</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allTopicsList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_topic_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allTopicsList.filter(s => this.state.trainingSearchForm.search_topic_ids.includes(s.value))}
                                        placeholder="Select Topics"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Status</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allStatus}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_status')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allStatus.filter(s => this.state.trainingSearchForm.search_status.includes(s.value))}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Requested Mode of Training</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allModes}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_request_mode')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allModes.filter(s => this.state.trainingSearchForm.search_request_mode.includes(s.value))}
                                        placeholder="Requested Mode of Training"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Mode of Training</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allModes}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_training_mode')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allModes.filter(s => this.state.trainingSearchForm.search_training_mode.includes(s.value))}
                                        placeholder="Select Mode of Training"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Trainers</label>
                                <div className="col-sm-9">
                                    <InputUserSearch
                                        changeEvent={(selectedOption) => {

                                            this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_trainer_ids');
                                            this.setState({
                                                trainingSearchForm: { ...this.state.trainingSearchForm, search_trainer_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                searchedUsers: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        placeholder="Select Trainers"
                                        value={this.state.searchedUsers.length > 0 ? this.state.searchedUsers.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Group</label>
                                <div className="col-sm-9">
                                    <InputGroupSearch
                                        changeEvent={(selectedOption) => {

                                            this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_master_ids');
                                            this.setState({
                                                trainingSearchForm: { ...this.state.trainingSearchForm, search_master_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                searchedGroups: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        placeholder="Select Groups"
                                        value={this.state.searchedGroups.length > 0 ? this.state.searchedGroups.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row py-2 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Training  Tentative Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={this.state.all_periods ? this.state.all_periods : []}
                                        value={this.state.trainingSearchForm.date_range}
                                        startDate={this.state.trainingSearchForm.date_range_start}
                                        endDate={this.state.trainingSearchForm.date_range_end}
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            this.setState({
                                                trainingSearchForm
                                                    : {
                                                    ...this.state.trainingSearchForm,
                                                    date_range: period,
                                                    date_range_start: startDate ? startDate : null,
                                                    date_range_end: endDate ? endDate : null
                                                }
                                            });
                                        }}
                                        className="form-control-sm"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"trainingSearchForm"}>Search{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Training Listing</title></Helmet>
            {this.trainingJsx()}
            {this.addCommentModalJsx()}
            {this.updateTrainingModalJsx()}
            {this.trainingSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(TrainingList);