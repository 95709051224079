import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../components/ui/TapSelect';
import AppBaseComponent from '../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';


class SubModuleList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initAddModuleForm              =       {
            module_id           :       '',
            name                :       "",
            sub_module          :       "",
            under_license       :       "N"
        }
        
        this.state                           =     {
            
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            module_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            addModuleForm                       :       {...this.initAddModuleForm},
            editModule                          :       false,
            addModuleFormSubmitting             :       false,
            formDataLoaded                      :       false,
            form_loading                        :       [],
            modules                             :       [],
            agree                               :      'N',
            permissions                         :       []
        };
       
    }

    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
        this.loadListingTblData(1);
        this.addModuleModal                  =   new Modal(document.getElementById('addModuleModal'), {keyboard: false, backdrop :false});
      
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }

  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/submodule', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                module_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

   
    addModuleFormInit       =       (data)      =>      {
        this.addModuleModal.show();
        if(data){
            this.setState({
                addModuleForm                       :       {
                    id              :       data.id,
                    module_id           :   data.module.id,
                    name                :    data.name,
                    sub_module          :   data.sub_module,
                    under_license       :   data.under_license && data.under_license == 'Y' ? 'Y' :    "N"
                },
                editModule          :       true,
                agree               :       'N',
            })
        }else{
            this.setState({
                addModuleForm      :       {...this.initAddModuleForm},
                editModule         :       false,
                agree              :       'N',
            })
        }
        if(!this.state.formDataLoaded){
            this.getAddFormData()
        }
    }

    getAddFormData                 =   ()  =>         {
        this.setState({form_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/submodule/addForm', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({ 
                modules             :       respData.modules && respData.modules.length > 0 ? respData.modules.map(md => {return({value:md.id,label:md.name})}) :[],
                formDataLoaded      :       true
            });
           
        }).then(() => this.setState({form_loading: false}));
    }

    submitAddModuleForm     =       (e)     =>      {
        e.preventDefault();
        this.setState({ addModuleFormSubmitting: true });
       
        if (this.state.editModule) {
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/submodule/update', this.props.access_token, {}, { ...this.state.addModuleForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addModuleModal.hide();
                this.setState({ addModuleForm: { ...this.initAddModuleForm } });
                this.loadListingTblData(this.state.listing_tbl_page);
            }).then(() => this.setState({ addModuleFormSubmitting: false }));
        } else {
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH + '/backoffice/submodule/add', this.props.access_token, {}, { ...this.state.addModuleForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addModuleModal.hide();
                this.setState({ addModuleForm: { ...this.initAddModuleForm } });
                this.loadListingTblData(1);
            }).then(() => this.setState({ addModuleFormSubmitting: false }));
        }
        
    }

    moduleJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Sub Module List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                            {this.state.permissions.includes('sub_module_add') &&  <button type="button" className="btn btn-primary" onClick={() => this.addModuleFormInit()}>New</button>}
                           
                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Module :  </h3>
                          
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.moduleLisitngJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>test</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    moduleLisitngJsx            =   ()   =>   {
        return(<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                        <tr className='text-center'>
                            <th scope="col" style={{ width: "10%" }}>S.No</th>
                            <th scope="col" style={{ width: "20%" }}>Module</th>
                            <th scope="col" style={{ width: "30%" }}>Name</th>
                            <th scope="col" style={{ width: "20%" }}>Key</th>
                            <th scope="col" style={{ width: "10%" }}>Under License</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                        </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.module_listing && this.state.module_listing.length > 0
                            ? (this.state.module_listing.map((item, index) => {
                                return (<tr key={index} >
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index }</td>
                                    <td  className='text-capitalize'>{item.module && item.module.name ? item.module.name : ""}</td>
                                    <td  className='text-capitalize'>{item.name ? item.name : ""}</td>
                                    <td>{item.sub_module ? item.sub_module : ""}</td>
                                    <td className='text-center'>{item.under_license && item.under_license == 'Y' ? 'Yes' : 'No'}</td>
                                    <td className='text-center'>
                                    {this.state.permissions.includes('sub_module_edit') && <Ax>
                                       <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" onClick={() => this.addModuleFormInit(item)} role="button">Edit</a></li>
                                        </ul>
                                        </Ax>}
                                    </td>
                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    addModuleModalJsx                        =   ()  =>  {
        return (
            <div className="modal fade" id="addModuleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModuleModalLabel">{this.state.editModule ? "Edit" : "Add"} Sub Module</h5>
                        </div>
                        <form onSubmit={this.submitAddModuleForm} id="addTermsForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Module</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addModuleForm', 'module_id');
                                            }}
                                            options={this.state.modules}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            isDisabled={this.state.editModule}
                                            value={this.state.modules.find(s => s.value == this.state.addModuleForm.module_id)}
                                            placeholder="Select Module"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Sub Module Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => { this.formInputHandler(e, 'addModuleForm') }}
                                            value={this.state.addModuleForm.name}
                                            required
                                            placeholder="Enter Sub Module Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Sub Module Key</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="sub_module"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required
                                            placeholder="Enter Sub Module Key"
                                            onChange={(e) => { this.formInputHandler(e, 'addModuleForm') }}
                                            disabled={this.state.editModule ? true : false}
                                            value={this.state.addModuleForm.sub_module}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Under License</label>
                                    </div>
                                    <div className="col-sm-9">

                                        <div className="form-check form-check-inline">
                                            <input
                                                name="under_license"
                                                type="radio"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, 'addModuleForm')}
                                                checked={this.state.addModuleForm.under_license == "Y"}
                                                className="form-check-input"
                                                required={true}
                                                disabled={this.state.editModule}
                                                id="addModuleForm_yes"
                                            />
                                            <label className="form-check-label" htmlFor="addModuleForm_yes">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="under_license"
                                                type="radio"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, 'addModuleForm')}
                                                checked={this.state.addModuleForm.under_license == "N"}
                                                className="form-check-input"
                                                required={true}
                                                disabled={this.state.editModule}
                                                id="addModuleForm_no"
                                            />
                                            <label className="form-check-label" htmlFor="addModuleForm_no">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between  row">
                                <div className="col text-start">

                                    <input
                                        name="agree"
                                        type="checkbox"
                                        className="form-check-input"
                                        id="agree"
                                        required
                                        value={this.state.agree}
                                        onChange={(e) => {
                                            this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                        }}
                                        checked={this.state.agree == 'Y'}
                                    />
                                    <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                                </div>
                                <div class="col text-end">
                                    <button type="button"
                                        disabled={this.state.addModuleFormSubmitting}
                                        className="btn btn-secondary mx-2"
                                        data-bs-dismiss="modal"

                                    >Close</button>
                                    <button type="submit" disabled={this.state.addModuleFormSubmitting} className="btn btn-primary">
                                        Submit {this.state.addModuleFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Sub Module Listing</title></Helmet>
            {this.moduleJsx()}
           {this.addModuleModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(SubModuleList);