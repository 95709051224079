import React from "react";
import AppBaseComponent from "../../components/AppBaseComponent";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { connect } from "react-redux";
import ApplicationLayout from "../../layouts/ApplicationLayout";
import TapIcon from "../../services/TapIcon";
import { Modal } from "bootstrap";
import { Helmet } from "react-helmet";
import Ax from "../../components/hoc/Ax";
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import { DisplayListPagination } from "../../components/TapUi";
import Status from "../../components/ui/Status";
import { toast } from 'react-toastify';
import swal from "sweetalert";
import TapApiUrls from "../../services/TapApiUrls";
import TapSelect from "../../components/ui/TapSelect";

class IOTServiceProvider extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.iotUrl                 =   TapApiUrls.BACKOFFICE_URL + '/iot_vendor';
        this.formDataUrl            =   this.iotUrl + '/form_data';
        this.listUrl                =   this.iotUrl + '/list';
        this.statusUpdateUrl        =   this.iotUrl + '/status_update'; 
        this.updateUrl              =   this.iotUrl + '/update'; 
        this.addUrl                  =   this.iotUrl + '/add'; 
        this.detailUrl               =   this.iotUrl + '/detail/'; 

        this.updateForm             =   {
            name                        :   '',
            file                        :   '',
            base64_document             :   null,
            file_name                   :   '',
            description                 :   '', 
            logo                        :   null  
        }
        
        this.state                  =   {
            updateProfileForm           :   {...this.updateForm},
            formSubmit                  :   false,
            listing_loading             :   false,
            iot_listing                 :   [],
            listingMeta                 :   null,
            listing_tbl_page            :   1,
            totalListingCount           :   0,
            form_loading                :   false,
            statusData                  :   [],
            profile_id                  :   null,             
        };
        
    }

    componentDidMount() {
        this.initializeData(this.props);
        this.loadListingTblData(1);
        this.organizationUpdateModal    =   new Modal(document.getElementById('organizationUpdateModal'), {Keyboard: false, backdrop: false});
    }

    initializeData                      =   (pr)    =>  {
        HttpAPICall.withAthorization('GET',  this.formDataUrl, this.props.access_token, {}, {}, (resp) => {
           this.setState({
                statusData  :   resp.data && resp.data.status &&  resp.data.status.length > 0 ? resp.data.status.map(st => {return({value:st.key,label:st.status})}) : []
            });
        }, (err) => {});
    }

    loadListingTblData                  =   (page = 1)  =>      {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page};
        HttpAPICall.withAthorization('GET',  this.listUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =   resp.data;
            this.setState({
                iot_listing         :   respData.data,
                listingMeta         :   respData.meta,
                totalListingCount   :   respData.meta.total,
            });
        }).then(() => this.setState({listing_loading: false}));
    }

    
    updateStatus                =   (id,statusKey) => {
        const status            =   statusKey === 1 ? 2 : 1;      
        // listing_tbl_page: page
        const data              =   {id, status};

        swal({
            title: "Change Status",
            text: "Do you really want to change the status?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('PUT',  this.updateUrl, this.props.access_token, {}, {...data}, (resp) => {
                    toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.loadListingTblData(1);
                });
            }
        });

    }

    formDataSubmit              =   (e) =>  {
        e.preventDefault();
        this.setState({formSubmit  : true});
       
         if(this.state.profile_id !== null) {
            let data                =   {...this.state.updateProfileForm, id : this.state.profile_id};
            HttpAPICall.withAthorization('PUT', this.updateUrl, this.props.access_token, {}, data, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.loadListingTblData(1);
                this.organizationUpdateModal.hide();
            }).then(() => this.setState({formSubmit : false}));

        }else {
            HttpAPICall.withAthorization('POST',  this.addUrl, this.props.access_token, {}, {...this.state.updateProfileForm}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.loadListingTblData(1);
                this.organizationUpdateModal.hide();
            }).then(() => this.setState({formSubmit : false}));
        }

    }  

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        console.log(file,'file')
        let filename = file.name
        const base64_document = await this.convertBase64(file);
        this.setState({updateProfileForm : { ...this.state.updateProfileForm ,base64_document : base64_document,file_name:filename}})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    updateProfileInit           =   (action, id = null)   =>  {
        if(this.props.permissions.includes('iot-vendor-add') || this.props.permissions.includes('iot-vendor-edit')){
            if(action === "update" && id !== null && this.props.permissions.includes('iot-vendor-edit')) {
                HttpAPICall.withAthorization('GET',  this.detailUrl + id, this.props.access_token, {}, {}, (resp) => {
                    const respData = resp.data.data;
                    this.setState({
                        updateProfileForm   :   {...this.state.updateProfileForm,
                             name: respData.name,
                              description: respData.description,
                              logo : respData.logo && respData.logo.file ? respData.logo.file : null, 
                            },
                        profile_id          :   id,
                    }, () => {
                        this.organizationUpdateModal.show();
                    });
                });
            }else{
                this.setState({
                    updateProfileForm   :   {...this.updateForm},
                    profile_id          :   null,
                });
                if(document.getElementById("organizationUpdateForm")) {
                    let form = document.getElementById("organizationUpdateForm")
                    form.reset();
                }
                this.organizationUpdateModal.show();
            }
        }else{
            toast.error('You dont have permission to perform this action')
        }
        
    }

    organizationUpdateModalJsx  =   ()  =>  {
       
        return (
            <div className="modal fade" id="organizationUpdateModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Profile</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="organizationUpdateForm" onSubmit={this.formDataSubmit}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label">Name</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.updateProfileForm.name}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'updateProfileForm')}
                                            placeholder="Please enter Name"
                                        />
                                    </div>
 
                                </div>
                                {!this.state.profile_id && <div className="row align-items-center my-3">
                                   <label htmlFor="first_name" className="col-sm-2 col-form-label require">Status</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'updateProfileForm', 'status')}
                                            options={this.state.statusData}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            value={this.state.statusData.find(s => this.state.updateProfileForm.status == s.value)}
                                            placeholder={"Please Select Status"}
                                        />
                                    </div>
                                </div>}
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label">Detail</label>
                                    <div className="col-sm-10 iot">
                                        <CKEditor
                                            data={this.state.updateProfileForm.description}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                // this.state.description = editor.getData();
                                                this.setState({ updateProfileForm: { ...this.state.updateProfileForm, description: editor.getData() } })
                                            }}

                                        />
                                        
                                    </div>
                                </div>
                               
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label">Logo</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="logo"
                                            type="file"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => { this.handleFileChange(e)}}
                                            placeholder="Please upload file"     
                                        />
                                    </div>
                                    {this.state.updateProfileForm && this.state.updateProfileForm.logo  && <div className="offset-2 col-sm-10 mt-3">
                                        <img src={this.state.updateProfileForm.logo} alt="logo_img" style={{ maxHeight: "90px",maxWidth:"90px" }} /> 
                                    </div>}
                                </div>
                            </div> 
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.formSubmit ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.formSubmit ? true : false}>Save 
                                {this.state.formSubmit && 
                                    <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />
                                }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    
    iotServiceProviderTblJsx    =   ()  => {
        return (
            <Ax>
                <table className="table table-bordered table-fixed  bg-white table-sm">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th scope="col" style={{ width: "8%" }}>S.No</th>
                            <th scope="col">Name</th>
                            <th scope="col" style={{ width: "25%" }} >Detail</th>
                            <th scope="col" style={{ width: "15%" }}>Logo</th>
                            <th scope="col" style={{ width: "8%" }}>Status</th>
                            <th scope="col" style={{ width: "8%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.listing_loading 
                        ?
                        <tr><td className="text-center" colSpan="6"><Loader/></td></tr>  
                        :
                        this.state.iot_listing.length > 0 
                        ? 
                        this.state.iot_listing.map((data,i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center">{++i}</td>
                                    <td className="text-center">{data.name}</td>
                                    <td className="text-center">{data.description}</td>
                                    <td className="text-center">{data.logo && data.logo.file && <img src={data.logo.file} alt="logo_img" style={{ maxHeight: "50px" }} /> }</td>
                                    <td className="text-center">
                                        <Status color={data.status.color_code}>{data.status.status}</Status>
                                    </td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit("update", data.id)}>Edit</a></li>
                                            {this.props.permissions.includes('iot-vendor-edit') && <li><a className="dropdown-item" role="button" onClick={() => this.updateStatus(data.id, data.status.key)}>Change Status</a></li>}
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })
                        : <tr><td className="text-center" colSpan={6}>No Records</td></tr>
                    }
                    </tbody>
                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                />
                {this.organizationUpdateModalJsx()}
            </Ax>
            )
    }

    render() {
        return (
            <ApplicationLayout>
            <Helmet><title>IOT Service Provider</title></Helmet>
            <Ax>
                <div className="page_title row m0">
                <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                    <h3>IoT Vendors</h3>
                    <div className="text-end mt15">
                       {this.props.permissions.includes('iot-vendor-add') && <button type="button" className="btn btn-primary" onClick={() => this.updateProfileInit("add")}>Add</button>}
                    </div>
                </div>
                </div>
            </Ax>
            <div className="container-fluid pl5 mt-2">
                {this.iotServiceProviderTblJsx()}
            </div>
            </ApplicationLayout>
        )
    } 

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :    state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(IOTServiceProvider);