import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import swal from "sweetalert";
import { Modal } from 'bootstrap';
import renderHTML from 'react-render-html'
import TapSelect from '../../../components/ui/TapSelect';
import InputUserSearch from '../../includes/ui/InputUserSearch';



class MomListing extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initCommentForm            =       {
            mom_meeting_id             :            '',
            notes                 :           ''
        }

        this.meetingSearchInitState         =   {
            'search_user_ids'           :       [],
            'search_present_member'     :       '',
            'search_title'              :       '',
            search_mom_company_ids      :       [],
            date_range                  :       null,
            date_range_start            :       null,
            date_range_end              :       null,
            created_date_range          :       null,
            created_date_range_start    :       null,
            created_date_range_end      :       null,
        };

        this.meetingSearchFormLabel         =   {
            'search_mom_company_ids'          :   'Companies : ',
            'search_user_ids'                 :   'Created By : ',
            'search_present_member'     :       'Present Members : ',
            'search_title'              :       'Title : ',
            'date_range'                    :   'M.O.M Period : ',
            'created_date_range'            :   'Created At : ',
            
        };
       
        this.state                           =     {
            minimizeTable                :       false,
            listing_loading              :       false,
            listing_tbl_page             :       1,
            mom_listing                  :       [],
            listingMeta                  :       null,
            totalListingCount            :       0,
            meetingData                 :       null,
            addCommentForm               :       {...this.initCommentForm},
            saveFormSubmitting          :       false,
            meetingSearchForm              :       {...this.meetingSearchInitState},
           submittedMeetingSearchForm      :       {...this.meetingSearchInitState},
           formSearchedElems               :       [],
           allCompanyList                    :       [],
           all_periods                      :       [],
           searchedUsers                    :   [],
        };
       
    }

    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);  
        this.loadListingTblData(1);
        this.getFormData();
        this.addCommentModal         =   new Modal(document.getElementById('addCommentModal'), {keyboard: false, backdrop :false});
        this.meetingSearchModal     =    new Modal(document.getElementById('meetingSearchModal'), {keyboard: false, backdrop :false}); 
    }
    
    initalizeComponent      =       (pr)            =>      {
        
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
            //all_periods     :        all_periods ,
        })
    }

    getFormData        =       ()      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH  + '/backoffice/mom/form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
            let all_periods             =     respData && respData.periods && respData.periods.length > 0 ?  [...respData.periods , {key : 'custom' , display : 'Custom Date Range'}] : [] ;
            this.setState({
                formDataLoaded              :   true,
                allCompanyList             : respData && respData.companies && respData.companies.length > 0 ? respData.companies.map(tp => {return({value:tp.id,label:tp.name})}) : [],
                all_periods                :   all_periods
            },() => {
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50,...this.state.submittedMeetingSearchForm};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/mom/list', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                mom_listing    :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
            });
        }).then(() => this.setState({listing_loading: false}));
    }   
    
    viewMeetingDetail        =       (id)        =>      {
        if(this.props.permissions.includes('mom-view')){
            this.setState({
                minimizeTable : true
            })
    
            this.getMeetingDetail(id)
        }else{
            toast.error('You dont have permission to perform this action')
        }
        
    }

    getMeetingDetail           =       (id)      =>      {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_SERVER_PATH + '/backoffice/mom/detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            
            this.setState({
                meetingData        :   respData.data
            });

        }).then(() => this.setState({ view_loading: false }));
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    deleteMeetingInit      =       ()        =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
            
                HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_SERVER_PATH + '/backoffice/mom/delete/' + this.state?.meetingData?.id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.setState({minimizeTable : false});
                    this.loadListingTblData(1);
                }).then(() => { });
            }
        });
    }

    addCommentModalInit     =       ()      =>      {
        this.addCommentModal.show();
        this.setState({
            addCommentForm : {
                ...this.initCommentForm,
                mom_meeting_id     :       this.state?.meetingData?.id,
            }
        })
    }

    submitCommentForm      =       (e)         =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('POST', process.env.REACT_APP_SERVER_PATH  + '/backoffice/mom/comment/add', this.props.access_token, {}, { ...this.state.addCommentForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.setState({addCommentForm:{...this.initCommentForm}})
           this.addCommentModal.hide();
           this.getMeetingDetail(this.state?.meetingData?.id);
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }

    addMeetingFormInit         =       ()      =>      {
        this.props.history.push('/meeting_add')
    }

    editMeetingInit        =       ()      =>      {
        this.props.history.push({ pathname: `/meeting_add`, state: { editMeetingData : this.state.meetingData } });
    }

    meetingSearchModalInit         =       ()      =>      {
        this.meetingSearchModal.show(); 
    }

    meetingSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.meetingSearchForm);
        let searchedElems               =   [];
        let meetingSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.meetingSearchFormLabel[key];
            let value                       =   this.state.meetingSearchForm[key];
            if(value && value.toString().length > 0) {
                meetingSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                   
                     if(key == 'search_title') {
                        show_val            =   this.state.meetingSearchForm.search_title ? this.state.meetingSearchForm.search_title : "";
                    }
                    if(key == 'search_present_member') {
                        show_val            =   this.state.meetingSearchForm.search_present_member ? this.state.meetingSearchForm.search_present_member : "";
                    }
                    
                    if(key == 'search_user_ids') {
                        show_val            =   this.state.searchedUsers ? this.state.searchedUsers.map(s => s.label).join(', ') : '';
                    }

                    if(key == 'search_mom_company_ids') {
                        show_val            =   this.state.allCompanyList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.meetingSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.meetingSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                     if(key == 'created_date_range') {
                        let  start_range = moment(this.state.meetingSearchForm.created_date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.meetingSearchForm.created_date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedMeetingSearchForm         :   {...this.state.meetingSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.meetingSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    meetingSearchClear         =       ()     =>      {
        this.setState({
            meetingSearchForm                :   {...this.meetingSearchInitState},
            submittedMeetingSearchForm       :   {...this.meetingSearchInitState},
            formSearchedElems                 :   [],
        searchedUsers                   :       []
        }, () => {
            this.meetingSearchModal.hide();
            this.loadListingTblData(1)
        });
    }
  
    meetingJsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>M.O.M with Clients List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>{this.props.permissions.includes('mom-add') && <button type="button" className="btn btn-primary" onClick={() => this.addMeetingFormInit()}>New</button>}
                            <button type="button" disabled={this.state.listing_loading} onClick={this.meetingSearchModalInit} className="btn btn-secondary" >
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button> </Ax> : null}
                </div>

            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>M.O.M Detail </h3>
                            <div className="text-end mt15">
                            {this.props.permissions.includes('mom-add-comment') && <button type="button" className="btn btn-secondary" onClick={() => this.addCommentModalInit()}>Add Comment</button>}
                            {this.props.permissions.includes('mom-edit') && <button type="button" className="btn btn-secondary" onClick={() => this.editMeetingInit()}>
                                   Edit
                                </button>}
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>

                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.meetingListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <div className='text-center'><Loader /></div> : this.meetingViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    meetingListingJsx            =   ()   =>   {
        return (<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.meetingSearchClear} />
           <table className="table table-bordered table-fixed  bg-white table-sm">

                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Meeting</th>
                        </tr>)
                        : (
                            <tr className='text-center'>
                                <th scope="col" style={{ width: "5%" }} >S.No</th>
                                <th scope="col" style={{ width: "13%" }}>Date of Meeting</th>
                                <th scope="col" style={{ width: "21%" }}>Title</th>
                                <th scope="col" style={{ width: "20%" }}>Group Name</th>
                                <th scope="col" style={{ width: "20%" }}>Members Present</th>
                                <th scope="col" style={{ width: "8%" }}>Action</th>

                            </tr>)}
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.mom_listing.length > 0
                            ? (this.state.mom_listing.map((meet, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewMeetingDetail(meet.id)}>{meet.title ? meet.title : ""}</a>
                                                        <div className="text-end">
                                                            <small>{meet.meeting_at_display ? meet.meeting_at_display : ""}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td>{meet.meeting_at_display ? meet.meeting_at_display : ""}</td>
                                                <td  className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewMeetingDetail(meet.id)}>{meet.title ? meet.title : ""}</td>
                                                <td>{meet.company  ? meet.company.name : "-"}</td>
                                                <td>{meet.present_member && meet.present_member.length > 0 ? meet.present_member.map(fb => {return(fb)}).join(", ") : ""}</td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewMeetingDetail(meet.id)}>View</a></li>
                                                    </ul>
                                                </td>

                                            </Ax>)}

                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

            </table>
           
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    meetingViewDetails       =       ()      =>      {
        let detail = this.state.meetingData;
        return (<div className="tab_content_header">
            {detail
                ? <Ax>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                        <tbody>
                        <tr>
                                <td style={{ width: "26%" }} >Date of Meeting</td>
                                <th>{detail.meeting_at_display ? detail.meeting_at_display : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Title</td>
                                <th>{detail.title ? detail.title : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Group Name</td>
                                <th>{detail.company ? detail.company.name : "-"}</th>

                            </tr>
                            
                            <tr>
                                <td style={{ width: "26%" }} >Members Present</td>
                                <th>{detail.present_member && detail.present_member.length > 0 ? detail.present_member.map(f => {return(f)}).join(', ') : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Created By</td>
                                <th>{detail.createdBy ? detail.createdBy.name : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Created At</td>
                                <th>{detail.created_at_display ? detail.created_at_display : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Notes</td>
                                <th>{detail.notes ? renderHTML(detail.notes) : "-"}</th>
                            </tr>
                          
                           
                        </tbody>
                    </table>
                    { detail.comments && detail.comments.length > 0 && <Ax>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Comments</span></div>
                        
                        </Ax>}
                   
                        <div className="my-2">
                            {
                                detail.comments && detail.comments.length > 0 ? detail.comments.map((c, k) => {
                                    return (<Ax key={k}><div className="row">
                                        <div className='col-sm-8'>{c.created_by && c.created_by.name} added Comment  </div> 
                                        <div className="col-sm-4 text-end">
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                            <span> {c.created_date_display}</span>
                                        </div>
                                        <div className="col-sm-8 my-1">{c.notes}</div>
                                    </div><hr /></Ax>);
                                })
                                    : null
                            }
                        </div>
                
                </Ax>
                : null}
        </div>)
    }

    addCommentModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"addCommentModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Add Comment</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitCommentForm} id={"addCommentForm"}>
                            
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Comment </label>
                                <div className="col-sm-9">
                                    <textarea
                                        name="notes"
                                        value={this.state.addCommentForm.notes}
                                        onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                        className="form-control"
                                        style={{ height: "100px" }}
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"addCommentForm"}>Submit{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    meetingSearchModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"meetingSearchModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Search</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.meetingSearchFormSubmit} id={"meetingSearchForm"}>

                            <div className="row align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Title</label>
                                <div className="col-sm-9">
                                    <input
                                        name="search_title"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Title"
                                        onChange={(e) => { this.formInputHandler(e, 'meetingSearchForm') }}
                                        value={this.state.meetingSearchForm.search_title}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Present Member</label>
                                <div className="col-sm-9">
                                    <input
                                        name="search_present_member"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Present Member"
                                        onChange={(e) => { this.formInputHandler(e, 'meetingSearchForm') }}
                                        value={this.state.meetingSearchForm.search_present_member}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Companies</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allCompanyList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'meetingSearchForm', 'search_mom_company_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allCompanyList.filter(s => this.state.meetingSearchForm.search_mom_company_ids.includes(s.value))}
                                        placeholder="Companies"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>

                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Created by</label>
                                <div className="col-sm-9">
                                    <InputUserSearch
                                        changeEvent={(selectedOption) => {

                                            this.tapSelectChange(selectedOption, 'meetingSearchForm', 'search_user_ids');
                                            this.setState({
                                                meetingSearchForm: { ...this.state.meetingSearchForm, search_user_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                searchedUsers: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        placeholder="Select Created By"
                                        value={this.state.searchedUsers.length > 0 ? this.state.searchedUsers.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>

                            <div className="row py-2 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Meeting Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={this.state.all_periods ? this.state.all_periods : []}
                                        value={this.state.meetingSearchForm.date_range}
                                        startDate={this.state.meetingSearchForm.date_range_start}
                                        endDate={this.state.meetingSearchForm.date_range_end}
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            this.setState({
                                                meetingSearchForm
                                                    : {
                                                    ...this.state.meetingSearchForm,
                                                    date_range: period,
                                                    date_range_start: startDate ? startDate : null,
                                                    date_range_end: endDate ? endDate : null
                                                }
                                            });
                                        }}
                                        className="form-control-sm"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row py-2 align-items-center">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Meeting Created At Period</label>
                                <div className="col-sm-9">
                                    <PeriodSelectionWithCustom
                                        periods={this.state.all_periods ? this.state.all_periods : []}
                                        value={this.state.meetingSearchForm.created_date_range}
                                        startDate={this.state.meetingSearchForm.created_date_range_start}
                                        endDate={this.state.meetingSearchForm.created_date_range_end}
                                        onSelectPeriod={(period, startDate, endDate) => {
                                            this.setState({
                                                meetingSearchForm
                                                    : {
                                                    ...this.state.meetingSearchForm,
                                                    created_date_range: period,
                                                    created_date_range_start: startDate ? startDate : null,
                                                    created_date_range_end: endDate ? endDate : null
                                                }
                                            });
                                        }}
                                        className="form-control-sm"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"meetingSearchForm"}>Search{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>MOM with Client Listing</title></Helmet>
            {this.meetingJsx()}
            {this.addCommentModalJsx()}
            {this.meetingSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(MomListing);