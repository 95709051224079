import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../components/ui/TapSelect';
import AppBaseComponent from '../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import Status from "../../components/ui/Status";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";


class GroupList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initaddGroupForm              =       {
            group_id                :       '',
            group                   :       '',
            group_label             :       '',
            module_id               :       '',
            sub_module_id           :       '',
            transaction_locking     :       'N'
        }

        this.initIamUpdateLicenseForm      =       {
            master_id               :           '',
            licence_to              :           '',
            new_licence_to          :           new Date(),
            group_name              :           ''
        }

        this.initIamUpdateCountForm      =       {
            master_id               :           '',
            group_name              :           '',
            max_asset_new           :           '',
            max_user_new           :           '',
            max_site_new           :           '',
        }

        this.initIamAddSubmoduleForm      =       {
            master_id               :           '',
            till                    :           '',
            new_till                :           new Date(),
            group_name              :           '',
            sub_module_id           :           ''
        }

        this.initIamRevokeSubmoduleForm      =       {
            master_id               :           '',
            till                    :           '',
            group_name              :           '',
            sub_module_id           :           ''
        }

        this.initHrmUpdateLicenseForm      =       {
            master_id               :           '',
            licence_to              :           '',
            new_licence_to          :           new Date(),
            new_status              :           '',
            group_name              :           ''
        }

        this.initHrmUpdateCountForm      =       {
            master_id               :           '',
            group_name              :           '',
            max_users_new            :           '',
        }

        this.initIscUpdateLicenseForm      =       {
            master_id               :           '',
            licence_to              :           '',
            new_licence_to          :           new Date(),
            new_status              :           '',
            group_name              :           ''
        }

        this.initIscUpdateCountForm      =       {
            master_id               :           '',
            group_name              :           '',
            max_warehouse_new       :           '',
            max_items_new           :           '',
        }
        
        this.state                           =     {
            
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            group_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            addGroupForm                       :       {...this.initaddGroupForm},
            editModule                          :       false,
            updateIamLicenseFormSubmitting             :       false,
            detail_loading                      :       false,
            groupDetail                         :       null,
            updateIamLicenseForm                :       {...this.initIamUpdateLicenseForm},
            iamLicenceDetail                    :       null,
            updateIamLicenseFormSubmitting      :       false,
            updateIamCountForm                  :       {...this.initIamUpdateCountForm},
            updateIamCountFormSubmitting        :       false,
            addIamSubmoduleForm                 :       {...this.initIamAddSubmoduleForm},
            addIamSubmoduleFormSubmitting       :       false,
            form_data_loading                   :       false,
            allSubModuleList                    :       [],
            allGroupLicencedModuleList          :       [],
            revokeIamSubmoduleForm              :       {...this.initIamRevokeSubmoduleForm},
            revokeIamSubmoduleFormSubmitting    :       false,
            allLicenceStatus                    :       [{value: 1, label: 'Active'}, {value: 2, label: 'Inactive'}, {value: 17, label: 'Trial'}],
            updateHrmLicenseForm                :       {...this.initHrmUpdateLicenseForm},
            hrmLicenceDetail                    :       null,
            updateHrmLicenseFormSubmitting      :       false,
            updateHrmCountForm                  :       {...this.initHrmUpdateCountForm},
            updateHrmCountFormSubmitting        :       false,
            currentModule                       :       '',
            updateIscLicenseForm                :       {...this.initIscUpdateLicenseForm},
            iscLicenceDetail                    :       null,
            updateIscLicenseFormSubmitting      :       false,
            updateIscCountForm                  :       {...this.initIscUpdateCountForm},
            updateIscCountFormSubmitting        :       false,
            agree                               :       'N',
            permissions                         :       []
        };
       
    }

    componentDidMount() {
        this.initalizeComponent(this.props);
        this.loadListingTblData(1);
        this.updateIamLicenseModal          =   new Modal(document.getElementById('updateIamLicenseModal'), {keyboard: false, backdrop :false});
        this.updateIamCountModal            =   new Modal(document.getElementById('updateIamCountModal'), {keyboard: false, backdrop :false});
        this.addIamSubModuleModal           =   new Modal(document.getElementById('addIamSubModuleModal'), {keyboard: false, backdrop :false});
        this.revokeIamSubModuleModal        =   new Modal(document.getElementById('revokeIamSubModuleModal'), {keyboard: false, backdrop :false});
        this.updateHrmLicenseModal          =   new Modal(document.getElementById('updateHrmLicenseModal'), {keyboard: false, backdrop :false});
        this.updateHrmCountModal            =   new Modal(document.getElementById('updateHrmCountModal'), {keyboard: false, backdrop :false});
        this.updateIscLicenseModal          =   new Modal(document.getElementById('updateIscLicenseModal'), {keyboard: false, backdrop :false});
        this.updateIscCountModal            =   new Modal(document.getElementById('updateIscCountModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.permissions !== this.props.permissions){
            this.initalizeComponent(nextProps);
        }
    }

    initalizeComponent      =       (pr)            =>      {
        this.setState({
            permissions     :       pr.permissions && pr.permissions.length > 0 ? pr.permissions : [],
        })
    }
  
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:50};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/group/listing', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
           
            this.setState({
                group_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

   
    addGroupFormInit       =       ()      =>      {
        window.open('https://old.tappetbox.in/signup', '_blank');
    }

    viewGroupDetail         =       (id)      =>      {
        
        if(this.state.permissions.includes('group-license-view')){
            this.setState({
                minimizeTable   :   true
            })
            this.getGroupDetail(id)
        }else{
            toast.info("Sorry You dont have permission", { position: toast.POSITION.TOP_RIGHT });
        }
        
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable:false})
    }

    getGroupDetail                   =      (id)    =>       {
        this.setState({detail_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_SERVER_PATH + '/backoffice/group/detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            let detail              =       respData && respData.data ? respData.data : null;
            let iam_licence_detail  =       detail && detail.iam_licence ? detail.iam_licence : null;
            let hrm_licence_detail  =       detail && detail.hrm_licence ? detail.hrm_licence : null;
            let isc_licence_detail  =       detail && detail.isc_licence ? detail.isc_licence : null;

            this.setState({
                groupDetail         :       respData.data,
                iamLicenceDetail    :       iam_licence_detail,
                hrmLicenceDetail    :       hrm_licence_detail,
                iscLicenceDetail    :       isc_licence_detail
            })
        }).then(() => this.setState({detail_loading: false}));
    }

    updateIamLicenseFormInit        =       ()      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let iam_licence_detail  =   detail && detail.iam_licence ? detail.iam_licence : null;
       
        if(detail){
            this.updateIamLicenseModal.show();
            this.setState({
                updateIamLicenseForm        :       {
                    master_id               :           detail.master_id,
                    licence_to              :           iam_licence_detail ? iam_licence_detail.licence_to : '',
                    new_licence_to          :           new Date(),
                    group_name              :           detail.group_name,
                },
                agree                       :           'N'
            })
        }
    }

    submitUpdateIamLicenseForm               =      (e)     =>      {
        e.preventDefault();
        this.setState({ updateIamLicenseFormSubmitting: true });
     
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/group/iam_expiry_date', this.props.access_token, {}, { ...this.state.updateIamLicenseForm,new_licence_to: moment(this.state.updateIamLicenseForm.new_licence_to, 'YYYY-MM-DD').format('YYYY-MM-DD') }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateIamLicenseModal.hide();
            this.setState({ updateIamLicenseForm: { ...this.initIamUpdateLicenseForm } });
            this.loadListingTblData(1);
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ updateIamLicenseFormSubmitting: false }));  
    }

    updateIamCountFormInit        =       ()      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let iam_licence_detail  =   detail && detail.iam_licence ? detail.iam_licence : null;
       
        if(detail){
            this.updateIamCountModal.show();
            this.setState({
                updateIamCountForm        :       {...this.initIamUpdateCountForm,
                    master_id               :           detail.master_id,
                    group_name              :           detail.group_name
                },
                agree                       :           'N'
            })
        }
    }

    submitUpdateIamCountForm               =      (e)     =>      {
        e.preventDefault();
        this.setState({ updateIamCountFormSubmitting: true });
       
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/group/iam_max_limit', this.props.access_token, {}, { ...this.state.updateIamCountForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateIamCountModal.hide();
            this.setState({ updateIamCountForm: { ...this.initIamUpdateCountForm } });
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ updateIamCountFormSubmitting: false }));  
    }

    addIamSubmoduleFormInit        =       (current_module='')      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
       
        this.addIamSubModuleModal.show();
        this.getSubModuleList(detail.master_id,current_module)
        this.setState({
            addIamSubmoduleForm        :       {...this.initIamAddSubmoduleForm,
                master_id               :           detail.master_id,
                group_name              :           detail.group_name
            },
            currentModule               :       current_module,
            agree                       :           'N'
        })
       
    }

    getSubModuleList                   =      (id,current_module='')    =>       {
        this.setState({form_data_loading: true});
        let url = '';
        if(current_module == 'iam'){
            url = process.env.REACT_APP_SERVER_PATH + '/backoffice/group/add_submodule_form_data/'
        }else if(current_module == 'hrm'){
            url = process.env.REACT_APP_SERVER_PATH +  '/backoffice/hrm/sub_module_frm_data/'
        }else if(current_module == 'isc'){
            url = process.env.REACT_APP_SERVER_PATH +  '/backoffice/isc/sub_module_frm_data/'
        }

        HttpAPICall.withAthorization('GET',  url + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           
            let all_liceinced_sub_modules   =   respData.all_liceinced_sub_modules && respData.all_liceinced_sub_modules.length > 0 ? respData.all_liceinced_sub_modules : [] ;
            let group_licensed_active_submodules   =   respData.group_licensed_active_submodules && respData.group_licensed_active_submodules.length > 0 ? respData.group_licensed_active_submodules : [] ;
            let activeSubModules                =       [];
            if(group_licensed_active_submodules && group_licensed_active_submodules.length > 0){
                activeSubModules            =   group_licensed_active_submodules.map((asm) => {
                    asm.data = all_liceinced_sub_modules.find((lsm) => lsm.id == asm.sub_module_id);
                    return asm;
                });
            }
          
            this.setState({
                allSubModuleList              :  all_liceinced_sub_modules && all_liceinced_sub_modules.length > 0 ? all_liceinced_sub_modules.map(sm => {return({value:sm.id,label:`${sm.name} (${sm.sub_module})`})}) : [],
                allGroupLicencedModuleList    :  activeSubModules && activeSubModules.length > 0 ? activeSubModules.map(ac => ({value : ac.sub_module_id,label:`${ac.data.name} (${ac.data.sub_module})`,till:ac.till}))     :   []
            });
           
        }).then(() => this.setState({form_data_loading: false}));
    }

    submitAddIamSubmodulesForm              =      (e)     =>      {
        e.preventDefault();
        this.setState({ addIamSubmoduleFormSubmitting: true });
        let url = '';
        if(this.state.currentModule == 'iam'){
            url =  process.env.REACT_APP_SERVER_PATH + '/backoffice/group/add_submodule'
        }else if(this.state.currentModule == 'hrm'){
            url =  process.env.REACT_APP_SERVER_PATH + '/backoffice/hrm/submodule'
        }else if(this.state.currentModule == 'isc'){
            url =  process.env.REACT_APP_SERVER_PATH + '/backoffice/isc/submodule'
        }

        HttpAPICall.withAthorization('POST',url, this.props.access_token, {}, { ...this.state.addIamSubmoduleForm,new_till: moment(this.state.addIamSubmoduleForm.new_till, 'YYYY-MM-DD').format('YYYY-MM-DD') }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.addIamSubModuleModal.hide();
            this.setState({ addIamSubmoduleForm: { ...this.initIamAddSubmoduleForm } });
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ addIamSubmoduleFormSubmitting: false }));  
    }
    
    revokeIamSubmoduleFormInit        =       (current_module='')      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        
        this.revokeIamSubModuleModal.show();
        this.getSubModuleList(detail.master_id,current_module)
        this.setState({
            revokeIamSubmoduleForm        :       {...this.initIamRevokeSubmoduleForm,
                master_id               :           detail.master_id,
                group_name              :           detail.group_name
            },
            currentModule           :       current_module,
            agree                       :           'N'
        })
       
    }
    
    submitRevokeIamSubmodulesForm              =      (e)     =>      {
        e.preventDefault();
        this.setState({ revokeIamSubmoduleFormSubmitting: true });
        let url = '';
        if(this.state.currentModule == 'iam'){
            url =  process.env.REACT_APP_SERVER_PATH + '/backoffice/group/submodule'
        }else if(this.state.currentModule == 'hrm'){
            url =  process.env.REACT_APP_SERVER_PATH + '/backoffice/hrm/submodule'
        }else if(this.state.currentModule == 'isc'){
            url =  process.env.REACT_APP_SERVER_PATH + '/backoffice/isc/submodule'
        }

        HttpAPICall.withAthorization('DELETE',url , this.props.access_token, {}, { ...this.state.revokeIamSubmoduleForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.revokeIamSubModuleModal.hide();
            this.setState({ revokeIamSubmoduleForm: { ...this.initIamRevokeSubmoduleForm } });
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ revokeIamSubmoduleFormSubmitting: false }));  
    }

    updateHrmLicenseFormInit        =       ()      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let hrm_licence_detail  =   detail && detail.hrm_licence ? detail.hrm_licence : null;
       
        if(detail){
            this.updateHrmLicenseModal.show();
            this.setState({
                updateHrmLicenseForm        :       {
                    master_id               :           detail.master_id,
                    licence_to              :           hrm_licence_detail ? hrm_licence_detail.active_till : '',
                    new_licence_to          :           new Date(),
                    new_status              :           hrm_licence_detail ? hrm_licence_detail.status : '',
                    group_name              :           detail.group_name
                },
                agree                       :           'N'
            })
        }
    }

    submitUpdateHrmLicenseForm               =      (e)     =>      {
        e.preventDefault();
        this.setState({ updateHrmLicenseFormSubmitting: true });
       
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/hrm/update_license', this.props.access_token, {}, { ...this.state.updateHrmLicenseForm,new_licence_to: moment(this.state.updateHrmLicenseForm.new_licence_to, 'YYYY-MM-DD').format('YYYY-MM-DD') }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateHrmLicenseModal.hide();
            this.setState({ updateHrmLicenseForm: { ...this.initHrmUpdateLicenseForm } });
            this.loadListingTblData(1);
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ updateHrmLicenseFormSubmitting: false }));  
    }

    updateHrmCountFormInit        =       ()      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let hrm_licence_detail  =   detail && detail.hrm_licence ? detail.hrm_licence : null;
       
        if(detail){
            this.updateHrmCountModal.show();
            this.setState({
                updateHrmCountForm        :       {...this.initHrmUpdateCountForm,
                    master_id               :           detail.master_id,
                    group_name              :           detail.group_name
                },
                agree                       :           'N'
            })
        }
    }

    submitUpdateHrmCountForm               =      (e)     =>      {
        e.preventDefault();
        this.setState({ updateHrmCountFormSubmitting: true });
       
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/hrm/group_max_limit', this.props.access_token, {}, { ...this.state.updateHrmCountForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateHrmCountModal.hide();
            this.setState({ updateHrmCountForm: { ...this.initHrmUpdateCountForm } });
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ updateHrmCountFormSubmitting: false }));  
    }

    updateIscLicenseFormInit        =       ()      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let isc_licence_detail  =   detail && detail.isc_licence ? detail.isc_licence : null;
       
        if(detail){
            this.updateIscLicenseModal.show();
            this.setState({
                updateIscLicenseForm        :       {
                    master_id               :           detail.master_id,
                    licence_to              :           isc_licence_detail ? isc_licence_detail.active_till : '',
                    new_licence_to          :           new Date(),
                    new_status              :           isc_licence_detail ? isc_licence_detail.status :'',
                    group_name              :           detail.group_name
                },
                agree                       :           'N'
            })
        }
    }

    submitUpdateIscLicenseForm               =      (e)     =>      {
        e.preventDefault();
        this.setState({ updateIscLicenseFormSubmitting: true });
       
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/isc/update_license', this.props.access_token, {}, { ...this.state.updateIscLicenseForm,new_licence_to: moment(this.state.updateIscLicenseForm.new_licence_to, 'YYYY-MM-DD').format('YYYY-MM-DD') }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateIscLicenseModal.hide();
            this.setState({ updateIscLicenseForm: { ...this.initIscUpdateLicenseForm } });
            this.loadListingTblData(1);
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ updateIscLicenseFormSubmitting: false }));  
    }

    updateIscCountFormInit        =       ()      =>          {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        
        if(detail){
            this.updateIscCountModal.show();
            this.setState({
                updateIscCountForm        :       {...this.initIscUpdateCountForm,
                    master_id               :           detail.master_id,
                    group_name              :           detail.group_name
                },
                agree                       :           'N'
            })
        }
    }

    submitUpdateIscCountForm               =      (e)     =>      {
        e.preventDefault();
        this.setState({ updateIscCountFormSubmitting: true });
       
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_SERVER_PATH + '/backoffice/isc/group_max_limit', this.props.access_token, {}, { ...this.state.updateIscCountForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateIscCountModal.hide();
            this.setState({ updateIscCountForm: { ...this.initIscUpdateCountForm } });
            this.getGroupDetail(this.state.groupDetail.master_id)
        }).then(() => this.setState({ updateIscCountFormSubmitting: false }));  
    }

    groupJsx                         =      ()      =>      {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Group List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                           {this.state.permissions.includes('group_listing') && <button type="button" className="btn btn-primary" onClick={() => this.addGroupFormInit()}>New</button>}

                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Group :  {this.state.groupDetail && this.state.groupDetail.group_name ? this.state.groupDetail.group_name : "-"}</h3>
                            <div className="text-end mt15">
                            <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                                </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.groupListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.groupDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    groupListingJsx                  =      ()      =>      {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                       {this.state.minimizeTable
                       ?  <th scope="col">Name</th>
                        : <Ax>
                             <th scope="col" style={{ width: "5%" }}>S.No</th>
                        <th scope="col" style={{ width: "10%" }}>Group ID</th>
                        <th scope="col" style={{ width: "26%" }}>Name</th>
                        <th scope="col" style={{ width: "18%" }}>IAM License</th>
                        <th scope="col" style={{ width: "18%" }}>HRM License</th>
                        <th scope="col" style={{ width: "18%" }}>ISC License</th>
                        <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                        </Ax>}
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.group_listing && this.state.group_listing.length > 0
                            ? (this.state.group_listing.map((item, index) => {
                                return (<tr key={index} >
                                   {this.state.minimizeTable
                                   ? <td className='cursor_pointer link-primary'  onClick={() => this.viewGroupDetail(item.master_id)}>{item.group_name ? item.group_name : ""}</td>
                                    :  <Ax>
                                        <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{item.tap_master_id ? item.tap_master_id : ""} </td>
                                    <td className='cursor_pointer link-primary' onClick={() => this.viewGroupDetail(item.master_id)}>{item.group_name ? item.group_name : ""}</td>
                                    <td className='text-center'>{item.iam_licence ? <span><Status color={item.iam_licence.status_color_code}>{item.iam_licence.status_text} ({item.iam_licence.licence_to_display})</Status></span> : "-"}</td>
                                    <td className='text-center'>{item.hrm_licence ? <span><Status color={item.hrm_licence.status_color_code}>{item.hrm_licence.status_text} ({item.hrm_licence.active_till_display})</Status></span> : "-"}</td>
                                    <td className='text-center'>{item.isc_licence ? <span><Status color={item.isc_licence.status_color_code}>{item.isc_licence.status_text} ({item.isc_licence.active_till_display})</Status></span> : "-"}</td>
                                            <td className='text-center'>
                                                {this.state.permissions.includes('group-license-view') && <Ax>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" onClick={() => this.viewGroupDetail(item.master_id)} role="button">View</a></li>
                                                    </ul>
                                                </Ax>}
                                            </td>
                                        </Ax>}
                                   
                                  
                                </tr>)
                            }))
                            : (<tr><td colSpan="7" className="text-center">No Records</td></tr>))
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    groupDetailJsx                  =       ()      =>      {
        let detail = this.state.groupDetail ? this.state.groupDetail : null
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>

                </li>

            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.detail_loading ? <Loader />
                        : detail ? <div className="tab_content_header">
                            <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Group Name</td>
                                        <th>{detail.group_name ? detail.group_name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Group ID</td>
                                        <th>{detail.tap_master_id ? detail.tap_master_id : "-"}</th>


                                    </tr>

                                </tbody>
                            </table>

                            <div className="tab_content_wrapper"> <span className="content_heading">IAM Detail</span>
                                {this.state.permissions.includes('group-license-manage') && <div> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ cursor: "pointer" }}>

                                        <li><a className="dropdown-item" onClick={() => this.updateIamLicenseFormInit()}>Update License</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.updateIamCountFormInit()}>Update Count</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.addIamSubmoduleFormInit('iam')}>Add Sub Module</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.revokeIamSubmoduleFormInit('iam')}>Revoke Sub Module</a></li>

                                    </ul>
                                </div>}
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>License Status</td>
                                        <th>{detail.iam_licence && <Status color={detail.iam_licence.status_color_code}>{detail.iam_licence.status_text}</Status>}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Expiry Date</td>
                                        <th>{detail?.iam_licence?.licence_to_display ?? "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Sub Modules</td>
                                        <th>{detail.iam_submodules && detail.iam_submodules.length > 0
                                            ? detail.iam_submodules.map((md, k) => { return (<div>{k + 1}. {md.sub_module_data ? md.sub_module_data.name : "-"} ({md.till_display})</div>) })

                                            : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Asset</td>
                                        <th>{detail.iam_licence && detail.iam_licence.current_activity ? <span>{detail.iam_licence.current_activity.asset_count} / {detail.iam_licence.max_asset}</span> : ""}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>User</td>
                                        <th>{detail.iam_licence && detail.iam_licence.current_activity ? <span>{detail.iam_licence.current_activity.user_count} / {detail.iam_licence.max_user}</span > : ""}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Site</td>
                                        <th>{detail.iam_licence && detail.iam_licence.current_activity ? <span>{detail.iam_licence.current_activity.site_count} / {detail.iam_licence.max_site}</span> : ""}</th>
                                    </tr>

                                </tbody>
                            </table>
                            <div className="tab_content_wrapper"> <span className="content_heading">HRM Detail</span>
                                {this.state.permissions.includes('group-license-manage') && <div> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ cursor: "pointer" }}>

                                        <li><a className="dropdown-item" onClick={() => this.updateHrmLicenseFormInit()}>Update License</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.updateHrmCountFormInit()}>Update Count</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.addIamSubmoduleFormInit('hrm')}>Add Sub Module</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.revokeIamSubmoduleFormInit('hrm')}>Revoke Sub Module</a></li>

                                    </ul>
                                </div>}
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>License Status</td>
                                        <th>{detail.hrm_licence && <Status color={detail.hrm_licence.status_color_code}>{detail.hrm_licence.status_text}</Status>}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Expiry Date</td>
                                        <th>{detail?.hrm_licence?.active_till_display ?? "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Sub Modules</td>
                                        <th>{detail.hrm_submodules && detail.hrm_submodules.length > 0
                                            ? detail.hrm_submodules.map((md, k) => { return (<div>{k + 1}. {md.sub_module_data ? md.sub_module_data.name : "-"} ({md.till_display})</div>) })

                                            : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Employee</td>
                                        <th>{detail.hrm_licence ? <span>{detail.hrm_licence.current_users} / {detail.hrm_licence.max_users}</span> : ""}</th>
                                    </tr>

                                </tbody>
                            </table>
                            <div className="tab_content_wrapper"> <span className="content_heading">ISC Detail</span>
                                {this.state.permissions.includes('group-license-manage') && <div> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ cursor: "pointer" }}>

                                        <li><a className="dropdown-item" onClick={() => this.updateIscLicenseFormInit()}>Update License</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.updateIscCountFormInit()}>Update Count</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.addIamSubmoduleFormInit('isc')}>Add Sub Module</a></li>
                                        <li><a className="dropdown-item" onClick={() => this.revokeIamSubmoduleFormInit('isc')}>Revoke Sub Module</a></li>

                                    </ul>
                                </div>}
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>License Status</td>
                                        <th>{detail.isc_licence && <Status color={detail.isc_licence.status_color_code}>{detail.isc_licence.status_text}</Status>}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Expiry Date</td>
                                        <th>{detail?.isc_licence?.active_till_display ?? "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Sub Modules</td>
                                        <th>{detail.isc_submodules && detail.isc_submodules.length > 0
                                            ? detail.isc_submodules.map((md, k) => { return (<div>{k + 1}. {md.sub_module_data ? md.sub_module_data.name : "-"} ({md.till_display})</div>) })

                                            : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Warehouse</td>
                                        <th>{detail.isc_licence ? <span>{detail.isc_licence.current_warehouse} / {detail.isc_licence.max_warehouse}</span> : ""}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Item</td>
                                        <th>{detail.isc_licence ? <span>{detail.isc_licence.current_items} / {detail.isc_licence.max_items}</span> : ""}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                            : null}
                </div>

            </div>
        </section>);    
    }

    updateIamLicenseModalJsx        =       ()      =>      {
        return (<div className="modal fade" id="updateIamLicenseModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="updateIamLicenseModalLabel">Update IAM License</h5>
                    </div>
                    <form onSubmit={this.submitUpdateIamLicenseForm} id="updateIamLicense" method="post" encType="multipart/form-data">
                        <div className="modal-body">
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">License Status</label>
                                <div className="col-sm-9">
                                    {this.state.iamLicenceDetail && <Status color={this.state.iamLicenceDetail.status_color_code}>{this.state.iamLicenceDetail.status_text}</Status>}
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                <div className="col-sm-9">
                                    <input
                                        name="group_name"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Group Name"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIamLicenseForm') }}
                                        disabled
                                        value={this.state.updateIamLicenseForm.group_name}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Current Expiry Date</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={
                                            this.state.updateIamLicenseForm.licence_to
                                                ? moment(this.state.updateIamLicenseForm.licence_to, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="license_to"
                                        onChange={(value, event) => this.formDateHandler('licence_to', value, 'updateIamLicenseForm')}
                                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        disabled
                                        placeholderText={`Please Enter Current Expiry Date`}

                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">New Expiry Date</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={
                                            this.state.updateIamLicenseForm.new_licence_to
                                                ? moment(this.state.updateIamLicenseForm.new_licence_to, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="new_licence_to"
                                        onChange={(value, event) => this.formDateHandler('new_licence_to', value, 'updateIamLicenseForm')}
                                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        placeholderText={`Please Enter New Expiry Date`}

                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.updateIamLicenseFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.updateIamLicenseFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.updateIamLicenseFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>)
    }

    updateIamCountModalJsx        =       ()      =>      {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let iam_licence_detail  =   this.state.iamLicenceDetail ? this.state.iamLicenceDetail : null;
        return (<div className="modal fade" id="updateIamCountModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="updateIamLicenseModalLabel">Update IAM Count</h5>
                    </div>
                    <form onSubmit={this.submitUpdateIamCountForm} id="updateIamLicense" method="post" encType="multipart/form-data">
                        <div className="modal-body">

                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                <div className="col-sm-9">
                                    <input
                                        name="group_name"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Group Name"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIamCountForm') }}
                                        disabled
                                        value={this.state.updateIamCountForm.group_name}
                                    />
                                </div>
                            </div>

                            <div className='row my-2'>

                                <div className='offset-3 col-sm-3'>
                                    <small className="form-text">Used Limit</small>
                                </div>
                                <div className='col-sm-3'>
                                    <small className="form-text">Current Max Limit</small>
                                </div>
                                <div className={'col-sm-3'} >
                                    <small className="form-text">New Max Limit</small>
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Asset</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Asset"
                                        disabled
                                        value={iam_licence_detail?.current_activity?.asset_count ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Asset"
                                        disabled
                                        value={iam_licence_detail?.max_asset ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        name="max_asset_new"
                                        type="number"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="New Max Limit"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIamCountForm') }}
                                        value={this.state.updateIamCountForm.max_asset_new}
                                        min={0}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">User</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter User"
                                        disabled
                                        value={iam_licence_detail?.current_activity?.user_count ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter User"
                                        disabled
                                        value={iam_licence_detail?.max_user ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        name="max_user_new"
                                        type="number"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="New Max Limit"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIamCountForm') }}
                                        value={this.state.updateIamCountForm.max_user_new}
                                        min={0}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Site</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Site"
                                        disabled
                                        value={iam_licence_detail?.current_activity?.site_count ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Site"
                                        disabled
                                        value={iam_licence_detail?.max_site ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        name="max_site_new"
                                        type="number"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="New Max Limit"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIamCountForm') }}
                                        value={this.state.updateIamCountForm.max_site_new}
                                        min={0}
                                        required
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.updateIamCountFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.updateIamCountFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.updateIamCountFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>)
    }

    addIamSubmodulesModalJsx        =       ()      =>      {
        return (<div className="modal fade" id="addIamSubModuleModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addIamSubmodulesModalLabel">Add Sub Module</h5>
                    </div>
                    <form onSubmit={this.submitAddIamSubmodulesForm} id="addIamSubmodules" method="post" encType="multipart/form-data">
                        <div className="modal-body">

                            {this.state.form_data_loading ? <Loader />
                                : <Ax>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                        <div className="col-sm-9">
                                            <input
                                                name="group_name"
                                                type="text"
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                placeholder="Enter Group Name"
                                                onChange={(e) => { this.formInputHandler(e, 'addIamSubmoduleForm') }}
                                                disabled
                                                value={this.state.addIamSubmoduleForm.group_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Sub Module</label>
                                        <div className="col-sm-9">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addIamSubmoduleForm', 'sub_module_id', () => {
                                                        let group_list = this.state.allGroupLicencedModuleList;
                                                        let group_licensed_detail = group_list && group_list.length > 0 ? group_list.find(gm => gm.value == selectedOption.value) : null;
                                                        this.setState({
                                                            addIamSubmoduleForm: {
                                                                ...this.state.addIamSubmoduleForm,
                                                                till: group_licensed_detail && group_licensed_detail.till ? group_licensed_detail.till : ''
                                                            }
                                                        })
                                                    });
                                                }}
                                                options={this.state.allSubModuleList}
                                                isSearchable={true}
                                                isClearable={true}
                                                required={true}
                                                value={this.state.allSubModuleList.find(s => s.value == this.state.addIamSubmoduleForm.sub_module_id)}
                                                placeholder="Select Sub Module"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Current Expiry Date</label>
                                        <div className="col-sm-9">
                                            <DatePicker
                                                selected={
                                                    this.state.addIamSubmoduleForm.till
                                                        ? moment(this.state.addIamSubmoduleForm.till, 'YYYY-MM-DD').toDate()
                                                        : false
                                                }
                                                name="till"
                                                onChange={(value, event) => this.formDateHandler('till', value, 'addIamSubmoduleForm')}
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                required={true}
                                                disabled
                                                placeholderText={`Please Enter Current Expiry Date`}

                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">New Expiry Date</label>
                                        <div className="col-sm-9">
                                            <DatePicker
                                                selected={
                                                    this.state.addIamSubmoduleForm.new_till
                                                        ? moment(this.state.addIamSubmoduleForm.new_till, 'YYYY-MM-DD').toDate()
                                                        : false
                                                }
                                                name="new_till"
                                                onChange={(value, event) => this.formDateHandler('new_till', value, 'addIamSubmoduleForm')}
                                                minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                required={true}
                                                placeholderText={`Please Enter New Expiry Date`}

                                            />
                                        </div>
                                    </div>
                                </Ax>}

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.addIamSubmoduleFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.addIamSubmoduleFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addIamSubmoduleFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>)
    }

    revokeIamSubmodulesModalJsx        =       ()      =>      {
        return (<div className="modal fade" id="revokeIamSubModuleModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="revokeIamSubmodulesModalLabel">Revoke Sub Module</h5>
                    </div>
                    <form onSubmit={this.submitRevokeIamSubmodulesForm} id="revokeIamSubmodules" method="post" encType="multipart/form-data">
                        <div className="modal-body">

                            {this.state.form_data_loading ? <Loader />
                                : <Ax>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                        <div className="col-sm-9">
                                            <input
                                                name="group_name"
                                                type="text"
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                placeholder="Enter Group Name"
                                                onChange={(e) => { this.formInputHandler(e, 'revokeIamSubmoduleForm') }}
                                                disabled
                                                value={this.state.revokeIamSubmoduleForm.group_name}
                                            />
                                        </div>
                                        </div>
                                     <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Licensed Sub Module</label>
                                        <div className="col-sm-9">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'revokeIamSubmoduleForm', 'sub_module_id',() => {
                                                    let group_list = this.state.allGroupLicencedModuleList;
                                                    let group_licensed_detail = group_list && group_list.length > 0 ? group_list.find(gm => gm.value == selectedOption.value ) : null;
                                                    this.setState({
                                                        revokeIamSubmoduleForm  :       {
                                                            ...this.state.revokeIamSubmoduleForm,
                                                            till        :       group_licensed_detail && group_licensed_detail.till ? group_licensed_detail.till  : ''
                                                        }
                                                    })
                                                });
                                            }}
                                            options={this.state.allGroupLicencedModuleList}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            value={this.state.allGroupLicencedModuleList.find(s => s.value == this.state.revokeIamSubmoduleForm.sub_module_id)}
                                            placeholder="Select Sub Module"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">Current Expiry Date</label>
                                        <div className="col-sm-9">
                                            <DatePicker
                                                selected={
                                                    this.state.revokeIamSubmoduleForm.till
                                                        ? moment(this.state.revokeIamSubmoduleForm.till, 'YYYY-MM-DD').toDate()
                                                        : false
                                                }
                                                name="till"
                                                onChange={(value, event) => this.formDateHandler('till', value, 'revokeIamSubmoduleForm')}
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                required={true}
                                                disabled
                                                placeholderText={`Please Enter Current Expiry Date`}

                                            />
                                        </div>
                                    </div>
                                 
                                </Ax>}

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.revokeIamSubmoduleFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.revokeIamSubmoduleFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.revokeIamSubmoduleFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>
                      
                    </form>
                </div>
            </div>
        </div>)
    }

    updateHrmLicenseModalJsx        =       ()      =>      {
        return (<div className="modal fade" id="updateHrmLicenseModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="updateIamLicenseModalLabel">Update Hrm License</h5>
                    </div>
                    <form onSubmit={this.submitUpdateHrmLicenseForm} id="updateIamLicense" method="post" encType="multipart/form-data">
                        <div className="modal-body">
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Status</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'updateHrmLicenseForm', 'new_status')

                                        }}
                                        options={this.state.allLicenceStatus}
                                        isSearchable={true}
                                        isClearable={true}
                                        required={true}
                                        value={this.state.allLicenceStatus.find(s => s.value == this.state.updateHrmLicenseForm.new_status)}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                <div className="col-sm-9">
                                    <input
                                        name="group_name"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Group Name"
                                        onChange={(e) => { this.formInputHandler(e, 'updateHrmLicenseForm') }}
                                        disabled
                                        value={this.state.updateHrmLicenseForm.group_name}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Current Expiry Date</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={
                                            this.state.updateHrmLicenseForm.licence_to
                                                ? moment(this.state.updateHrmLicenseForm.licence_to, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="license_to"
                                        onChange={(value, event) => this.formDateHandler('licence_to', value, 'updateHrmLicenseForm')}
                                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        disabled
                                        placeholderText={`Please Enter Current Expiry Date`}

                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">New Expiry Date</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={
                                            this.state.updateHrmLicenseForm.new_licence_to
                                                ? moment(this.state.updateHrmLicenseForm.new_licence_to, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="new_licence_to"
                                        onChange={(value, event) => this.formDateHandler('new_licence_to', value, 'updateHrmLicenseForm')}
                                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        placeholderText={`Please Enter New Expiry Date`}

                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.updateHrmLicenseFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.updateHrmLicenseFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.updateHrmLicenseFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>)
    }

    updateHrmCountModalJsx        =       ()      =>      {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let hrm_licence_detail  =   this.state.hrmLicenceDetail ? this.state.hrmLicenceDetail : null;
        return (<div className="modal fade" id="updateHrmCountModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="updateHrmLicenseModalLabel">Update Hrm Count</h5>
                    </div>
                    <form onSubmit={this.submitUpdateHrmCountForm} id="updateHrmLicense" method="post" encType="multipart/form-data">
                        <div className="modal-body">

                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                <div className="col-sm-9">
                                    <input
                                        name="group_name"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Group Name"
                                        onChange={(e) => { this.formInputHandler(e, 'updateHrmCountForm') }}
                                        disabled
                                        value={this.state.updateHrmCountForm.group_name}
                                    />
                                </div>
                            </div>

                            <div className='row my-2'>

                                <div className='offset-3 col-sm-3'>
                                    <small className="form-text">Used Limit</small>
                                </div>
                                <div className='col-sm-3'>
                                    <small className="form-text">Current Max Limit</small>
                                </div>
                                <div className={'col-sm-3'} >
                                    <small className="form-text">New Max Limit</small>
                                </div>
                            </div>

                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Employee</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter User"
                                        disabled
                                        value={hrm_licence_detail?.current_users ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter User"
                                        disabled
                                        value={hrm_licence_detail?.max_users ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        name="max_users_new"
                                        type="number"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="New Max Limit"
                                        onChange={(e) => { this.formInputHandler(e, 'updateHrmCountForm') }}
                                        value={this.state.updateHrmCountForm.max_users_new}
                                        min={0}
                                        required
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.updateHrmCountFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.updateHrmCountFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.updateHrmCountFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>)
    }

    updateIscLicenseModalJsx        =       ()      =>      {
        return (<div className="modal fade" id="updateIscLicenseModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="updateIscLicenseModalLabel">Update Isc License</h5>
                    </div>
                    <form onSubmit={this.submitUpdateIscLicenseForm} id="updateIscLicense" method="post" encType="multipart/form-data">
                        <div className="modal-body">
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Status</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'updateIscLicenseForm', 'new_status')

                                        }}
                                        options={this.state.allLicenceStatus}
                                        isSearchable={true}
                                        isClearable={true}
                                        required={true}
                                        value={this.state.allLicenceStatus.find(s => s.value == this.state.updateIscLicenseForm.new_status)}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                <div className="col-sm-9">
                                    <input
                                        name="group_name"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Group Name"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIscLicenseForm') }}
                                        disabled
                                        value={this.state.updateIscLicenseForm.group_name}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Current Expiry Date</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={
                                            this.state.updateIscLicenseForm.licence_to
                                                ? moment(this.state.updateIscLicenseForm.licence_to, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="license_to"
                                        onChange={(value, event) => this.formDateHandler('licence_to', value, 'updateIscLicenseForm')}
                                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        disabled
                                        placeholderText={`Please Enter Current Expiry Date`}

                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">New Expiry Date</label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        selected={
                                            this.state.updateIscLicenseForm.new_licence_to
                                                ? moment(this.state.updateIscLicenseForm.new_licence_to, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="new_licence_to"
                                        onChange={(value, event) => this.formDateHandler('new_licence_to', value, 'updateIscLicenseForm')}
                                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        placeholderText={`Please Enter New Expiry Date`}

                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.updateIscLicenseFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.updateIscLicenseFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.updateIscLicenseFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>)
    }

    updateIscCountModalJsx        =       ()      =>      {
        let detail              =   this.state.groupDetail ? this.state.groupDetail : null;
        let isc_licence_detail  =   this.state.iscLicenceDetail ? this.state.iscLicenceDetail : null;
        return (<div className="modal fade" id="updateIscCountModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="updateHrmLicenseModalLabel">Update ISC Count</h5>
                    </div>
                    <form onSubmit={this.submitUpdateIscCountForm} id="updateIscLicense" method="post" encType="multipart/form-data">
                        <div className="modal-body">

                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Group Name</label>
                                <div className="col-sm-9">
                                    <input
                                        name="group_name"
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Group Name"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIscCountForm') }}
                                        disabled
                                        value={this.state.updateIscCountForm.group_name}
                                    />
                                </div>
                            </div>

                            <div className='row my-2'>

                                <div className='offset-3 col-sm-3'>
                                    <small className="form-text">Used Limit</small>
                                </div>
                                <div className='col-sm-3'>
                                    <small className="form-text">Current Max Limit</small>
                                </div>
                                <div className={'col-sm-3'} >
                                    <small className="form-text">New Max Limit</small>
                                </div>
                            </div>

                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Warehouse</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Warehouse"
                                        disabled
                                        value={isc_licence_detail?.current_warehouse ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Warehouse"
                                        disabled
                                        value={isc_licence_detail?.max_warehouse ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        name="max_warehouse_new"
                                        type="number"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="New Max Limit"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIscCountForm') }}
                                        value={this.state.updateIscCountForm.max_warehouse_new}
                                        min={0}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Items</label>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Item"
                                        disabled
                                        value={isc_licence_detail?.current_items ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="Enter Items"
                                        disabled
                                        value={isc_licence_detail?.max_items ?? 0}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        name="max_items_new"
                                        type="number"
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder="New Max Limit"
                                        onChange={(e) => { this.formInputHandler(e, 'updateIscCountForm') }}
                                        value={this.state.updateIscCountForm.max_items_new}
                                        min={0}
                                        required
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer d-flex justify-content-between  row">
                            <div className="col text-start">

                                <input
                                    name="agree"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="agree"
                                    required
                                    value={this.state.agree}
                                    onChange={(e) => {
                                        this.setState({ agree: this.state.agree == 'N' ? 'Y' : 'N' })
                                    }}
                                    checked={this.state.agree == 'Y'}
                                />
                                <label className="form-check-label mx-2 text-danger" htmlFor="agree">Are you sure and agree to perform this action? </label>
                            </div>
                            <div class="col text-end">
                                <button type="button"
                                    disabled={this.state.updateIscCountFormSubmitting}
                                    className="btn btn-secondary mx-2"
                                    data-bs-dismiss="modal"

                                >Close</button>
                                <button type="submit" disabled={this.state.updateIscCountFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.updateIscCountFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>

                        </div>


                    </form>
                </div>
            </div>
        </div>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Group Listing</title></Helmet>
            {this.groupJsx()}
            {this.updateIamLicenseModalJsx()} 
            {this.updateIamCountModalJsx()} 
            {this.addIamSubmodulesModalJsx()} 
            {this.revokeIamSubmodulesModalJsx()}
            {this.updateHrmLicenseModalJsx()}
            {this.updateHrmCountModalJsx()}
            {this.updateIscLicenseModalJsx()}
            {this.updateIscCountModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :           state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(GroupList);