// import your icons
import {
    faAngleDown,
    faAngleLeft,
    faAngleUp,
    faArrowRight,
    faLongArrowAltRight,
    faArrowAltCircleRight,
    faAngleRight,
    faBars,
    faBusinessTime,
    faCalculator,
    faCar,
    faCarAlt,
    faCalendar,
    faCalendarAlt,
    faCaretLeft,
    faCaretRight,
    faChevronCircleDown,
    faChevronCircleUp,
    faCircle,
    faCode,
    faCogs,
    faCheck,
    faCubes,
    faDownload,
    faEllipsisV,
    faEdit,
    faFileDownload,
    faFileInvoiceDollar,
    faHighlighter,
    faHistory,
    faMapMarker,
    faHome,
    faInfoCircle,
    faLock,
    faLink,
    faMapPin,
    faMoneyBill,
    faMoneyCheck,
    faPallet,
    faPlay,
    faPlus,
    faPencilAlt,
    faRedo,
    faSyncAlt,
    faShareAlt,  
    faSearch,
    faTachometerAlt,
    faThLarge,
    faTimes,
    faUnlock,
    faUser,
    faUsers,
    faUserShield,
    faWallet,
    faWarehouse, faPlusCircle, faCaretUp, faCaretDown, faThumbtack, faFilter, faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';

import { 
    faFacebookF, 
    faLinkedinIn, 
    faTwitter, 
    faYoutube
} from "@fortawesome/free-brands-svg-icons";

import {
    faChartBar,
    faEye,
    faFileExcel,
    faTrashAlt,
    faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";



import ActionIcon from "../assets/img/sub-header-icon/more-icon.svg";
import CloseIcon from "../assets/img/sub-header-icon/close-icon.svg";
import EditIcon from "../assets/img/sub-header-icon/edit-icon.svg";
import SearchIcon from "../assets/img/sub-header-icon/search-icon.svg";
import ListActionIcon from "../assets/img/svg/action-icon.svg";
import CalenderIcon from "../assets/img/svg/calender-icon.svg";
import CloseCircleIcon from "../assets/img/svg/close-cross.svg";
import PinIcon from "../assets/img/svg/pin-icon.svg";
import Plus from "../assets/img/svg/plus.svg";
import Close from "../assets/img/svg/close.svg";
import InfoIcon from "../assets/img/svg/infoIcon.svg"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const imageIcon         =   (props)  =>  {
    let p = {...props, icon : null};
    return <img alt={props.alt ? props.alt : 'Action'} src={props.icon} {...p} />;
}

const TapIcon           =   ()  =>  {
    return {
        FontAwesomeIcon,
        imageIcon,
        faAngleDown,
        faAngleLeft,
        faAngleRight,
        faAngleUp,
        faArrowLeft,
        faArrowRight,
        faArrowAltCircleRight,
        faLongArrowAltRight,
        faBars,
        faBusinessTime,
        faCalculator,
        faCar,
        faChartBar,
        faCaretRight,
        faCaretLeft,
        faCaretUp,
        faCalendar,
        faCalendarAlt,
        faCaretDown,
        faCarAlt,
        faCode,
        faCogs,
        faCheck,
        faChevronCircleDown,
        faChevronCircleUp,
        faCircle,
        faCubes,
        faDownload,
        faEllipsisV,
        faEye,
        faEdit,
        faFacebookF,
        faFileExcel,
        faFileInvoiceDollar,
        faFileDownload,
        faFilter,
        faInfoCircle,
        faHome,
        faHistory,
        faHighlighter,
        faLinkedinIn,
        faLock,
        faLink,
        faMapMarker,
        faMapPin,
        faMoneyBill,
        faMoneyCheck,
        faPallet,
        faPlay,
        faPlus,
        faPlusCircle,
        faPencilAlt,
        faRedo,
        faSyncAlt,
        faShareAlt,
        faSearch,
        faTimes,
        faTimesCircle,
        faTrashAlt,
        faThLarge,
        faThumbtack,
        faTachometerAlt,
        faTwitter,
        faUnlock,
        faUser,
        faUsers,
        faUserShield,
        faWallet,
        faWarehouse,
        faYoutube,
        ActionIcon,
        CloseIcon,
        CloseCircleIcon,
        CalenderIcon,
        EditIcon,
        InfoIcon,
        SearchIcon,
        PinIcon,
        Plus,
        Close,
        ListActionIcon,
    }
}

export default TapIcon();