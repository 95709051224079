import * as actionTypes from './actionTypes';
import axios from 'axios';
import { logout } from './auth';
import errorValidation from "../../services/ErrorValidation";
import HttpAPICall from "../../services/HttpAPICall";
import TapApiUrls from '../../services/TapApiUrls';


export const requisionFormDataStart = () => {
    return {
        type                :   actionTypes.REQUISION_FORM_DATA_INIT
    };
};

export const requisionFormDataDone = (formData) => {
    return {
        type                :   actionTypes.REQUISION_FORM_DATA_LOAD,
        requisionFormData   :   formData
    };
};

export const appInitilizationStarting   = () => {
    return {
        type                :   actionTypes.APP_DATA_INIT
    };
};

export const appInitilizationDataLoad   = (user_info, acl_info) => {
    console.log(acl_info,'rrr')
    return {
        type                :   actionTypes.APP_DATA_SUCCESS,
        user_info           :   user_info,
        acl_info            :   acl_info
    };
};

export const appInitilizationError   = (msg) => {
    return {
        type                :   actionTypes.APP_DATA_FAIL,
        error_msg           :   msg
    };
};

export const fireBaseSystemConfigUpdate   = (data) => {
    console.log('fb Data ===', data);
    return {
        type                        :   actionTypes.FIREBASE_APP_VERSION_UPDATE,
        firebase_app_version        :   data && data.version    ?   data.version : '',
        firebase_allowed_ip         :   data && data.allowed_ip ?   data.allowed_ip :   null,
        firebase_maintenance_mode   :   data && data.maintenance_mode ? data.maintenance_mode : 'N'
    };
};

export const applicationInitLoading         =   (access_token,reload = true)  =>  {
    return dispatch => {
        const headers   =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + access_token};
        if(reload == true){
            dispatch(appInitilizationStarting());
        }
        
        Promise.all([
            axios({ method: 'GET', headers: headers,params:{'need_permissions' : 'Y'}, url: process.env.REACT_APP_SERVER_PATH + '/backoffice/user'}), 
           
        ])
        .then((results) => {
            
            let user                =   results[0].data.data;
            let user_info           =   {
                mobile                  :   user.mobile,
                name                    :   user.name,
                id                      :   user.role_id,
            };
           
            let acl_info            =   {
                permissions             :   user.permissions,
            };
            console.log(acl_info)
            dispatch(appInitilizationDataLoad(user_info, acl_info));
            
        }).catch(function (err) {
            errorValidation.apiErrorHandle(err, 'Application not initialize successfully.', false);
            dispatch(logout());
            dispatch(appInitilizationError('An Error Occured'));
        });
       
    };
    
}
