import React from 'react';
import { connect } from 'react-redux';
import Ax from '../components/hoc/Ax';
import { Link } from 'react-router-dom';
import TappetLogo from "../assets/img/svg/logo_new.svg";
import TapIcon from '../services/TapIcon';
import ApplicationLayout from '../layouts/ApplicationLayout';

class PageNotFound extends React.Component {
  
   
    render() {
     
        return (
            <ApplicationLayout>

                <div style={{ backgroundColor: '#fff', minHeight: '100vh', paddingTop: '4rem', textAlign: 'center' }}>
                    <h1 style={{ fontSize: '5rem', margin: '2rem 0' }}>404</h1>
                    <p style={{ fontSize: '2rem', margin: '1rem 0' }}>Page Not Found</p>
                    <small>Please go to  <a class="link-primary" href="/dashboard">Dashboard</a></small>
                </div>
            </ApplicationLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions         :   state.app && state.app.acl_info ? state.app.acl_info : null
    };
};

export default connect(mapStateToProps)(PageNotFound);
